import React, { Component } from "react";
import LineChart1 from "../charts/b_12_lineChart_1";
import LineChart2 from "../charts/b_12_lineChart_2";
import TemplateComponent from "../maps/TemplateComponent";
import '../../resources/styles/MainStyles.css';
import '../../resources/styles/bs_12_ComportamientoConsumidor.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import { arrayToString } from '../../resources/helpers/stringFormatter'
import { titlevisitas } from '../../resources/helpers/DinamicTitle';
import { dateParams } from '../../resources/helpers/globalDates'
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";

import { url } from "../../resources/helpers/urltab2";

const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<1024) {
    width=width / 1.2
}
else if ( width >= 1024 ) width = width / 2.3  
var height = window.innerHeight;  
if (height<1024) {
    height=height / 1
} else if ( height >= 1024 ) height = height / 2.3  


export default class TableroPerfilBogota extends Component {
    constructor( props ){
        super( props );
        this.state = { 
            keyTablero: '12ComportamientoConsumidor', 
            apiResponse: 0,
            localidad: '',
            upz: '',
            barrio: '',
            estrato: '',
            categoria: '',
            metrica: 'Visitas',
            usuarioIniciado: '',
            filtro_fecha_inicio: dateParams.startDate,
		    filtro_fecha_fin: dateParams.endDate, 
            alto_mapa:  height,
            ancho_mapa: width
        };
    }


    componentDidMount()
    {
        /*window.addEventListener('resize', ()=>{
            headerHeight = document.querySelector('.encabezado').offsetHeight
            optionsHeight = document.querySelector('.opciones').offsetHeight
        } );*/
    }

    asignarCategorias = ()=>{
        var content = "";
        // eslint-disable-next-line
        if(this.state.categoria == []){
          content = arrayToString(arrayCategoriasDos);
        } else {
          content = this.state.categoria;
        }
        return content;
    }

    updateQuery = (nuevoEstado) => {
        this.setState( 
        {
            localidad : nuevoEstado.localidad,
            upz: nuevoEstado.upz,
            barrio: nuevoEstado.barrio,
            estrato: nuevoEstado.estrato,
            categoria: nuevoEstado.categoriasNoBasico,
            metrica : nuevoEstado.metrica,
            apiResponse: nuevoEstado.contadorDeCambios,
        })
    }


    updateUserCats = (usuarioIniciado) => {
        if ( usuarioIniciado!== this.state.usuarioIniciado){
            this.setState( { usuarioIniciado: usuarioIniciado })
        }
    }

    updateDates = (nuevasFechas) => {
        this.setState( {filtro_fecha_inicio : nuevasFechas.startDate,
          filtro_fecha_fin: nuevasFechas.endDate,
          apiResponse: nuevasFechas.apiResponse} );
      }

    // se evita "reconciliacion" de react
    shouldComponentUpdate ( nextProps, nextState ){
        if(this.state.apiResponse === nextState.apiResponse){
            if (this.usuarioIniciado !== nextState.usuarioIniciado){
                return true 
            }
        } 
        return true
    } 

    componentDidUpdate(){
        //alert(document.querySelector('.header2').offsetHeight)
    }

    render(){
		return(
		<body>
			<div className="encabezado" >
				<div style={{'display':'contents'}}>
					<div className="logo1" >
						<img className="LogoImage" src={imgn} alt="imgn" /> 
                        <img className="LogoImage2" src={imgn2} alt="imgn" /> 
					</div>
					<div className="titulo1" >
                    Comportamiento del consumidor Por sus tiempos de consumo.
					</div>
					<div className="calendario1" style={{'z-index':'80'}}>
						<DatePickerComponent 
							tablero = {this.state.keyTablero}
							datesChanged = {this.updateDates}
						/>
					</div>
				</div>
			</div>

			<SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

			<div className="opciones_02" >
				<div style={{'display':'contents'}}>
					<div className="subtitulo">
						<h5 align="left" style={{'color':'#4F4F4F'}}>
						Conozca la cantidad y tiempo promedio de visitas por consumidor, según la ubicación y la subcategoría del comercio. </h5> 
				    </div>
			    
			        <div className="filtros1">
						<MultipleFiltersComponent 
							tablero={this.state.keyTablero}
							metrica={this.state.metrica}
				           	onFilterChange={this.updateQuery}
				        />            
					</div>
				</div>
			</div>

			<div className="paneltableros">
                <div className="contenedor1-comp-consumidor">
                    <h5>Visitas: Cantidad y Duración</h5>
                    <LineChart1
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.asignarCategorias()}
                        url={urlDev.urlApiDev+"ConsultaModuloAvanzado/LineasUno"}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                    />
                </div>

                <div className="contenedor2-comp-consumidor">
                    <h5>{titlevisitas(this.state.metrica)}</h5>
                    <TemplateComponent
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.asignarCategorias()}
                        filtro_metrica={this.state.metrica}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                        url = {url(this.state.metrica)}
                        id_mapa_tablero = {this.state.keyTablero}
                        alto_mapa = {this.state.alto_mapa}
                        ancho_mapa = {this.state.ancho_mapa}
                    />
                </div>

                <div className="contenedor3-comp-consumidor">
                    <h5>Visitas: Frecuencia y Duración Promedio</h5>             
                    <LineChart2
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.asignarCategorias()}
                        url={urlDev.urlApiDev+"ConsultaModuloAvanzado/LineasDos"}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                    />
                </div>
            </div>
			<div className="footer2">Footer</div>
		</body>

		)
	}
}
	
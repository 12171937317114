//Imports de librerias y componentes.
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "./auth";
import { withStyles } from "@material-ui/styles";
import logo from "../../resources/img/logo-color.svg";
import imgn from "../../resources/img/imagen-login.png";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import '../../resources/styles/Login.css';
import banner3 from "../../resources/img/logoIsCcompleto.png";
import colombia_productiva from "../../resources/img/colombia_productiva.png";
import credibanco from "../../resources/img/credibanco.png";
import mintic from "../../resources/img/mintic.png";
import rectangulo from "../../resources/img/Rectangulo.png";

//Asignación de estilos al componente.
const styles = () => ({
  "@global": {
    body: {
      backgroundColor: "#F4F8FD",
      display: "block"
    }
  },
  root: {
    '& input:valid + fieldset': {
      backgroundcolor: "#fff",
      borderColor: '#499DFF',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      backgroundcolor: "#fff",
      borderColor: '499DFF',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      backgroundcolor: "#fff",
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
    '&:hover': {
      background: 'rgba(255,255,255)',
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
  form: {
    marginTop: 1,
    backgroundcolor: "#FFFFFF"
  },
  errorText: {
    color: "#de1212",
    marginBottom: 5,
    textAlign: "center"
  },
  TextField: {
    background:'#FFF',
    color: "black"
  },
  button:{
    backgroundColor:'#499DFF',
    color: "white"
  },
  input:{
    background: "#FFF",
    color: "black"
  }
});

//Clase que permite la ejecución del componente.

class Login extends Component {

  state = { email: "", password: "" };

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;

    dispatch(loginUser(email, password));
  };

  //Método para el renderizado del componente.

  render() {
    const { classes, loginError, isAuthenticated } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {

      return (
        <div>
            <div className="formulario">
            <img className="logo" src={logo} alt="Logo" />

            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="email"
              label="Ingresa tu correo electrónico"
              name="email"
              color="secondary"
              className={classes.root}
              onChange={this.handleEmailChange}
            />

            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              name="password"
              label="Ingresa tu contraseña"
              type="password"
              id="password"
              color="secondary"
              className={classes.root}
              onChange={this.handlePasswordChange}
            />
            {loginError && (
              <Typography component="p" className={classes.errorText}>
                El correo o la contraseña ingresados son incorrectos, inténtelo nuevamente.
              </Typography>
            )}

            <div className="login-boton">
            <Tooltip title="Clic aquí para iniciar sesión" arrow>
              <Button
                type="button"
                fullWidth
                variant="contained"
                // eslint-disable-next-line
                className={classes.submit, classes.button}
                onClick={this.handleSubmit}
              >
                Iniciar Sesión
              </Button>
            </Tooltip>
            </div>
          </div>
            <img className="image" src={imgn} alt="imgn" />
            <div className="imagen1">
              <h3 className ="Texto1-imagen1">En alianza con  :</h3>
              <img src={credibanco}/>
            </div>

            <div className="imagen2">
              <h3 className ="Texto1-imagen2">Powered by:</h3>
              <img class="img1" src={banner3} />
              <h3 className ="Texto2-imagen2">Este es un proyecto en colaboración con: </h3>
              <img class="img2" src={mintic} />
              <img class="img3" src={rectangulo} />
              <img class="img4"  src={colombia_productiva} />
            </div>
        </div>
      );
    }
  }
}

//Método de retorno el cual renderiza los componentes codificados en estilo JSX.

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Login));
import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react'
import { formatMetrica } from '../../resources/helpers/numberFormatter'
import 'semantic-ui-css/semantic.min.css'

let auto_key = 0;
let jsonResponse = [];

export default class TablePagination extends PureComponent {

  constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz: props.filtro_upz,
      filtro_barrio: props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  //callAPi
  readQuery() {
        fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
          + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
          .then ( res => res.text() )
          .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
          .then ( res => jsonResponse = this.state.apiResponse );
      }


  componentDidMount(){
    this.readQuery();
  }

  async componentWillReceiveProps(nextProps) {
    let params_string = "";
    //LOGICA:
    // para cada parametro de filtro
    // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
    if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
      if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
    }
    else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

    if ( nextProps.filtro_upz !== this.state.filtro_upz ){
      if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
    }
    else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

    if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
      if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
    }
    else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

    if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
      if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
    }
    else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

    if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
      if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
    }
    else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

    if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate )) params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
    else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");

    if (params_string.length >= 2 ) params_string = params_string.substring(2);
    if(nextProps.filtro_metrica !== this.state.filtro_metrica) {}

    else {
        try {
          //call API
          /// envia valores de filtros al servicio y determinar que responde para renderizar
          const response = await fetch(this.state.url + "?" + params_string);
          if (!response.ok){
            throw Error(response.statusText);
          }
          jsonResponse = await response.text();
          jsonResponse = JSON.parse(jsonResponse);
        }catch (error) {
          console.log(error);
        }
      }
      this.setState( {
        apiResponse: jsonResponse,
        filtro_upz: nextProps.filtro_upz,
        filtro_barrio: nextProps.filtro_barrio,
        filtro_metrica: nextProps.filtro_metrica,
        startDate: nextProps.startDate, endDate: nextProps.endDate
      } );
  }

  shouldComponentUpdate(nextProps,nextState){
   if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica){
        return true
      }
      return false
   } else {
      return true
   }
  }

  render() {

    var metrica = this.state.filtro_metrica;


    if (this.state.filtro_metrica === "Ticket Prom") {
        metrica = "Tickets";
    } else if (this.state.filtro_metrica === "Búsquedas") {
        metrica = "Busquedas"
    }

    return (
      <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{align : 'center'}}>Localidad</Table.HeaderCell>
              <Table.HeaderCell style={{align : 'center'}}>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
          {this.state.apiResponse.map( columna =>{
              return(
                  <Table.Row>
                      <Table.Cell key={auto_key+=1} style={{fontSize: '10px'}}>{columna["Nombre_Loc"]}</Table.Cell>
                      <Table.Cell key={auto_key+=1} style={{fontSize: '10px'}}>{formatMetrica(metrica,columna[metrica])}</Table.Cell>
                  </Table.Row>
                )
              }
            )}
          </Table.Body>
        </Table>
    );
  }
}

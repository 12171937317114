//Archivo de configuración de firebase para el manejo de la autenticación.

import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";

//Opciones: Dev -> firebaseConfigDevEnv / QA -> firebaseConfigQAEnv
const jsonConfigEnv = require("../properties/properties");

export const myFirebase = firebase.initializeApp(jsonConfigEnv.firebaseConfigDevEnv);

myFirebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

const db = myFirebase.firestore();
const auth = firebase.auth();

export { db, auth };
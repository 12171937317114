//Archivo de configuración con las variables parametrizables del software.

//Json de configuración para ambiente de desarrollo
const firebaseConfigDevEnv = {
  apiKey: "AIzaSyDkgHPr3L8uP1fROs88p1dv8wd_5MKlm38",
  authDomain: "healthy-wares-288219.firebaseapp.com",
  databaseURL: "https://healthy-wares-288219.firebaseio.com",
  projectId: "healthy-wares-288219",
  storageBucket: "healthy-wares-288219.appspot.com",
  messagingSenderId: "27573074624",
  appId: "1:27573074624:web:2a27cb5854b1dbc3834b25",
  measurementId: "G-VYV236FKZN"
};


//Json de configuración para ambiente de QA - pruebas
const firebaseConfigQAEnv = {
  apiKey: "AIzaSyBAY55z-TX3AUKfEjCQwU-dTrqG0x9J784",
    authDomain: "insights-295219.firebaseapp.com",
    projectId: "insights-295219",
    storageBucket: "insights-295219.appspot.com",
    messagingSenderId: "800701619672",
    appId: "1:800701619672:web:3746ae8d0f2f273fb166ad",
    measurementId: "G-90R8EBZF8C"
};

const localhost = "http://localhost:5000/insightsAPI/";

//URL DEL SERVICIO (API) EN DESARROLLO
const urlApiDev = "https://insights-295219.ew.r.appspot.com/insightsAPI/";


//URL DEL SERVICIO (API) EN QA
const urlApiQA = "https://insights-295219.ew.r.appspot.com/";

//URL DEL SERVICIO (API) EN PRODUCCIÓN
const urlApiProd = "";

module.exports = { urlApiDev, urlApiQA, urlApiProd, localhost, firebaseConfigDevEnv, firebaseConfigQAEnv }

import React from 'react';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload) {
      return (
        <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
          <text className="label" style={{'font-size':'13px',  'color':'#cb2821'}}>{`Hora :
          ${payload[0].payload.Hora}`}<br/></text>

          <text className="label" style={{'font-size':'13px', 'color':'#122860'}}>{`Porcentaje :
          ${payload[0].value}
          ${payload[0].unit}`}<br/></text>

          <text className="label" style={{'font-size':'13px',  'color':'#122860'}}>{`Visitas :
          ${Number.parseFloat(payload[0].payload.visitas).toLocaleString('es-CO')}`}<br/></text>
        </div>
      );
    }
  }
  return null;
};

import React, { Component } from "react";
import TemplateComponent1 from "../maps/TemplateComponentOportEstrategicas1";
import TemplateComponent2 from "../maps/TemplateComponentOportEstrategicas2";
import Linechart from "../charts/b_11_LineChart";
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import SideMenu from '../menu/ResponsiveMenu';
import '../../resources/styles/bs_11_OportunidadesEstrategicas.css';
import '../../resources/styles/MainStyles.css';
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import { arrayToString } from '../../resources/helpers/stringFormatter';
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";

import { dateParams } from '../../resources/helpers/globalDates';


const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<1024) width=width / 1.2
else if ( width >= 1024 ) width = width / 2.3


//Clase que permite la ejecución del componente.
export default class VariacionAgrupadaComponent extends Component {

    constructor( props ){
    super( props );
    this.state = { 
    	keyTablero: '11OportunidadesEstrategicas', 
    	apiResponse: 0,
        localidad: '',
        upz: '',
        barrio: '',
        estrato: '',
        categoria: '',
        usuarioIniciado: '',
        filtro_fecha_inicio : dateParams.startDate,
        filtro_fecha_fin : dateParams.endDate,
        mapaSemanales : "11OportunidadesEstrategicasMapaSemanales",
        mapaAnuales: "11OportunidadesEstrategicasMapaAnuales",
        alto_mapa: 600,
        ancho_mapa: width
    };
  }

  asignarCategorias = ()=>{
    var content = "";
    // eslint-disable-next-line
    if(this.state.categoria == []){
      content = arrayToString(arrayCategoriasDos);
    } else {
      content = this.state.categoria;
    }
    return content;
  }

  updateQuery = (nuevoEstado) => {
    this.setState( {
      localidad : nuevoEstado.localidad,
      upz: nuevoEstado.upz,
      categoria: nuevoEstado.categoriasNoBasico,
      apiResponse: nuevoEstado.contadorDeCambios,
    })
  }

  updateDates = (nuevasFechas) => {
    // se hace use del operador spread, para evitar mutabilidad
    this.setState( prevState => ( {
            ...prevState ,  
            filtro_fecha_inicio : nuevasFechas.startDate,
            filtro_fecha_fin: nuevasFechas.endDate, 
            apiResponse: nuevasFechas.apiResponse
        } 
        ));
    }

  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.state.apiResponse === nextState.apiResponse){
      if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
        }
      return false
    } 
      return true
  }

  //Método para el renderizado del componente


    render(){
        return(
        <body>
            <div className="encabezado" >
                <div style={{'display':'contents'}}>
                    <div className="logo1" >
                        <img className="LogoImage" src={imgn} alt="imgn" /> 
                        <img className="LogoImage2" src={imgn2} alt="imgn" /> 
                    </div>
                    <div className="titulo1" >
                    Oportunidades estratégicas Comportamiento oferta y demanda.
                    </div>
                    <div className="calendario1" style={{'z-index':'80'}}>
                        <DatePickerComponent 
                            tablero = {this.state.keyTablero}
                            datesChanged = {this.updateDates}
                        />
                    </div>
                </div>
            </div>

            <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

            <div className="opciones_02" >
                <div style={{'display':'contents'}}>
                    <div className="subtitulo">
                        <h5 align="left" style={{'color':'#4F4F4F'}}>
                        Encuentre oportunidades en oferta y demanda en los desplazamientos del consumidor, según la subcategoría y la ubicación geográfica. </h5> 
                    </div>
                
                    <div className="filtros1">
                        <MultipleFiltersComponent 
                            tablero={this.state.keyTablero}
                            metrica={this.state.metrica}
                            onFilterChange={this.updateQuery}
                        />            
                    </div>
                </div>
            </div>

            <div className="paneltableros">
                <div className="contenedor1_Oportunidades_estrategicas">
                    <h5>Promedio de la Distancia del Desplazamiento </h5>
                    <Linechart
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.asignarCategorias()}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                        url={urlDev.urlApiDev+"ConsultaModuloAvanzado/BarrasVerticales"}
                    />
                </div>

                <div className="contenedor2_Oportunidades_estrategicas">
                    <h5>Desplazamientos: Demanda - Oferta </h5>
                    <TemplateComponent1
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.asignarCategorias()}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                        url = {urlDev.urlApiDev+"ConsultaModuloAvanzado/MapaUno"}
                        id_mapa_tablero = {this.state.mapaSemanales}
                        alto_mapa = {this.state.alto_mapa}
                        ancho_mapa = {this.state.ancho_mapa}
                    />
                </div>

                <div className="contenedor3_Oportunidades_estrategicas">
                    <h5> Desplazamientos: Demanda - Oferta</h5>          
                    <TemplateComponent2
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.asignarCategorias()}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                        url = {urlDev.urlApiDev+"ConsultaModuloAvanzado/MapaDos"}
                        id_mapa_tablero = {this.state.mapaAnuales}
                        alto_mapa = {this.state.alto_mapa}
                        ancho_mapa = {this.state.ancho_mapa}
                    />
                </div>
            </div>
            <div className="footer2">Footer</div>
        </body>

        )
    }
}
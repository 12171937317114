import _ from 'lodash'
import React, { PureComponent, useState, useEffect } from 'react';
import MultiSelect from 'react-multi-select-component';
import { Form } from 'semantic-ui-react';
import {ResponsiveContainer} from 'recharts';
import { db } from "../../firebase/firebase";
import { ExCodigoComercioUsuario } from "../menu/ResponsiveMenu";
import { arrayLocalidades, arrayUpzs, arrayBarrios } from "./geoFiltersInitializer";
import './filtersStyles.css'

export let arrayCategorias = ["Belleza y Bienestar", "Comercio", "Compras",
							"Entretenimiento", "Restaurantes", "Salud",
                        	"Servicios", "Turismo", "Tráfico y Transporte", 
                        	"El Distrito"]

export let arrayCategoriasDos = ["Cargando..."]


const LOCALIDADES = arrayLocalidades;
const UPZ = arrayUpzs;
const BARRIOS = arrayBarrios;
// variables para determinar si algun elemento de la jerarquia esta seleccionado y usar sus llaves
var esLocalidadFiltrada = false;
var esUPZFiltrada = false;
var esBarriosFiltrada = false;

let arrayEstratos = [1, 2, 3, 4, 5, 6]

let arrayMetricas = ["Ventas", "Flujos", "Búsquedas", "Ticket Prom", "Transacciones"];
let arrayMetricasResp = ["Visitas", "Duración promedio visita", "Frecuencia de visitas", "Duración visitas"];
let arrayMetricasMapaTipologias = ["Ventas", "Transacciones"];
let arrayVariaciones = ["Anual", "Semanal"];

let usuarioActualizado = false
var auxwidth = 160;

async function queryObtenerCategoriasHijo () {
	await db.collection("ClienteComercio")
    .where("IdentificadorUnico", "==", ExCodigoComercioUsuario )
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        if(data.length !== 0){
            arrayCategoriasDos.pop();
            arrayCategoriasDos = data[0].categorias_id_list;  
            usuarioActualizado = true
         }
      });
}


//Funciones para asignar las opciones de filtro a las listas desplegables.
const getOptionsLocalidades = (number) =>
  _.times(number, (index) => {
      return ({
        key: index,
        label: LOCALIDADES[index].label,
        value: LOCALIDADES[index].value,
      })
  })

const getOptionsUPZ = (number, banderaLocalidad, filtroLocalidad ) =>
   {
    var aux = []
    // si no se ha filtrado la estructura tiene un string vacio [ "" ]
    if ( banderaLocalidad )
    {
      // retorna las opciones filtradas
      _.times(number, (index) => {
        return (
          filtroLocalidad.includes( UPZ[index].id_localidadPadre+"" ) ? aux.push(
            {
              key: index,
              label: UPZ[index].label,
              value: UPZ[index].value,
            }) : ''
        )
      })
    }
    else
    {
      // retorna todas las opciones
      _.times(number, (index) => {
        return (
          aux.push(
            {
              key: index,
              label: UPZ[index].label,
              value: UPZ[index].value,
            })
        )
      })
    }
    return aux
 }

const getOptionsBarrios = ( number, banderaLocalidad, filtroLocalidad, banderaUPZ, filtroUPZ )  =>
  {
    var aux = []
    // si no se ha filtrado las estructuras son [ "" ]
    if ( banderaLocalidad && banderaUPZ )
    {
      // retorna las opciones filtradas
      _.times(number, (index) => {
        return (
          ( filtroLocalidad.includes( BARRIOS[index].id_localidadPadre+"" ) &&
            filtroUPZ.includes( BARRIOS[index].id_UPZPadre+"" ) ) ? aux.push(
            {
              key: index,
              label: BARRIOS[index].label,
              value: BARRIOS[index].value,
            }) : ''
        )
      })
    }
    else if ( banderaLocalidad )
    {
      // retorna las opciones filtradas
      _.times(number, (index) => {
        return (
          filtroLocalidad.includes( BARRIOS[index].id_localidadPadre+"" ) ? aux.push(
            {
              key: index,
              label: BARRIOS[index].label,
              value: BARRIOS[index].value,
            }) : ''
        )
      })
    }
    else if ( banderaUPZ ) {
      // retorna las opciones filtradas
      _.times(number, (index) => {
        return (
          filtroUPZ.includes( BARRIOS[index].id_UPZPadre+"" ) ? aux.push(
            {
              key: index,
              label: BARRIOS[index].label,
              value: BARRIOS[index].value,
            }) : ''
        )
      })
    }
    else
    {
      // retorna todas las opciones
      _.times(number, (index) => {
        return (
          aux.push(
            {
              key: index,
              label: BARRIOS[index].label,
              value: BARRIOS[index].value,
            })
        )
      })
    }
    return aux
  }

const getOptionsEstrato = (number) =>
  _.times(number, (index) => ({
    key: index,
    label: `${arrayEstratos[index]}`,
    value: arrayEstratos[index],
}))

const getOptionsCategoria = (number) =>
  _.times(number, (index) => (
  {
    key: index,
    label: `${arrayCategorias[index]}`,
    value: arrayCategorias[index],
  }
))

const getOptionsCategoriaDos = (number) =>
  _.times(number, (index) => (
  {
    key: index,
    label: `${arrayCategoriasDos[index]}`,
    value: arrayCategoriasDos[index],
  }
))

const getOptionsVariaciones = (number) =>
  _.times(number, (index) => ({
    key: index,
    label: `${arrayVariaciones[index]}`,
    value: arrayVariaciones[index],
}))

const getOptionsMetrica = (number) =>
  _.times(number, (index) => ({
    key: index,
    label: `${arrayMetricas[index]}`,
    value: arrayMetricas[index],
}))

const getOptionsMetricaResp = (number) =>
  _.times(number, (index) => ({
    key: index,
    label: `${arrayMetricasResp[index]}`,
    value: arrayMetricasResp[index],
}))

const getOptionsMetricaTipologias = (number) =>
  _.times(number, (index) => ({
    key: index,
    label: `${arrayMetricasMapaTipologias[index]}`,
    value: arrayMetricasMapaTipologias[index],
}))



// clase principal
export default class MultipleFiltersComponent extends PureComponent {
 	constructor(props) {
    	super(props);
    	if (props.tablero === 'DispersionVariaciones'){
      		this.state = {
        		localidad: '',
        		upz: '',
		        barrio: '',
		        estrato: '',
		        categoriasBasico: '',
		        metricaY: props.metricaY,
		        metricaX: props.metricaX,
		        variacion: props.variacion,
		        tablero: props.tablero,
		        contadorDeCambios: -1,
		    }
    	}
    	else if (props.tablero === 'impactoCambiosPorcentuales'){
    		this.state = {
    			localidad: '',
    			upz: '',
    			barrio: '',
    			estrato: '',
    			categoriasBasico: props.categoria,
    			metrica: props.metrica,
    			variacion: props.variacion,
    			tablero: props.tablero,
    			contadorDeCambios: -1,
    			modulo: 'basico'
    		}
    	}
    	else {
    		this.state = {
    			localidad: '',
    			upz: '',
    			barrio: '',
    			estrato: '',
    			categoriasBasico: '',
    			categoriasNoBasico: '',
    			metrica: props.metrica,
    			tablero: props.tablero,
    			contadorDeCambios: -1,
    			modulo: props.modulo
    		}
    	}
    }

//Manejador del evento cuando se selecciona algun filtro.
handleChange = (eventArray, name) => {
	//asigna un string separado por comas como valores de filtro [name] a cada posibilidad
    let event_values = ''
    let one_or_more_filters = false

    //revisa si el filtro seleccionado corresponde a los de seleccion unica o multiple
    if ( eventArray.length > 0 ) {
      //si es unica, debe tomar solo un valor
      if(  ! ( [ "localidad", "upz", "barrio", "estrato", 
      	"categoriasBasico", "categoriasNoBasico" ].includes( name ) ) )
      {
      	event_values += eventArray[0].value
      }
      //sino, concatena la lista de valores e indica que una o varias opciones del filtro estan seleccionadas
      else
      {
      	for ( let i in eventArray ) event_values += eventArray[i].value + ","
      	
      	event_values = event_values.substring(0, event_values.length-1)
      	one_or_more_filters = true
      	event_values = event_values.split(",")
      }

 	}
  	this.setState( function(state, props)
  	{
  		return {
  			[name]: event_values,
  			contadorDeCambios: this.state.contadorDeCambios+1,
  		};
  	});

    // actualiza las nodos de jerarquia
    switch (name) {
    	case 'localidad':
    	esLocalidadFiltrada = one_or_more_filters
    	break;

    	case 'upz':
    	esUPZFiltrada = one_or_more_filters
    	break;

    	case 'barrio':
    	esBarriosFiltrada = one_or_more_filters
    	break;

    	default:
    	break;
    }
}


/// si no se actualiza la pagina mantiene al usuario registrado con sus categorias asociadas
// si sucede un refrescado en el navegador, recuerda las categorias y las vuelve a guardar. 

async componentWillReceiveProps(nextProps) {
  	if ( ! usuarioActualizado ){
  		await queryObtenerCategoriasHijo()
  		this.setState( () => { 
  			return {
  				categoriasBasico: arrayCategorias, 
  				categoriasNoBasico: arrayCategoriasDos, 
  				contadorDeCambios: this.state.contadorDeCambios+1} 
  			}
		) 
  	}
}
  
componentDidUpdate(){
  	if (usuarioActualizado) this.props.onFilterChange( this.state ) ;
}

//permite que al cerrar sesión y volver a entrar con otro usuario 
// realice de nuevo las consultas a firebase y no se queden pegados los datos 
// anteriores 
componentWillUnmount(){
    usuarioActualizado = false
    arrayCategoriasDos = ["Cargando..."]
}  


render() {

    auxwidth = 160;
    if(this.state.tablero === '12ComportamientoConsumidor') {
      auxwidth = 221;
    } else if(this.state.tablero === '11OportunidadesEstrategicas') {
      auxwidth = 310;
    } else if (this.state.tablero === 'DispersionVariaciones') {
      auxwidth = 115;
    }

    if(this.state.tablero === '01perfilBogota' || this.state.tablero === '02perfilComercios' 
      || this.state.tablero === '03RecuperacionEconcomica' || this.state.tablero === 'DispersionVariaciones' 
      || this.state.tablero === '05VariacionesAgrupadas' || this.state.tablero === '06impactoCambiosPorcentuales' || this.state.tablero === 'intro'
      || this.state.tablero === 'userConfig'){

      if (this.state.tablero === 'DispersionVariaciones'){
      return (
        <div className="ContainerFilter4" id="pruebafiltro">
        <Form size="mini" id="filterForm" >
          <Form.Group>
            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "localidad"
                options={ getOptionsLocalidades( LOCALIDADES.length ) }
                disabled={ esUPZFiltrada || esBarriosFiltrada }
                handleChange={ this.handleChange }
                value={this.state.localidad}
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "upz"
                options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                disabled={ esBarriosFiltrada }
                handleChange={ this.handleChange }
                value={this.state.upz}
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "barrio"
                options={ getOptionsBarrios( BARRIOS.length, esLocalidadFiltrada, this.state.localidad, esUPZFiltrada, this.state.upz ) }
                disabled={ false }
                handleChange={ this.handleChange }
                value={this.state.barrio}
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "estrato"
                options={ getOptionsEstrato( arrayEstratos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                value={this.state.estrato}
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "categoriasBasico"
                options={ getOptionsCategoria( arrayCategorias.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={true}
                prefixedValue={this.state.categoriasBasico}
              />
            </Form.Field>


            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "variacion"
                options={ getOptionsVariaciones( arrayVariaciones.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.variacion}
                isUniqueSelect = {true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "metricaX"
                options={ getOptionsMetrica( arrayMetricas.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.metricaX}
                isUniqueSelect = {true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "metricaY"
                options={ getOptionsMetrica( arrayMetricas.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.metricaY}
                isUniqueSelect = {true}
              />
            </Form.Field>


          </Form.Group>
        </Form>

      </div>
      )
    }  else {
      return (
      <div className="ContainerFilter3">
        <Form size="mini" >
          <Form.Group>
            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "localidad"
                options={ getOptionsLocalidades( LOCALIDADES.length ) }
                disabled={ esUPZFiltrada || esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "upz"
                options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                disabled={ esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "barrio"
                options={ getOptionsBarrios( BARRIOS.length, esLocalidadFiltrada, this.state.localidad, esUPZFiltrada, this.state.upz ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "estrato"
                options={ getOptionsEstrato( arrayEstratos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "categoriasBasico"
                options={ getOptionsCategoria( arrayCategorias.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                prefixedValue={this.state.categoriasBasico}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "metrica"
                options={ getOptionsMetrica( arrayMetricas.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.metrica}
                isUniqueSelect = {true}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      )
    }

    } else {

      if(this.state.tablero === 'DistribucionTipologia') {
      return (
      <div className="ContainerFilter3">
        <Form size="mini" >
          <Form.Group>
            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "localidad"
                options={ getOptionsLocalidades( LOCALIDADES.length ) }
                disabled={ esUPZFiltrada || esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "upz"
                options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                disabled={ esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "barrio"
                options={ getOptionsBarrios( BARRIOS.length, esLocalidadFiltrada, this.state.localidad, esUPZFiltrada, this.state.upz ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "estrato"
                options={ getOptionsEstrato( arrayEstratos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "categoriasNoBasico"
                options={ getOptionsCategoriaDos( arrayCategoriasDos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                prefixedValue={this.state.categoriasNoBasico}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "metrica"
                options={ getOptionsMetricaTipologias( arrayMetricasMapaTipologias.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.metrica}
                isUniqueSelect = {true}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      )
    } else if(this.state.tablero === '11OportunidadesEstrategicas'){
        return (
        <div className = "ContainerFilter2">
          <Form size="small" >
            <Form.Group>
              <Form.Field width={3} height={1}>
                <MultiSelectField
                  filterName = "localidad"
                  options={ getOptionsLocalidades( LOCALIDADES.length ) }
                  disabled={ esUPZFiltrada || esBarriosFiltrada }
                  handleChange={ this.handleChange }
                  hasSelectAll={true}
                />
              </Form.Field>

              <Form.Field width={3} height={1}>
                <MultiSelectField
                  filterName = "upz"
                  options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                  disabled={ esBarriosFiltrada }
                  handleChange={ this.handleChange }
                  hasSelectAll={false}
                />
              </Form.Field>

              <Form.Field width={3} height={1}>
                <MultiSelectField
                  filterName = "categoriasNoBasico"
                  options={ getOptionsCategoriaDos( arrayCategoriasDos.length ) }
                  disabled={ false }
                  handleChange={ this.handleChange }
                  prefixedValue={this.state.categoriasNoBasico}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        )
    }
    else if(this.state.tablero === '12ComportamientoConsumidor') {
      return (
      <div className = "ContainerFilter">
        <Form size="small" >
          <Form.Group>
            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "localidad"
                options={ getOptionsLocalidades( LOCALIDADES.length ) }
                disabled={ esUPZFiltrada || esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "upz"
                options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                disabled={ esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "categoriasNoBasico"
                options={ getOptionsCategoriaDos( arrayCategoriasDos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                prefixedValue={this.state.categoriasNoBasico}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "metrica"
                options={ getOptionsMetricaResp( arrayMetricasResp.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.metrica}
                isUniqueSelect = {true}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      )
    } else if (this.state.tablero === 'ComportamientoCiudadano') {
      return (
      <div className="ContainerFilter3">
        <Form size="mini" >
          <Form.Group>
            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "localidad"
                options={ getOptionsLocalidades( LOCALIDADES.length ) }
                disabled={ esUPZFiltrada || esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "upz"
                options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                disabled={ esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "barrio"
                options={ getOptionsBarrios( BARRIOS.length, esLocalidadFiltrada, this.state.localidad, esUPZFiltrada, this.state.upz ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "estrato"
                options={ getOptionsEstrato( arrayEstratos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "categoriasNoBasico"
                options={ getOptionsCategoriaDos( arrayCategoriasDos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                prefixedValue={this.state.categoriasNoBasico}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      )
    }   else if (['07DistribucionMercado', 'TendenciaMercado'].includes(this.state.tablero )) {
      return (
      <div className="ContainerFilter3">
        <Form size="mini" >
          <Form.Group>
            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "localidad"
                options={ getOptionsLocalidades( LOCALIDADES.length ) }
                disabled={ esUPZFiltrada || esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "upz"
                options={ getOptionsUPZ( UPZ.length, esLocalidadFiltrada, this.state.localidad ) }
                disabled={ esBarriosFiltrada }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "barrio"
                options={ getOptionsBarrios( BARRIOS.length, esLocalidadFiltrada, this.state.localidad, esUPZFiltrada, this.state.upz ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "estrato"
                options={ getOptionsEstrato( arrayEstratos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={true}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "categoriasNoBasico"
                options={ getOptionsCategoriaDos( arrayCategoriasDos.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                prefixedValue={this.state.categoriasNoBasico}
              />
            </Form.Field>

            <Form.Field width={3} height={1}>
              <MultiSelectField
                filterName = "metrica"
                options={ getOptionsMetrica( arrayMetricas.length ) }
                disabled={ false }
                handleChange={ this.handleChange }
                hasSelectAll={false}
                prefixedValue = {this.state.metrica}
                isUniqueSelect = {true}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      )
    }
  }
  }
}


// objeto retornado a cada campo de formulario de filtros
const MultiSelectField = ( props ) => {

	// estructura de propiedades de comportamiento de cada filtro
	const propsobject = {
      "selectSomeItems": props.filterName.charAt(0).toUpperCase() + props.filterName.slice(1, 10),
      "allItemsAreSelected": "Seleccionados todos",
      "selectAll": "Todos",
      "search": "Busca...",
      "clearSearch": "Borrar búsqueda"
  	};

  	// arreglo auxiliar para manejar valores preseleccionados en las opciones de filtros
  	let aux_arrayValue = []

 	if (props.prefixedValue) {
   		for (let i in props.options ){
     		if ( props.prefixedValue === props.options[i].value ){
       			aux_arrayValue = props.options[ i ]
      		}
    	}
    	aux_arrayValue = [ aux_arrayValue ]
  	}

 	const [selected, setSelected] = useState( aux_arrayValue )

 	// ejecuta metodo para Update en el padre
  	useEffect(() => {
  		// recive filterName como identificador de filtro
    	props.handleChange( selected, props.filterName )
  	}, [selected]);

  	//verifica los filtros aquellos que solo deben tener una unica seleccion
  	const setUniqueSingle = event => {
		let aux_selected = event
    	if ( event.length > 0 ){
      		aux_selected = [aux_selected[aux_selected.length-1]]
    	}
    	// no permite borrar prefixed single_values_selected
    	else { return }
    	setSelected( aux_selected )
  	}

  	return (
	    <ResponsiveContainer width={auxwidth} padding={4}>
	     	<MultiSelect
	        	options={props.options}
		        value={selected}
	    	    onChange={ e => {
	    	    	if(  ! ( [ "localidad", "upz", "barrio", "estrato", 
	    	    		"categoriasBasico", "categoriasNoBasico" ].includes(props.filterName) ) 
	    	    	){ setUniqueSingle( e ) }
	          		else { setSelected(e) }
	          		}
	        	}
	        	disabled={props.disabled}
	        	overrideStrings={propsobject}
	        	hasSelectAll={props.hasSelectAll}
	      	/>
	    </ResponsiveContainer>
    )

}
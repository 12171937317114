import React, { Component } from 'react';
import SideMenu from '../menu/ResponsiveMenu';
import { Link } from "react-router-dom";
import rectangulo from "../../resources/img/Rectangulo.png";
import rectangulo2 from "../../resources/img/Rectangulo2.png";
import colombia_productiva from "../../resources/img/colombia_productiva.png";
import credibanco from "../../resources/img/credibanco.png";
import mintic from "../../resources/img/mintic.png";
import { arrayToString } from '../../resources/helpers/stringFormatter';
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
//import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import '../../resources/styles/MainStyles.css';
import '../../resources/styles/bs_introduccion.css';

//const urlDev = require("../../properties/properties");
var categoriasString = arrayToString(arrayCategoriasDos);


//Clase que permite la ejecución del componente.
export default class VariacionAgrupadaComponent extends Component {

    constructor( props ){
        super( props );
        this.state = { keyTablero: 'intro', apiResponse: 0,
        categoria: categoriasString,
        usuarioIniciado: '',};
    }
    
      updateUserCats = (usuarioIniciado) => {
        if ( usuarioIniciado!== this.state.usuarioIniciado){
          this.setState( {usuarioIniciado: usuarioIniciado })
        }
      }
    
      updateQuery = ( ) => {}
    
    render(){
        return(
        <body>
            <div className="encabezado" >
                <div style={{'display':'contents'}}>
                    <div className="titulo1" >
                    Insights.
                    </div>
                </div>
            </div>

            <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

            <div className="paneltableros_intro">
                <div className="Contenedor_introduccion">
                    <h1>Indicadores económicos e Información localizada</h1>
                    
                    <img className="rectangulo_introduccion" src={rectangulo2} alt="rectangulo2" /> 

                    <h4>Analizamos la ciudad y sus comercios entendiendo los hábitos de los ciudadanos,
                    el consumo transaccional y las búsquedas digitales para proporcionar Insights que
                    respaldarán tus estrategias de negocio a nivel de las localidades hasta los barrios. </h4>

                    <h4><li>Revisamos las más de 11 millones de transacciones mensuales que
                    realizan los comercios de Bogotá.</li></h4>

                    <h4><li>Estudiamos el movimiento de más de 1 millón de usuarios únicos mensuales de
                    la aplicación Cívico App, para entender la preferencia de los consumidores.</li></h4>

                    <h4><li>Seguimos el comportamiento virtual de los usuarios Cívico, para
                    determinar que tan populares son los negocios on-line </li></h4>

                    <br></br><br></br>

                    <Link to="/glosario">
                        <h4 style={{'color':'#1890ff'}}>Haga clíc aquí y consulte el diccionario de términos.</h4>
                    </Link>

                    <div className ="Contenedor_imagenes_introduccion">
                        <div className ="Contenedor_imagenes_introduccion1">
                            <h3 class = "Contenedor_imagenes_introduccion1_text1">En alianza con:</h3>
                            <img id="i01" src={credibanco} alt="credibanco" />
                        </div>

                        <div className ="Contenedor_imagenes_introduccion2">
                            <h3 class = "Contenedor_imagenes_introduccion1_text2">Este es un proyecto en colaboración con: </h3>
                            <img id="i02" src={mintic} alt="mintic" />
                            <img id="i03" src={rectangulo} alt="rectangulo" />
                            <img id="i04" src={colombia_productiva} alt="colombia_productiva" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer2">Footer</div>
        </body>

        )
    }
}
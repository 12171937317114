import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip, Label, ResponsiveContainer
} from 'recharts';
import { formatTableDispersion } from '../../resources/helpers/numberFormatter'
//import '../styles/BoardStyleDistribucionMercado.css';

let jsonResponse = [];
var screen_width = window.innerWidth ;
if (screen_width<1024) screen_width = "100%"
else if ( screen_width >= 1024 ) screen_width = "80%"


export default class LineChartDistribucionMercadoUno extends PureComponent {

   constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz: props.filtro_upz,
      filtro_barrio: props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate,
      chart_width : screen_width
    };
  }


  //callAPi
  readQuery() {
    fetch(this.state.url+"?nombrecategoria="+this.state.filtro_categoria+"&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ))
      .then ( res => jsonResponse = this.state.apiResponse );
  }

  componentDidMount(){
    this.readQuery();
    window.addEventListener('resize', ()=>{
    let screen_width = window.innerWidth
    if (screen_width<1024) screen_width = "100%"
    else if ( screen_width >= 1024 ) screen_width = "80%"
    this.setState( prevState => ( { ...prevState, chart_width: screen_width } ) )
        
    } );
  }

  async componentWillReceiveProps(nextProps) {
    let params_string = "";
      // para cada parametro de filtro
      // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
      if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
        if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
      }
      else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

      if ( nextProps.filtro_upz !== this.state.filtro_upz ){
        if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
      }
      else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

      if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
        if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
      }
      else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

      if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
        if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
      }
      else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

      if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
        if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
      }
      else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

      if ( ( nextProps.startDate !== this.state.startDate ) ||  ( nextProps.endDate !== this.state.endDate )){
        params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
      }
      else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");


    if (params_string.length >= 2 ) params_string = params_string.substring(2);
    if(nextProps.filtro_metrica !== this.state.filtro_metrica) {

      } else {

      try {
        //call API
        /// mandar valores de filtros al servicio y determinar que responde para renderizar
        const response = await fetch(this.state.url + "?" + params_string);
        if (!response.ok){
          throw Error(response.statusText);
        }
        jsonResponse = await response.text();
        jsonResponse = JSON.parse(jsonResponse);
      }catch (error) {
        console.log(error);
      }
    }
    this.setState( {
      apiResponse: jsonResponse,
      filtro_localidad: nextProps.filtro_localidad,
      filtro_upz: nextProps.filtro_upz,
      filtro_barrio: nextProps.filtro_barrio,
      filtro_metrica: nextProps.filtro_metrica,
      filtro_categoria: nextProps.filtro_categoria,
      startDate: nextProps.startDate,
      endDate: nextProps.endDate
    }
  );
}

 shouldComponentUpdate(nextProps,nextState){
   if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica || this.state.chart_width !== nextState.chart_width){
        return true
      }
      return false
   } else {
      return true
   }
}

  componentDidUpdate(){
  }


  render() {
    var metrica = this.state.filtro_metrica;
    var categorias = this.state.filtro_categoria;
    var colorkey = -1;

    if (typeof(categorias) === "string") categorias=categorias.split(",")

    if ( ! Array.isArray( categorias ) ){
        categorias = [categorias]
    }


    const COLORS = ['#FF1F70', '#FF6D2F', '#FFA945', '#FFDC61',  '#73EA56', '#1BDFC8', '#36B0F4',
    '#1B4DAD', '#8E44C6', '#602A97'];

    if (this.state.filtro_metrica === "Ticket Prom") {
        metrica = "Tickets";
    } else if (this.state.filtro_metrica === "Búsquedas") {
        metrica = "Busquedas"
    }

    let valorCategorias = [];
    let valorCategorias2 = [];
    let ValorCategoriasLegend = [];

    for (let i = 1 ; i <= categorias.length ; i++){
      valorCategorias.push(metrica+"Categoria"+i)
      valorCategorias.push(metrica+"Categoria"+i+"_lagYear")
    }

    const leg = () => {
      var año = [];
      // eslint-disable-next-line
      this.state.apiResponse.map(index => {
        año.push(index.anoNumber);
      })
      return año;
    }

    var unidad = "";
    var año = "";
    var añofinal = 0;
    var añoPresente = 0;

    if (["Ventas", "Tickets"].includes(metrica)) {
      unidad = " M";
    }


    const distinct = (value,index, self) => {
      return self.indexOf(value) === index;
    }

    const array = leg().filter(distinct);

    if(array.length === 1){
      año = array[0];
      añofinal = parseInt(año);
      añoPresente = añofinal;
      añofinal = añofinal - 1;
    } else {
      // eslint-disable-next-line
      añofinal = " año anterior";
      añoPresente = " año presente"
    }

    for (let i = 0 ; i < categorias.length ; i++){
      if (categorias[i]){
        ValorCategoriasLegend.push(categorias[i]+" "+añoPresente)
        ValorCategoriasLegend.push(categorias[i]+" "+añofinal)
        valorCategorias2.push(categorias[i])
        valorCategorias2.push(categorias[i]+" "+añofinal)
      } 
    }


    var APIDataResponse = formatTableDispersion(this.state.apiResponse)
    // declare map Y datakey 


    var domainLimitAux_Max = 0 

    // Above document_key_row is ID_Semana
    for (let document_key_row in APIDataResponse){

      var objeto = APIDataResponse[document_key_row]
      var llaves  = Object.keys( objeto );
      // las key tambien son objetos : () 

      //eslint-disable-next-line 
      llaves.forEach( key => {
        let auxKey = key
        if (  valorCategorias.includes(auxKey.toString()) ){
            let value = parseFloat(objeto[auxKey].toString())
              if ( value >= domainLimitAux_Max )  
                domainLimitAux_Max = value
        }
      } )
    }

    domainLimitAux_Max = domainLimitAux_Max*1.15

    if ( metrica === "Tickets" ) {
      domainLimitAux_Max = domainLimitAux_Max.toFixed(1)
    }else{
      domainLimitAux_Max = domainLimitAux_Max.toFixed(0)
    }

    
    return (
      
      <ResponsiveContainer width={this.state.chart_width}
        height={350}>
      <LineChart
        data={ APIDataResponse }
        margin={{
          top: 10, right: -10, left: 30, bottom: 35,
        }}
      >

        <CartesianGrid stroke="#fffff" strokeDasharray="3 3"/>
        <XAxis  dataKey="ID_Week">
          <Label value="Semana" position="bottom"/>
        </XAxis>
        <YAxis type="number" unit = {unidad} domain={[dataMin => ( 0 ), dataMax => ( domainLimitAux_Max) ]} tick={{fontSize: 11, width : 250}}>
          <Label value="Evolución neta" angle={270} position='left' style={{ textAnchor: 'middle' }} />
        </YAxis>

        <Legend    
          layout="horizontal" 
          verticalAlign="bottom" 
          align="center" 
          wrapperStyle={{
            paddingTop: "30px"
        }}
          payload={
            ValorCategoriasLegend.map(
                      (entry, index) => {                     
                         if (categorias.includes(entry)){
                        return{
                        id: {index},
                        color: COLORS[index/2] ,
                        type: "rect",
                        value: `${entry}` ,
                      }}
                      else{
                       return{
                        id: {index},
                        color: COLORS[(index-1)/2 ] ,
                        type: "line",
                        value: `${entry}` ,
                      }}
                    }
                    )
                  }
        iconSize={ 11 } />
 
        <Tooltip />

        {  // eslint-disable-next-line
         categorias.map( (value, index) => {
           if ( valorCategorias2.includes( value ) ){
                return(
              <Line type="monotone"
              name = { value }
              dataKey = { valorCategorias[ index*2 ] }
              stroke = {COLORS[colorkey+=1]}
              activeDot = {{ r: 6 }}
              dot={ { r:0 } }
              unit = {unidad}/>             
            )
            }
          }

          )
      }

      {colorkey=-1}
      { // eslint-disable-next-line
        categorias.map( ( value, index ) => {
        if ( valorCategorias2.includes( value ) ){
                return(
              <Line type="monotone"
              name = { valorCategorias2[ valorCategorias2.indexOf( value ) + 1] }
              dataKey = {valorCategorias[ (index*2)+1 ]}
              stroke = {COLORS[colorkey+=1]}
              strokeDasharray= "5 3"
              dot={ {r:0} /*{r:3, fill:"none", stroke:COLORS[colorkey]}}*/ }
              activeDot = {{ fill:"none", stroke:COLORS[colorkey], r: 4 }}
              unit = {unidad}/>
            )
            }
            }

          )
      }
      </LineChart>
      </ResponsiveContainer>
    );
  }
}

export const METRICS = {
    VENTAS:'Ventas',
    BUSQUEDA:'Busquedas',
    TICKETS:'Tickets',
    TRANSACCIONES:'Transacciones',
    FLUJOS:'Flujos',
}

export const formatMetrica = (metricaString,number) => {
  switch (metricaString) {
    case METRICS.VENTAS:
      if(number !== null){
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(number.toFixed(2))
      }
      break;
    case METRICS.FLUJOS:
      if(number !== null){
        return new Intl.NumberFormat('es-CO').format(number.toFixed(2))
      }
      break;
    case METRICS.BUSQUEDA:
      if(number !== null){
        return new Intl.NumberFormat('es-CO').format(number.toFixed(2))
      }
      break;
    case METRICS.TICKETS:
      if(number !== null){
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(number.toFixed(2))
      }
      break;
    case METRICS.TRANSACCIONES:
      if(number !== null){
        return new Intl.NumberFormat('es-CO').format(number.toFixed(2))
      }
      break;
    default:
      return number
  }
}


export const formatTable = (table) => {
  return table.map(dataRow => {
    for(const key in dataRow){
      if(!['ID_localid', 'Nombre_Loc'].includes(key)) {
        dataRow[key] = Number(dataRow[key]).toFixed(2)
      }
    }
    return dataRow
  })
}

export const formatTableDispersion = (table) => {
  return table.map(dataRow => {
    for(const key in dataRow){
      if(!['ID_localid', 'Nombre_Loc', 'ID_Week', 'Lag_Week'].includes(key)) {
        dataRow[key] = Number(dataRow[key]).toFixed(2)
      }
    }
    return dataRow
  })
}


export const formatTableRecup = (table) => {
  return table.map(dataRow => {
    for(const key in dataRow){
      if(['cpventas', 'cpflujos', 'cpbusquedas', 'cpTiket_Promedio', 'cpTransacciones'].includes(key)) {
        dataRow[key] = (Number(dataRow[key]).toFixed(2))*1
      }
    }
    return dataRow
  })
}

export const formatTableAvanzadoUno = (table) => {
  return table.map(dataRow => {
    for(const key in dataRow){
      if(['ValorM1', 'ValorM2'].includes(key)) {
        dataRow[key] = (Number(dataRow[key]).toFixed(2))*1
      }
    }
    return dataRow
  })
}


export const castNumber = (table) => {
  return table.map(dataRow => {
    for(const key in dataRow){
      if(['Porcent'].includes(key)) {
        dataRow[key] = (Number(dataRow[key]).toFixed(2))*1
      }
    }
    return dataRow
  })
}

export const deletedecimal = (number) => {
  return parseFloat(number).toFixed()
}

//jerarquizacion de filtros localidad-upz-barrio
import datosjson from '../../resources/UPZ_DANE_ver1.json';
import jsonBarrios from '../../resources/A_Barrios_Distrito_Fisx_Civico.json';

export let arrayLocalidades=[];
export let arrayUpzs=[];
export let arrayBarrios=[];

let diccionarioLocalidades = {};

//Recorre archivo de UPZ para determinar localidades y upz
for(var i=0; i<datosjson.features.length;i++){
  let localidad_key = datosjson.features[i].properties.ID_Localid;
  //convierte los nombres de localidad a minúsculas menos inicial
  let words = datosjson.features[i].properties.Nombre_Loc.split(" ");
  let capitalized = ""
  for (let word in words) capitalized += " " + words[word].charAt(0) + words[word].slice(1).toLowerCase()
  capitalized = capitalized.trim()

  if( !( localidad_key in diccionarioLocalidades ) ){
    diccionarioLocalidades[localidad_key] = { label: capitalized, value: localidad_key};
    arrayLocalidades.push(diccionarioLocalidades[localidad_key])
  }

  //agrega upz
  words = datosjson.features[i].properties.Nombre_UPZ.split(" ");
  capitalized = ""
  for (let word in words) capitalized += " " + words[word].charAt(0) + words[word].slice(1).toLowerCase()
  capitalized = capitalized.trim()
  arrayUpzs.push( { label: capitalized, value: datosjson.features[i].properties.ID_UPZ, id_localidadPadre: datosjson.features[i].properties.ID_Localid } );
}

//Recorre archivo de Barrios para almacenarlos y determinar su localidad y upz respectivas
for( var k = 0; k < jsonBarrios.features.length; k++ ){
  let words = jsonBarrios.features[k].properties.Nombre_Bar.split(" ");
  let capitalized = ""
  for (let word in words) capitalized += " " + words[word].charAt(0) + words[word].slice(1).toLowerCase()
  capitalized = capitalized.trim()
  arrayBarrios.push( { label: capitalized, value: jsonBarrios.features[k].properties.ID_Barrio, id_localidadPadre: jsonBarrios.features[k].properties.ID_Localid, id_UPZPadre: jsonBarrios.features[k].properties.ID_UPZ} );
}
import React, { Component } from "react";
import TableChart from "../charts/b_07_TableChart-DistribucionMercado";
import PieChart from "../charts/b_07_PieChart-DistribucionMercado";
import TemplateComponent from "../maps/TemplateComponent";
import '../../resources/styles/MainStyles.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import '../../resources/styles/bs_07_DistribucionMercado.css';
import { arrayToString } from '../../resources/helpers/stringFormatter';
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";
import { dateParams } from '../../resources/helpers/globalDates'

const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<768) width= width / 1.2
else if ( width < 1024 ) width = width *0.86
else if ( width >= 1024 ) width = width *0.91

//Clase que permite la ejecución del componente.
export default class DistribucionMercado extends Component {

  constructor( props ){
    super( props );
    this.state = { keyTablero: '07DistribucionMercado', 
    apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    metrica: 'Ventas',
    usuarioIniciado: '',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    alto_mapa: 600,
    ancho_mapa: width
    };
  }

  asignarCategorias = ()=>{
    var content = "";
    if(this.state.categoria === []){
      content = arrayToString(arrayCategoriasDos);
    } else {
      content = this.state.categoria;
    }
    return content;
  }

  updateQuery = (nuevoEstado) => {
    this.setState( prevstate => (
    { ...prevstate, 
      localidad : nuevoEstado.localidad,
      upz: nuevoEstado.upz,
      barrio: nuevoEstado.barrio,
      estrato: nuevoEstado.estrato,
      categoria: nuevoEstado.categoriasNoBasico,
      metrica : nuevoEstado.metrica,
      apiResponse: nuevoEstado.contadorDeCambios,
    }))
  }

  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

  updateDates = (nuevasFechas) => {
    // se hace use del operador spread, para evitar mutabilidad
    this.setState( prevstate => (
      {
        ...prevstate, 
        filtro_fecha_inicio : nuevasFechas.startDate,
      filtro_fecha_fin: nuevasFechas.endDate,
      apiResponse: nuevasFechas.apiResponse} )) ;
  } 

   shouldComponentUpdate(nextProps,nextState){
     if(this.state.apiResponse === nextState.apiResponse){
       if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
        }
        return false
     }else {
        return true
     }
  }

  //Método para el renderizado del componente.
  render() {
    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (

      <body>
        <div className="encabezado" >
            <div style={{'display':'contents'}}>
                <div className="logo1" >
                        <img className="LogoImage" src={imgn} alt="imgn" /> 
                        <img className="LogoImage2" src={imgn2} alt="imgn" /> 
                    </div>
                <div className="titulo1" >
                Distribución geográfica del mercado por categoría.
                </div>
                <div className="calendario1">
                    <DatePickerComponent 
                        tablero = {this.state.keyTablero}
                        datesChanged = {this.updateDates}
                    />
                </div>
            </div>
        </div>


      <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 
          
      <div className="opciones" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>Encuentre  la clasificación de las subcategorías organizadas en un ranking 
          para evaluar como se comportan las métricas geográficamente en Bogotá.</h5>
            </div>
          
          <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
              onFilterChange={this.updateQuery}
            />            
          </div>
        </div>
      </div>

      <div className="paneltableros">

          <div className = "graph1">
              <h5> Clasificación de categorías por métrica. <br/></h5>
                <TableChart
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaDistribucionMercado/TablaUno"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
          </div>

          <div className = "graph2">
              <h5> Distribución de categorías por métrica. </h5> 
              <PieChart 
                filtro_localidad={this.state.localidad} 
                filtro_upz={this.state.upz}
                filtro_barrio={this.state.barrio} 
                filtro_estrato= {this.state.estrato}
                filtro_categoria= {this.asignarCategorias()} 
                filtro_metrica={this.state.metrica}
                url={urlDev.urlApiDev+"ConsultaDistribucionMercado/AnilloUno"}
                startDate={this.state.filtro_fecha_inicio} 
                endDate={this.state.filtro_fecha_fin} 
                /> 
          </div>


           <div className="mapa1r2c">
                <h5>Mapa de Variaciones en las Categorías.</h5>
                <TemplateComponent
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  url = {urlDev.urlApiDev+"ConsultaDistribucionMercado/MapaUno"}
                  id_mapa_tablero = {this.state.keyTablero}
                  alto_mapa = {this.state.alto_mapa}
                  ancho_mapa = {this.state.ancho_mapa}
                />
          </div>
        </div>
      <div className="footer2">Footer
      </div>
      </body>
    );
  }
}

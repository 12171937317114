import React, { PureComponent } from 'react';
import KeplerMapComponentv2 from './KeplerMapVaragrupada2';

export let datosProps;
export let metricaProps;
export let id_mapa_tablero;
export let alto_mapa_conf;
export let ancho_mapa_conf;
let jsonResponse = [];

export default class TemplateComponent extends PureComponent {

  constructor( props ){
    super( props );
    this.state = { apiResponse: [], 
      filtro_localidad : props.filtro_localidad,
      filtro_upz : props.filtro_upz,
      filtro_barrio : props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica, 
      startDate: props.startDate,
      endDate: props.endDate,
      url : props.url,
      id_mapa_tablero : props.id_mapa_tablero,
      alto_mapa: props.alto_mapa,
      ancho_mapa: props.ancho_mapa
    };
  }

  //initial callAPi
  readQuery() {
    fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD") 
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
      .then ( res => jsonResponse = this.state.apiResponse );
  }
  
  componentDidMount(){
    this.readQuery();
    window.addEventListener('resize', ()=>{
        let width = window.innerWidth
        if (width<1024) width=width / 1.2
        else if (width>=1024) width=width/2.3
        this.setState( prevState => ( { ...prevState, ancho_mapa: width } ) )
    } );
  }

  async componentWillReceiveProps(nextProps) {
    let params_string = "";
    //LOGICA: 
    // para cada parametro de filtro 
    // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido) 
    if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
      if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
    }
    else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

    if ( nextProps.filtro_upz !== this.state.filtro_upz ){
      if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
    } 
    else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

    if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
      if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
    }
    else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;
  
    if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
      if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
    }
    else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;
      
    if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
      if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria; 
    }
    else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;
      
    if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate )) params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
    else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");
      
    if (params_string.length >= 2 ) params_string = params_string.substring(2);
    
    if(nextProps.filtro_metrica !== this.state.filtro_metrica) {

    } else {
    try {
      //call API
      /// manda valores de filtros al servicio y obtiene respuesta para renderizar
      const response = await fetch(this.state.url + "?" + params_string);
      if (!response.ok){
        throw Error(response.statusText);
      }
      jsonResponse = await response.text();
      jsonResponse = JSON.parse(jsonResponse);
    }catch (error) {
      console.log(error);
    }
  }

    this.setState( { apiResponse: jsonResponse, filtro_localidad: nextProps.filtro_localidad, 
    filtro_metrica: nextProps.filtro_metrica, 
    startDate: nextProps.startDate,
    endDate: nextProps.endDate } );  
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica || this.state.ancho_mapa !== nextState.ancho_mapa ){
        return true
      }
      return false
    } else {
      return true
    }
  }

  render() {
     
     datosProps = this.state.apiResponse;
     metricaProps = this.state.filtro_metrica;
     id_mapa_tablero = this.state.id_mapa_tablero;
     alto_mapa_conf = this.state.alto_mapa;
     ancho_mapa_conf = this.state.ancho_mapa;

    return (
      <div>
        <KeplerMapComponentv2/>
      </div>
    );
  }
}

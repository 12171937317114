import React, { PureComponent } from 'react';
import {ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatTableAvanzadoUno } from '../../resources/helpers/numberFormatter';

let jsonResponse = [];

export default class LineChartPagDosComponent extends PureComponent {

  constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz : props.filtro_upz,
      filtro_barrio : props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica,
      filtro_metrica_lag: props.filtro_metrica_lag,
      filtro_metrica_cp : props.filtro_metrica_cp,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  //callAPi
  readQuery() {
    fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
      .then ( res => jsonResponse = this.state.apiResponse );
  }

  componentDidMount(){
    this.readQuery();
  }

  async componentWillReceiveProps(nextProps) {
      let params_string = "";
        // para cada parametro de filtro
      // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
      if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
        if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
      }
      else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

      if ( nextProps.filtro_upz !== this.state.filtro_upz ){
        if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
      }
      else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

      if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
        if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
      }
      else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

      if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
        if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
      }
      else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

      if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
        if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
      }
      else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

      if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate )) params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
      else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");


      if (params_string.length >= 2 ) params_string = params_string.substring(2);
      if(nextProps.filtro_metrica !== this.state.filtro_metrica) {

      } else {

        try {
          //call API
          /// mandar valores de filtros al servicio y determinar que responde para renderizar
          const response = await fetch(this.state.url + "?" + params_string);
          if (!response.ok){
            throw Error(response.statusText);
          }
          jsonResponse = await response.text();
          jsonResponse = JSON.parse(jsonResponse);
        }catch (error) {
          console.log(error);
        }
      }
      this.setState( {
        apiResponse: jsonResponse,
        filtro_localidad: nextProps.filtro_localidad,
        filtro_metrica: nextProps.filtro_metrica,
        filtro_metrica_lag: nextProps.filtro_metrica_lag,
        filtro_metrica_cp: nextProps.filtro_metrica_cp,
        startDate: nextProps.startDate,
        endDate: nextProps.endDate
      }
    );
}

 shouldComponentUpdate(nextProps,nextState){
   if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica){
        return true
      }
      return false
   } else {
      return true
   }
}


render() {

    if (this.state.filtro_metrica === "Ventas") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Suma_ventas_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpventas";
    } if (this.state.filtro_metrica === "Flujos") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Prom_flujos_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpflujos";
    } if (this.state.filtro_metrica === "Busquedas") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Suma_Busquedas_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpbusquedas";
    } if (this.state.filtro_metrica === "Tickets") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Prom_Tiket_Promedio_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpTiket_Promedio";
    } if (this.state.filtro_metrica === "Transacciones") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Suma_transacciones_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpTransacciones";
    }

    return (
        <ResponsiveContainer width='100%' height={350}>
            <ComposedChart
                data={formatTableAvanzadoUno(this.state.apiResponse)}
                margin={{
                top: 20, right: 0, bottom: 20, left: 20,
                }}
            >
                <CartesianGrid stroke="#fffff" strokeDasharray="3 3"/>
                <XAxis dataKey="Nombre_UPZ" tick={{fontSize: 10}} />
                <YAxis yAxisId="left" tick={{fontSize: 10}} unit={'mts'} />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="ValorM1" name="Desplazamientos: Demanda - Oferta" fill="#43CDB5" unit={' mts'}/>
                <Bar yAxisId="left" dataKey="ValorM2" name="Desplazamientos: Oferta - Demanda" fill="#FC6F59" unit={' mts'}/>
            </ComposedChart>
        </ResponsiveContainer> 
    );
  }
}

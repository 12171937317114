//Imports de librerias y componentes.
import React from "react";
import keplerGlReducer from "kepler.gl/reducers";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { taskMiddleware } from "react-palm/tasks";
import { Provider, useDispatch } from "react-redux";
import KeplerGl from "kepler.gl";
import { addDataToMap } from "kepler.gl/actions";
import Processors from 'kepler.gl/processors';
import '../../resources/styles/MapStyles.css';

import b_01_ConfigStyleKeplerBusquedas from '../../resources/KeplerJsonConfig/b_01_ConfigStyleKeplerBusquedas.json';
import b_01_ConfigStyleKeplerVentas from '../../resources/KeplerJsonConfig/b_01_ConfigStyleKeplerVentas.json';
import b_01_ConfigStyleKeplerFlujos from '../../resources/KeplerJsonConfig/b_01_ConfigStyleKeplerFlujos.json';
import b_01_ConfigStyleKeplerTiketPromedio from '../../resources/KeplerJsonConfig/b_01_ConfigStyleKeplerTiketPromedio.json';
import b_01_ConfigStyleKeplerTransacciones from '../../resources/KeplerJsonConfig/b_01_ConfigStyleKeplerTransacciones.json';

import b_03_ConfigStyleKeplerBusquedasRecEcono from '../../resources/KeplerJsonConfig/b_03_ConfigStyleKeplerBusquedasRecEcono.json';
import b_03_ConfigStyleKeplerVentasRecEcono from '../../resources/KeplerJsonConfig/b_03_ConfigStyleKeplerVentasRecEcono.json';
import b_03_ConfigStyleKeplerFlujosRecEcono from '../../resources/KeplerJsonConfig/b_03_ConfigStyleKeplerFlujosRecEcono.json';
import b_03_ConfigStyleKeplerTiketPromedioRecEcono from '../../resources/KeplerJsonConfig/b_03_ConfigStyleKeplerTiketPromedioRecEcono.json';
import b_03_ConfigStyleKeplerTransaccionesRecEcono from '../../resources/KeplerJsonConfig/b_03_ConfigStyleKeplerTransaccionesRecEcono.json';

import b_06_ConfigStyleKeplerVentasQ5CambiosPorcentualesAnual from '../../resources/KeplerJsonConfig/b_06_ConfigStyleKeplerVentasQ5CambiosPorcentualesAnual.json';
import b_06_ConfigStyleKeplerBusquedasQ5CambiosPorcentualesAnual from '../../resources/KeplerJsonConfig/b_06_ConfigStyleKeplerBusquedasQ5CambiosPorcentualesAnual.json';
import b_06_ConfigStyleKeplerFlujosQ5CambiosPorcentualesAnual from '../../resources/KeplerJsonConfig/b_06_ConfigStyleKeplerFlujosQ5CambiosPorcentualesAnual.json';
import b_06_ConfigStyleKeplerTransaccionesQ5CambiosPorcentualesAnual from '../../resources/KeplerJsonConfig/b_06_ConfigStyleKeplerTransaccionesQ5CambiosPorcentualesAnual.json';
import b_06_ConfigStyleKeplerTicketsQ5CambiosPorcentualesAnual from '../../resources/KeplerJsonConfig/b_06_ConfigStyleKeplerTicketsQ5CambiosPorcentualesAnual.json';

import b_07_ConfigStyleKeplerBusquedasDistMerc from '../../resources/KeplerJsonConfig/b_07_ConfigStyleKeplerBusquedasDistMerc.json';
import b_07_ConfigStyleKeplerVentasDistMerc from '../../resources/KeplerJsonConfig/b_07_ConfigStyleKeplerVentasDistMerc.json';
import b_07_ConfigStyleKeplerFlujosDistMerc from '../../resources/KeplerJsonConfig/b_07_ConfigStyleKeplerFlujosDistMerc.json';
import b_07_ConfigStyleKeplerTiketPromedioDistMerc from '../../resources/KeplerJsonConfig/b_07_ConfigStyleKeplerTiketPromedioDistMerc.json';
import b_07_ConfigStyleKeplerTransaccionesDistMerc from '../../resources/KeplerJsonConfig/b_07_ConfigStyleKeplerTransaccionesDistMerc.json';

import b_12_ConfigStyleKeplerMADuracion_min from '../../resources/KeplerJsonConfig/b_12_ConfigStyleKeplerMADuracion_min.json';
import b_12_ConfigStyleKeplerMADuracion_promedio from '../../resources/KeplerJsonConfig/b_12_ConfigStyleKeplerMADuracion_promedio.json';
import b_12_ConfigStyleKeplerMAFrecuencia_Visitas from '../../resources/KeplerJsonConfig/b_12_ConfigStyleKeplerMAFrecuencia_Visitas.json';
import b_12_ConfigStyleKeplerMAVisitas from '../../resources/KeplerJsonConfig/b_12_ConfigStyleKeplerMAVisitas.json';

// faltan tipologia, visitas, y distribucionmercado

import b_10_ConfigStyleKeplerTipologiaVentasCredito from '../../resources/KeplerJsonConfig/b_10_ConfigStyleKeplerTipologiaVentasCredito.json';
import b_10_ConfigStyleKeplerTipologiaTransaccionesCredito from '../../resources/KeplerJsonConfig/b_10_ConfigStyleKeplerTipologiaTransaccionesCredito.json';

// faltan visitasMA

import { id_mapa_tablero, datosProps, metricaProps, alto_mapa_conf, ancho_mapa_conf } from './TemplateComponent';


//Reducer perzonalidado del mapa en donde se indica que parámetros cargan por defecto.
const customizedKeplerGlReducer =
keplerGlReducer.initialState({
  uiState: {readOnly: true},
  }
);

//Lamado al reducer personalizado.
const reducers = combineReducers({
  keplerGl: customizedKeplerGlReducer,
});

//Creación del store de kepler y llamado del reducer.


const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));



//Función que permite la ejecución del mapa.
export default function KeplerMapComponent() {

  //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
  return (
    <div>
      <Provider store={store}>
        <Map />
      </Provider>
    </div>
  );
}

//Función para pasar los parámetros de datos que se cargaran al mapa.
function Map() {
  //Inicialización del dispatch requerido en kepler.
  const dispatch = useDispatch();

  //Almacenamiento de los datos enviados por el filtro en una variable del componente.
  let data = datosProps;
  let DataFin = Processors.processRowObject(data);
  let jsonConfigurator;

  //Método de carga de datos al mapa y visualización de estos.
  React.useEffect(() => {
    switch (id_mapa_tablero) {
    // tablero impactoCambiosPorcentuales

    // reemplazar ya existente actualizar mapa perfil bogota
    case "01perfilBogota":
    switch (metricaProps) {
        case "Búsquedas":
        // eslint-disable-next-line
          jsonConfigurator  = b_01_ConfigStyleKeplerBusquedas;
          break;
        case "Ventas":
          jsonConfigurator  = b_01_ConfigStyleKeplerVentas;
          break;
        case "Flujos":
          jsonConfigurator  = b_01_ConfigStyleKeplerFlujos;
          break;
        case "Ticket Prom":
          jsonConfigurator  = b_01_ConfigStyleKeplerTiketPromedio;
          break;
        case "Transacciones":
          jsonConfigurator  = b_01_ConfigStyleKeplerTransacciones;
          break;
        default:
          console.log("unappropiate user selection");
          break;
      }
      break;

      case "03RecuperacionEconcomica":
    switch (metricaProps) {
        case "Búsquedas":
          jsonConfigurator   = b_03_ConfigStyleKeplerBusquedasRecEcono;
          break;
        case "Ventas":
          jsonConfigurator   = b_03_ConfigStyleKeplerVentasRecEcono;
          break;
        case "Flujos":
          jsonConfigurator   = b_03_ConfigStyleKeplerFlujosRecEcono;
          break;
        case "Ticket Prom":
          jsonConfigurator   = b_03_ConfigStyleKeplerTiketPromedioRecEcono;
          break;
        case "Transacciones":
          jsonConfigurator   = b_03_ConfigStyleKeplerTransaccionesRecEcono;
          break;
        default:
          console.log("unappropiate user selection");
          break;
      }
    break;


    case '06impactoCambiosPorcentuales':
      switch (metricaProps) {
        case "Búsquedas":
        // eslint-disable-next-line
          jsonConfigurator   = b_06_ConfigStyleKeplerBusquedasQ5CambiosPorcentualesAnual;
          break;
        case "Ventas":
          jsonConfigurator   = b_06_ConfigStyleKeplerVentasQ5CambiosPorcentualesAnual;
          break;
        case "Flujos":
          jsonConfigurator   = b_06_ConfigStyleKeplerFlujosQ5CambiosPorcentualesAnual;
          break;
        case "Transacciones":
          jsonConfigurator   = b_06_ConfigStyleKeplerTransaccionesQ5CambiosPorcentualesAnual;
          break;
        case "Ticket Prom":
          jsonConfigurator   = b_06_ConfigStyleKeplerTicketsQ5CambiosPorcentualesAnual;
          break;
        default:
          console.log("unappropiate selection");
          break;
    }
    break;

    case "07DistribucionMercado":
    switch (metricaProps) {
        case "Búsquedas":
          jsonConfigurator   = b_07_ConfigStyleKeplerBusquedasDistMerc;
          break;
        case "Ventas":
          jsonConfigurator   = b_07_ConfigStyleKeplerVentasDistMerc;
          break;
        case "Flujos":
          jsonConfigurator   = b_07_ConfigStyleKeplerFlujosDistMerc;
          break;
        case "Ticket Prom":
          jsonConfigurator   = b_07_ConfigStyleKeplerTiketPromedioDistMerc;
          break;
        case "Transacciones":
          jsonConfigurator   = b_07_ConfigStyleKeplerTransaccionesDistMerc;
          break;
        default:
          console.log("unappropiate user selection");
          break;
      }
      break;

      case "DistribucionTipologia":
      switch (metricaProps) {
        case "Ventas":
          jsonConfigurator   = b_10_ConfigStyleKeplerTipologiaVentasCredito;
          break;
        case "Transacciones":
          jsonConfigurator   = b_10_ConfigStyleKeplerTipologiaTransaccionesCredito;
          break;
        default:
          console.log("unappropiate user selection");
          break;
      }
      break;


    case "12ComportamientoConsumidor":
      switch (metricaProps) {
        case "Visitas":
          jsonConfigurator = b_12_ConfigStyleKeplerMAVisitas;
          break;
        case "Duración promedio visita":
          jsonConfigurator   = b_12_ConfigStyleKeplerMADuracion_promedio;
          break;
        case "Frecuencia de visitas":
          jsonConfigurator   = b_12_ConfigStyleKeplerMAFrecuencia_Visitas;
          break;
        case "Duración visitas":
          jsonConfigurator   = b_12_ConfigStyleKeplerMADuracion_min;
          break;
        default:
          console.log("unappropiate user selection");
          break;
      }

      break;
    
      default:
        console.log("unappropiate user selection");
      break;
}

      dispatch(
        addDataToMap({
        // datasets
        datasets: {
          info: {
            id: 'my_data'
          },
          data: DataFin,
        },
        option: {},
        // config
        config: jsonConfigurator
        }
      )
      );
  }, [dispatch, DataFin]);

  let id = Math.random();
  id = id.toString();

  let altura = Number(alto_mapa_conf);
  let ancho = Number(ancho_mapa_conf);

  //Asignación de propiedades al estilo del mapa y token de mapbox.
  return (
    <KeplerGl
      id={id}
      // eslint-disable-next-line
      style={'mapbox://styles/mapbox/streets-v11'}
      theme="base"
      mapboxApiAccessToken={"pk.eyJ1IjoiY2FtaWxvbW9udG95YTAwMSIsImEiOiJja2V5aDZ4bHAwZHptMnNtYmU1cTQybmI5In0.XWyu0GoCdnuoZQpb_sd1IQ"}
      width={ ancho }
      height={altura}
    />
  );
}

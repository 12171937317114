export const url = (metrica) => {
  var url = " ";
  switch (metrica) {
    case "Visitas":
      url = "https://scademo2021.uc.r.appspot.com/insightsAPI/ConsultaModuloAvanzado/MapaUnoTab21";
      break;
    case "Duración promedio visita":
      url = "https://scademo2021.uc.r.appspot.com/insightsAPI/ConsultaModuloAvanzado/MapaUnoTab24";
      break;
    case "Frecuencia de visitas":
      url = "https://scademo2021.uc.r.appspot.com/insightsAPI/ConsultaModuloAvanzado/MapaUnoTab23";
      break;
    case "Duración visitas":
      url = "https://scademo2021.uc.r.appspot.com/insightsAPI/ConsultaModuloAvanzado/MapaUnoTab22";
      break;
    default:
      url = "https://scademo2021.uc.r.appspot.com/insightsAPI/ConsultaModuloAvanzado/MapaUnoTab22";
      break;
  }
  return url;
}

import React, { Component } from "react";
import Line1 from "../charts/b_08_LineChart1-DistribucionMercado";
import Line2 from "../charts/b_08_LineChart2-DistribucionMercado";
import '../../resources/styles/MainStyles.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
//import '../styles/BoardStyleTendenciaMercado.css';
import { arrayToString } from '../../resources/helpers/stringFormatter';
import { arrayCategoriasDos } from "../filters/MultipleFiltersComponent";
import { dateParams } from '../../resources/helpers/globalDates'
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";

const urlDev = require("../../properties/properties");

//Clase que permite la ejecución del componente.
export default class TendenciaMercado extends Component {

constructor( props ){
    super( props );
    this.state = { keyTablero: 'TendenciaMercado', 
    apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    metrica: 'Flujos',
    usuarioIniciado: '',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    modulo: 'intermedio'
    };
  }

  asignarCategorias = ()=>{
   var content = "";
   // eslint-disable-next-line
   if(this.state.categoria == []){
     content = arrayToString(arrayCategoriasDos);
   } else {
     content = this.state.categoria;
   }
   return content;
 }

  updateQuery = (nuevoEstado) => {
    
     this.setState( prevstate => (
      {  ...prevstate, 
        localidad : nuevoEstado.localidad,
        upz: nuevoEstado.upz,
        barrio: nuevoEstado.barrio,
        estrato: nuevoEstado.estrato,
        categoria: nuevoEstado.categoriasNoBasico,
        metrica : nuevoEstado.metrica,
        apiResponse: nuevoEstado.contadorDeCambios
      } ) )
  }

  updateDates = (nuevasFechas) => {
    this.setState( prevstate => (
      { ...prevstate, 
      filtro_fecha_inicio : nuevasFechas.startDate,
      filtro_fecha_fin: nuevasFechas.endDate,
      apiResponse: nuevasFechas.apiResponse} ) );
  }


  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado !== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

   shouldComponentUpdate(nextProps,nextState){
     if(this.state.apiResponse === nextState.apiResponse ){
        if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
        }
        return false
     }else {
        return true
     }
  }
  
  //Método para el renderizado del componente.
  render() {

    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (
      <body >
        <div className="encabezado" >
            <div style={{'display':'contents'}}>
                <div className="logo1" >
                        <img className="LogoImage" src={imgn} alt="imgn" /> 
                        <img className="LogoImage2" src={imgn2} alt="imgn" /> 
                    </div>
                <div className="titulo1" >
                  Tendencia del mercado por categoría.
                </div>
                <div className="calendario1">
                    <DatePickerComponent 
                        tablero = {this.state.keyTablero}
                        datesChanged = {this.updateDates}
                    />
                </div>
            </div>
        </div>


     <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <div className="opciones" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>Analice las variaciones netas y porcentuales de la métricas, 
            para establecer tendencias en el mercado.</h5>
            </div>
          
            <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
              onFilterChange={this.updateQuery}
            />            
          </div>
        </div>
      </div>

  <div className="paneltableros">

          <div className="graphs-Pag2">
             <h5> Evolución neta </h5>
                <Line1
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaTendenciaMercado/LineasUno"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
              </div>

            <div>
              
             <h5>  Variaciones porcentuales </h5>
                <Line2
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaTendenciaMercado/LineasDos"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
           </div>
      </div>

    <div className="footer2">Footer
    </div>
    </body>
    );
  }
}

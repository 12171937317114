export const METRICS = {
    VENTAS:'Ventas',
    BUSQUEDA:'Búsquedas',
    TICKETS:'Ticket Prom',
    TRANSACCIONES:'Transacciones',
    FLUJOS:'Flujos',
}

export const sortOrder = (datosApi, filtroApi) =>{
	datosApi.sort(function(a,b){
      if(filtroApi === METRICS.VENTAS){
        return b.Ventas - a.Ventas;
      } else if(filtroApi === METRICS.FLUJOS){
          return b.Flujos - a.Flujos;
      } else if(filtroApi === METRICS.BUSQUEDA){
          return b.Busquedas - a.Busquedas;
      } else if(filtroApi === METRICS.TICKETS){
          return b.Tickets - a.Tickets;
      } else if(filtroApi === METRICS.TRANSACCIONES){
          return b.Transacciones - a.Transacciones;
      }
      return 0
	});
	return datosApi;
}

import React, { Component } from "react";
import TemplateComponent from "../maps/TemplateComponent";
import PieChart3 from "../charts/b_10_PieChart-DistribuciónTipologia";
import PieChart4 from "../charts/b_10_PieChart1-DistribuciónTipologia";
import '../../resources/styles/MainStyles.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
//import '../styles/BoardStyleDistribucionTipologias.css';
import '../../resources/styles/bs_07_DistribucionMercado.css';
import { titletipologia } from '../../resources/helpers/DinamicTitle';
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import { arrayToString } from '../../resources/helpers/stringFormatter';
import { dateParams } from '../../resources/helpers/globalDates'
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";


const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<768) width= width / 1.2
else if ( width < 1024 ) width = width *0.86
else if ( width >= 1024 ) width = width *0.91



//Clase que permite la ejecución del componente.
export default class DistribuciónTipologia extends Component {

  constructor( props ){
    super( props );
    this.state = { 
      keyTablero: 'DistribucionTipologia', 
      apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    metrica: 'Ventas',
    usuarioIniciado: '',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    alto_mapa: 600,
    ancho_mapa: width
    };
  }

  asignarCategorias = ()=>{
    var content = "";
    // eslint-disable-next-line
    if(this.state.categoria == []){
      content = arrayToString(arrayCategoriasDos);
    } else {
      content = this.state.categoria;
    }
    return content;
  }


  updateQuery = (nuevoEstado) => {
      this.setState( {
        localidad : nuevoEstado.localidad,
        upz: nuevoEstado.upz,
        barrio: nuevoEstado.barrio,
        estrato: nuevoEstado.estrato,
        categoria: nuevoEstado.categoriasNoBasico,
        metrica : nuevoEstado.metrica,
        apiResponse: nuevoEstado.contadorDeCambios,
      })

  }

  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

  updateDates = (nuevasFechas) => {
    this.setState( {
      filtro_fecha_inicio : nuevasFechas.startDate,
      filtro_fecha_fin: nuevasFechas.endDate,
      apiResponse: nuevasFechas.apiResponse
    });
  }

   shouldComponentUpdate(nextProps,nextState){
     if(this.state.apiResponse === nextState.apiResponse){
      if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
        }
        return false
     }else {
        return true
     }
  }

  componentDidUpdate(){
  }

  //Método para el renderizado del componente.
  render() {

    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (

          <body >
    
      <div className="encabezado" >

        <div style={{'display':'contents'}}>

          <div className="logo1" >
            <img className="LogoImage" src={imgn} alt="imgn" /> 
            <img className="LogoImage2" src={imgn2} alt="imgn" /> 
          </div>
          <div className="titulo1" >
           Preferencias de pago del consumidor
          </div>
          <div className="calendario1" style={{'z-index':'80'}}>
            <DatePickerComponent 
              tablero = {this.state.keyTablero}
              datesChanged = {this.updateDates}
            />
          </div>
        </div>
      </div>

      <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <div className="opciones" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>
            Observe de qué manera se distribuyen las
          ventas y transacciones de los comercios según las preferencias de
          pago de los consumidores en datáfono. </h5> 
            </div>
          
              <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
                    onFilterChange={this.updateQuery}
                />            
          </div>
        </div>
      </div>


      <div className="paneltableros">


          <div className="graph1">
            <h5> Ventas </h5>
                <PieChart3
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaDistribucionTipologia/AnilloUno"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate={this.state.filtro_fecha_fin}
                />
              </div>
            <div className="graph2">
            <h5> Transacciones </h5>
                <PieChart4
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato={this.state.estrato}
                  filtro_categoria={this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaDistribucionTipologia/AnilloDos"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate={this.state.filtro_fecha_fin}
                />
              </div>
            
              <div className="mapa1r2c"><h5>{titletipologia(this.state.metrica)}</h5>
                <TemplateComponent
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.asignarCategorias()}
                  filtro_metrica={this.state.metrica}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  id_mapa_tablero = {this.state.keyTablero}
                  url = {urlDev.urlApiDev+"ConsultaDistribucionTipologia/MapaUno"}
                  alto_mapa = {this.state.alto_mapa}
                  ancho_mapa = {this.state.ancho_mapa}
                  />
              
              </div>
              </div>
          <div className="footer2">
          Footer
      </div>
    </body>
    );
  }
}

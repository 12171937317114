import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'antd/dist/antd.css';
import { Menu } from 'antd';
import { PieChartOutlined,
  LogoutOutlined, IdcardOutlined, DotChartOutlined, BarChartOutlined,
  SlidersOutlined, PartitionOutlined, FundProjectionScreenOutlined, 
  LineChartOutlined, HddOutlined, ShopOutlined, MonitorOutlined, TeamOutlined,
  DeploymentUnitOutlined, ToolOutlined, NodeIndexOutlined, BookOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import './antMenu.css'
import { logoutUser } from "../authentication";
import moment from 'moment';
import esLocale from 'moment/locale/es';
import { dateParams } from '../../resources/helpers/globalDates'



const { SubMenu } = Menu;


var llaveItemMenu = '0';
var llaveSubMenu1 = null;
var llaveSubMenu2 = null;

var bandera = true;


const refreshNumber = (valor) => {
    llaveItemMenu = valor.key;
    if(!valor.key){
      llaveItemMenu = valor.key;
    }
    // eslint-disable-next-line
    if(llaveItemMenu != 3 && llaveItemMenu != 4 && llaveItemMenu != 5 && llaveItemMenu != 6){
      llaveSubMenu1 = null;
      llaveSubMenu2 = null;
    }
  }

  const  refreshSubMenu1 = (valor) => {
    llaveSubMenu1 = valor.key;
  }

 const refreshSubMenu2 = (valor) => {
    llaveSubMenu2 = valor.key;
  }




  const retornarNombreMenu = (options, numero) => {
    var texto = "Cargando...";
    // eslint-disable-next-line
    if(options.length != 0 && bandera == true){
      for (let i = 0; i<options.length; i++){
        if (options[i].idOpcion == numero ) texto = options[i].nombreOpcion;  
      }
      
    } 
    return texto;
  }


const SidePan = ( props ) => {
  const state = useSelector(state => state.events)
  const dispatch = useDispatch() 
  
  const  handleLogout = (props) => {
    //bandera = false;
    moment.locale('es', esLocale);    
    dateParams.startDate= moment().add(-45, 'days')
    dateParams.endDate= moment().add(-14, 'days')
    dateParams.maxDate= moment().add(-30, 'days')

    dispatch(logoutUser());
    llaveItemMenu = '0';
  };

	let nombreRol =props.nombreRol
	let idModulo = props.idModulo
	let options = props.options
	let open = props.open
  console.log(props.usuarioFirebase)
	const { isLoggingOut, logoutError } = props;

  if(nombreRol == "superadministrador" || nombreRol == "administrador"){      
      // eslint-disable-next-line
    if( idModulo == 1){
        return (
          <div>
            <Menu
              defaultSelectedKeys={ [llaveItemMenu] }
              defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2] }
              mode="inline"
              inlineCollapsed={open}
              style={{'background-color': '#122860', 'color': 'white'}}
            >
              <Link to="/perfilBogota">
                <div/>
              </Link>

              <Menu.Item key="999" onClick={refreshNumber} icon={<IdcardOutlined/>}>
                {props.usuarioFirebase}
              </Menu.Item>

              <br></br>


              <Menu.Item key="0" onClick={refreshNumber} icon={<BookOutlined />}>
              { retornarNombreMenu( options, 15)}
              <Link to="/introduccion">
              </Link>
              </Menu.Item>

              <Menu.Item key="1" onClick={refreshNumber} icon={<PieChartOutlined />}>
              { retornarNombreMenu( options, 1)}
              <Link to="/perfilBogota">
              </Link>
              </Menu.Item>

              <Menu.Item key="2" onClick={refreshNumber} icon={<BarChartOutlined />}>
                { retornarNombreMenu( options, 2)}
                <Link to="/PerfilComercios">
                </Link>
              </Menu.Item>

              <SubMenu key="sub1" onTitleClick={refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3)}>
                <SubMenu key="sub2" onTitleClick={refreshSubMenu2} icon={<PartitionOutlined />} title={ retornarNombreMenu( options, 4)}>
                  <Menu.Item key="3" onClick={refreshNumber} icon={<LineChartOutlined />}>
                    { retornarNombreMenu( options, 5)}
                    <Link to="/recuperacionEconomica">
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4" onClick={refreshNumber} icon={<DotChartOutlined />}>
                    {retornarNombreMenu( options, 6)}
                    <Link to="/variacionSemanal">
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="5" onClick={ refreshNumber} icon={<HddOutlined />}>
                    { retornarNombreMenu( options, 7)}
                    <Link to="/variacionAgrupada">
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                  { retornarNombreMenu( options, 8)}
                  <Link to="/cambiosPorcentualesCovid19">
                  </Link>
                </Menu.Item>

              </SubMenu>
      
              <Menu.Item key="777" onClick={ refreshNumber} icon={<ToolOutlined />}>
                Configuración
                <Link to="/ConfiguracionUsuario">
                </Link>
              </Menu.Item>          

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu( options, 16)}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>

              <Menu.Item
              danger="true"
              onClick={ handleLogout}
              key="888" icon={<LogoutOutlined/>}>
                Cerrar Sesión 
              </Menu.Item>

            </Menu>

            {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
            {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

          </div>
        )
      } else if (idModulo==2)
      {
        return (
            <div>
              <Menu
                defaultSelectedKeys={[llaveItemMenu]}
                defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
                mode="inline"
                inlineCollapsed={open}
                style={{'background-color': '#122860', 'color': 'white'}}
              >

                <Link to="/perfilBogota">
                  <div />
                </Link>

                <Menu.Item key="999" onClick={refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

                <br></br>

                <Menu.Item key="0" onClick={refreshNumber} icon={<BookOutlined />}>
                { retornarNombreMenu( options, 15 )}
                <Link to="/introduccion">
                </Link>
                </Menu.Item>                

                <Menu.Item key="1" onClick={refreshNumber} icon={<PieChartOutlined />}>
                { retornarNombreMenu( options, 1 )}
                <Link to="/perfilBogota">
                </Link>
                </Menu.Item>

                <Menu.Item key="2" onClick={refreshNumber} icon={<BarChartOutlined />}>
                  { retornarNombreMenu( options, 2 )}
                  <Link to="/perfilComercios">
                  </Link>
                </Menu.Item>

                <SubMenu key="sub1" onTitleClick={refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3 )}>
                  <SubMenu key="sub2" onTitleClick={refreshSubMenu2} icon={<PartitionOutlined />} title={ retornarNombreMenu( options, 4 )}>
                    <Menu.Item key="3" onClick={refreshNumber} icon={<LineChartOutlined />}>
                      { retornarNombreMenu( options, 5 )}
                      <Link to="/recuperacionEconomica">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" onClick={refreshNumber} icon={<DotChartOutlined />}>
                      { retornarNombreMenu( options, 6 )}
                      <Link to="/variacionSemanal">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={refreshNumber} icon={<HddOutlined />}>
                      { retornarNombreMenu( options, 7 )}
                      <Link to="/variacionAgrupada">
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="6" onClick={refreshNumber} icon={<FundProjectionScreenOutlined />}>
                    { retornarNombreMenu( options, 8 )}
                    <Link to="/cambiosPorcentualesCovid19">
                    </Link>
                  </Menu.Item>

                </SubMenu>
                <Menu.Item key="7" onClick={refreshNumber} icon={<ShopOutlined />}>
                  { retornarNombreMenu( options, 9 )}
                  <Link to="/DistribucionMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="8" onClick={refreshNumber} icon={<MonitorOutlined />}>
                  { retornarNombreMenu( options, 10 )}
                  <Link to="/TendenciaMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="9" onClick={refreshNumber} icon={<TeamOutlined />}>
                  { retornarNombreMenu( options, 11 )}
                  <Link to="/ComportamientoCiudadano">
                  </Link>
                </Menu.Item>

                <Menu.Item key="10" onClick={refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu( options, 12 )}
                  <Link to="/DistribuciónTipologia">
                  </Link>
                </Menu.Item>

        

                <Menu.Item key="777" onClick={refreshNumber} icon={<ToolOutlined />}>
                  Configuración
                  <Link to="/ConfiguracionUsuario">
                  </Link>
                </Menu.Item>          

               <Menu.Item key="889" onClick={refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu( options, 16 )}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>

                <Menu.Item
                  danger="true"
                  onClick={handleLogout}
                  key="888" icon={<LogoutOutlined/>}>
                  Cerrar Sesión 
                </Menu.Item>
              </Menu>

              {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
              {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

            </div>
          )

      } else if ( idModulo==3 )
      {
        return (
            <div>
              <Menu
                defaultSelectedKeys={ [llaveItemMenu] }
                defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2] }
                mode="inline"
                inlineCollapsed={open}
                style={{'background-color': '#122860', 'color': 'white'}}
              >

                <Link to="/perfilBogota">
                  <div />
                </Link>

                <Menu.Item key="999" onClick={refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

                <br></br>

                <Menu.Item key="0" onClick={refreshNumber} icon={<BookOutlined />}>
                { retornarNombreMenu( options, 15 )}
                <Link to="/introduccion">
                </Link>
                </Menu.Item>                

                <Menu.Item key="1" onClick={refreshNumber} icon={<PieChartOutlined />}>
                { retornarNombreMenu( options, 1 )}
                <Link to="/perfilBogota">
                </Link>
                </Menu.Item>

                <Menu.Item key="2" onClick={refreshNumber} icon={<BarChartOutlined />}>
                  { retornarNombreMenu( options, 2 )}
                  <Link to="/perfilComercios">
                  </Link>
                </Menu.Item>

                <SubMenu key="sub1" onTitleClick={refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3 ) }>
                  <SubMenu key="sub2" onTitleClick={refreshSubMenu2} icon={<PartitionOutlined />} title={ retornarNombreMenu( options, 4 ) }  >
                    <Menu.Item key="3" onClick={ refreshNumber} icon={<LineChartOutlined />}>
                      { retornarNombreMenu( options, 5 )}
                      <Link to="/recuperacionEconomica">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" onClick={ refreshNumber} icon={<DotChartOutlined />}>
                      { retornarNombreMenu( options, 6 )}
                      <Link to="/variacionSemanal">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={ refreshNumber} icon={<HddOutlined />}>
                      { retornarNombreMenu( options, 7 )}
                      <Link to="/variacionAgrupada">
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                    { retornarNombreMenu( options, 8 )}
                    <Link to="/cambiosPorcentualesCovid19">
                    </Link>
                  </Menu.Item>

                </SubMenu>
                <Menu.Item key="7" onClick={ refreshNumber} icon={<ShopOutlined />}>
                  { retornarNombreMenu( options, 9 )}
                  <Link to="/DistribucionMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="8" onClick={ refreshNumber} icon={<MonitorOutlined />}>
                  { retornarNombreMenu( options, 10 )}
                  <Link to="/TendenciaMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="9" onClick={ refreshNumber} icon={<TeamOutlined />}>
                  { retornarNombreMenu( options, 11 )}
                  <Link to="/ComportamientoCiudadano">
                  </Link>
                </Menu.Item>

                <Menu.Item key="10" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu( options, 12 )}
                  <Link to="/DistribuciónTipologia">
                  </Link>
                </Menu.Item>

                <Menu.Item key="11" onClick={ refreshNumber} icon={<NodeIndexOutlined />}>
                  { retornarNombreMenu( options, 13 )}
                  <Link to="/OportunidadesEstrategicas">
                  </Link>
                </Menu.Item>          

                <Menu.Item key="12" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu(  options, 14 )}
                  <Link to="/ComportamientoConsumidor">
                  </Link>
                </Menu.Item>

                <Menu.Item key="777" onClick={ refreshNumber} icon={<ToolOutlined />}>
                  Configuración
                  <Link to="/ConfiguracionUsuario">
                  </Link>
                </Menu.Item>          

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu( options, 16 )}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>

                <Menu.Item
                  danger="true"
                  onClick={ handleLogout}
                  key="888" icon={<LogoutOutlined/>}>
                  Cerrar Sesión 
                </Menu.Item>
              </Menu>

              {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
              {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

            </div>
          )

      }
      
      
    } else if ( nombreRol == "analista" )
    {
      if (idModulo == 1)
      {
        return (
          <div>
            <Menu
              defaultSelectedKeys={[llaveItemMenu]}
              defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
              mode="inline"
              inlineCollapsed={open}
              style={{'background-color': '#122860', 'color': 'white'}}
            >

              <Link to="/perfilBogota">
                <div />
              </Link>

              <Menu.Item key="999" onClick={ refreshNumber} icon={<IdcardOutlined/>}>
                {props.usuarioFirebase}
              </Menu.Item>

              <br></br>

         
              <Menu.Item key="0" onClick={ refreshNumber} icon={<BookOutlined />}>
              { retornarNombreMenu(options, 15)}
              <Link to="/introduccion">
              </Link>
              </Menu.Item>

              <Menu.Item key="1" onClick={ refreshNumber} icon={<PieChartOutlined />}>
              { retornarNombreMenu( options, 1)}
              <Link to="/perfilBogota">
              </Link>
              </Menu.Item>

              <Menu.Item key="2" onClick={ refreshNumber} icon={<BarChartOutlined />}>
                { retornarNombreMenu( options, 2)}
                <Link to="/perfilComercios">
                </Link>
              </Menu.Item>

              <SubMenu key="sub1" onTitleClick={ refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu(options, 3)}>
                <SubMenu key="sub2" onTitleClick={ refreshSubMenu2} icon={<PartitionOutlined />} title={ retornarNombreMenu(options, 4)}>
                  <Menu.Item key="3" onClick={ refreshNumber} icon={<LineChartOutlined />}>
                    { retornarNombreMenu(options, 5)}
                    <Link to="/recuperacionEconomica">
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4" onClick={ refreshNumber} icon={<DotChartOutlined />}>
                    { retornarNombreMenu(options, 6)}
                    <Link to="/variacionSemanal">
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="5" onClick={ refreshNumber} icon={<HddOutlined />}>
                    { retornarNombreMenu(options, 7)}
                    <Link to="/variacionAgrupada">
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                  { retornarNombreMenu(options,8)}
                  <Link to="/cambiosPorcentualesCovid19">
                  </Link>
                </Menu.Item>

              </SubMenu>      

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu(options, 16)}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>              

              <Menu.Item
              danger="true"
              onClick={ handleLogout}
              key="888" icon={<LogoutOutlined/>}>
                Cerrar Sesión 
              </Menu.Item>
            </Menu>

            {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
            {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

          </div>
        )

      }else if (idModulo == 2)
      {
        return (
            <div>
              <Menu
                defaultSelectedKeys={[llaveItemMenu]}
                defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
                mode="inline"
                inlineCollapsed={open}
                style={{'background-color': '#122860', 'color': 'white'}}
              >

                <Link to="/perfilBogota">
                  <div/>
                </Link>

                <Menu.Item key="999" onClick={ refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

                <br></br>

                <Menu.Item key="0" onClick={ refreshNumber} icon={<BookOutlined />}>
                { retornarNombreMenu( options, 15 )}
                <Link to="/introduccion">
                </Link>
                </Menu.Item>

                <Menu.Item key="1" onClick={ refreshNumber} icon={<PieChartOutlined />}>
                { retornarNombreMenu( options, 1 )}
                <Link to="/perfilBogota">
                </Link>
                </Menu.Item>

                <Menu.Item key="2" onClick={ refreshNumber} icon={<BarChartOutlined />}>
                  { retornarNombreMenu(options,2)}
                  <Link to="/perfilComercios">
                  </Link>
                </Menu.Item>

                <SubMenu key="sub1" onTitleClick={ refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu(options,3)}>
                  <SubMenu key="sub2" onTitleClick={ refreshSubMenu2} icon={<PartitionOutlined />} title={ retornarNombreMenu(options,4)}>
                    <Menu.Item key="3" onClick={ refreshNumber} icon={<LineChartOutlined />}>
                      { retornarNombreMenu(options,5)}
                      <Link to="/recuperacionEconomica">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" onClick={ refreshNumber} icon={<DotChartOutlined />}>
                      { retornarNombreMenu(options,6)}
                      <Link to="/variacionSemanal">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={ refreshNumber} icon={<HddOutlined />}>
                      { retornarNombreMenu(options,7)}
                      <Link to="/variacionAgrupada">
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                    { retornarNombreMenu(options,8)}
                    <Link to="/cambiosPorcentualesCovid19">
                    </Link>
                  </Menu.Item>

                </SubMenu>
                <Menu.Item key="7" onClick={ refreshNumber} icon={<ShopOutlined />}>
                  { retornarNombreMenu(options,9)}
                  <Link to="/DistribucionMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="8" onClick={ refreshNumber} icon={<MonitorOutlined />}>
                  { retornarNombreMenu(options,10)}
                  <Link to="/TendenciaMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="9" onClick={ refreshNumber} icon={<TeamOutlined />}>
                  { retornarNombreMenu(options,11)}
                  <Link to="/ComportamientoCiudadano">
                  </Link>
                </Menu.Item>

                <Menu.Item key="10" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu(options,12)}
                  <Link to="/DistribuciónTipologia">
                  </Link>
                </Menu.Item>        

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu(options,16)}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>                  

                <Menu.Item
                danger="true"
                onClick={ handleLogout}
                key="888" icon={<LogoutOutlined/>}>
                  Cerrar Sesión
                </Menu.Item>
              </Menu>

              {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
              {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

            </div>
          )

      }else if (idModulo == 3)
      {
        return (
            <div>
              <Menu
                defaultSelectedKeys={[llaveItemMenu]}
                defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
                mode="inline"
                inlineCollapsed={open}
                style={{'background-color': '#122860', 'color': 'white'}}
              >

                <Link to="/perfilBogota">
                  <div/>
                </Link>

                <Menu.Item key="999" onClick={ refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

                <br></br>



                <Menu.Item key="0" onClick={ refreshNumber} icon={<BookOutlined />}>
                { retornarNombreMenu(options,15)}
                <Link to="/introduccion">
                </Link>
                </Menu.Item>

                <Menu.Item key="1" onClick={ refreshNumber} icon={<PieChartOutlined />}>
                { retornarNombreMenu(options,1)}
                <Link to="/perfilBogota">
                </Link>
                </Menu.Item>

                <Menu.Item key="2" onClick={ refreshNumber} icon={<BarChartOutlined />}>
                  { retornarNombreMenu(options,2)}
                  <Link to="/perfilComercios">
                  </Link>
                </Menu.Item>

                <SubMenu key="sub1" onTitleClick={ refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3 )}>
                  <SubMenu key="sub2" onTitleClick={ refreshSubMenu2} icon={<PartitionOutlined />} title={ retornarNombreMenu( options, 4 )}>
                    <Menu.Item key="3" onClick={ refreshNumber} icon={<LineChartOutlined />}>
                      { retornarNombreMenu(options,5)}
                      <Link to="/recuperacionEconomica">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" onClick={ refreshNumber} icon={<DotChartOutlined />}>
                      { retornarNombreMenu(options,6)}
                      <Link to="/variacionSemanal">
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={ refreshNumber} icon={<HddOutlined />}>
                      { retornarNombreMenu(options,7)}
                      <Link to="/variacionAgrupada">
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                    { retornarNombreMenu(options,8)}
                    <Link to="/cambiosPorcentualesCovid19">
                    </Link>
                  </Menu.Item>

                </SubMenu>
                <Menu.Item key="7" onClick={ refreshNumber} icon={<ShopOutlined />}>
                  { retornarNombreMenu(options,9)}
                  <Link to="/DistribucionMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="8" onClick={ refreshNumber} icon={<MonitorOutlined />}>
                  { retornarNombreMenu(options,10)}
                  <Link to="/TendenciaMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="9" onClick={ refreshNumber} icon={<TeamOutlined />}>
                  { retornarNombreMenu(options,11)}
                  <Link to="/ComportamientoCiudadano">
                  </Link>
                </Menu.Item>

                <Menu.Item key="10" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu(options,12)}
                  <Link to="/DistribuciónTipologia">
                  </Link>
                </Menu.Item>

                <Menu.Item key="11" onClick={ refreshNumber} icon={<NodeIndexOutlined />}>
                  { retornarNombreMenu(options,13)}
                  <Link to="/OportunidadesEstrategicas">
                  </Link>
                </Menu.Item>          

                <Menu.Item key="12" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu(options,14)}
                  <Link to="/ComportamientoConsumidor">
                  </Link>
                </Menu.Item>      

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu(options,16)}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>                  

                <Menu.Item
                danger="true"
                onClick={ handleLogout}
                key="888" icon={<LogoutOutlined/>}>
                  Cerrar Sesión 
                </Menu.Item>
              </Menu>

              {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
              {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

            </div>
          )

      }

    } else if ( nombreRol == "visualizador" )
    {
      if (idModulo == 1)
      {
        return (
          <div>
            <Menu
              defaultSelectedKeys={[llaveItemMenu]}
              defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
              mode="inline"
              inlineCollapsed={open}
              style={{'background-color': '#122860', 'color': 'white'}}
            >

              <Link to="/perfilBogota">
                <div/>
              </Link>

                <Menu.Item key="999" onClick={ refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

              <br></br>


              <Menu.Item key="0" onClick={ refreshNumber} icon={<BookOutlined />}>
              { retornarNombreMenu(options,15)}
              <Link to="/introduccion">
              </Link>
              </Menu.Item>

              <Menu.Item key="1" onClick={ refreshNumber} icon={<PieChartOutlined />}>
              { retornarNombreMenu(options,1)}
              <Link to="/perfilBogota">
              </Link>
              </Menu.Item>

              <Menu.Item key="2" onClick={ refreshNumber} icon={<BarChartOutlined />}>
                { retornarNombreMenu(options,2)}
                <Link to="/perfilComercios">
                </Link>
              </Menu.Item>

              <SubMenu key="sub1" onTitleClick={ refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3 )}>

                <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                  { retornarNombreMenu(options,8)}
                  <Link to="/cambiosPorcentualesCovid19">
                  </Link>
                </Menu.Item>

              </SubMenu>

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu(options,16)}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>                

              <Menu.Item
              danger="true"
              onClick={ handleLogout}
              key="888" icon={<LogoutOutlined/>}>
                Cerrar Sesión 
              </Menu.Item>
            </Menu>

            {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
            {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

          </div>
        )

      }else if (idModulo == 2)
      {
        return (
            <div>
              <Menu
                defaultSelectedKeys={[llaveItemMenu]}
                defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
                mode="inline"
                inlineCollapsed={open}
                style={{'background-color': '#122860', 'color': 'white'}}
              >

                <Link to="/perfilBogota">
                  <div/>
                </Link>

                <Menu.Item key="999" onClick={ refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

                <br></br>

                <Menu.Item key="0" onClick={ refreshNumber} icon={<BookOutlined />}>
                { retornarNombreMenu(options,15)}
                <Link to="/introduccion">
                </Link>
                </Menu.Item>

                <Menu.Item key="1" onClick={ refreshNumber} icon={<PieChartOutlined />}>
                { retornarNombreMenu(options,1)}
                <Link to="/perfilBogota">
                </Link>
                </Menu.Item>

                <Menu.Item key="2" onClick={ refreshNumber} icon={<BarChartOutlined />}>
                  { retornarNombreMenu(options,2)}
                  <Link to="/perfilComercios">
                  </Link>
                </Menu.Item>

                <SubMenu key="sub1" onTitleClick={ refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3)}>

                  <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                    { retornarNombreMenu(options,8)}
                    <Link to="/cambiosPorcentualesCovid19">
                    </Link>
                  </Menu.Item>

                </SubMenu>
                <Menu.Item key="7" onClick={ refreshNumber} icon={<ShopOutlined />}>
                  { retornarNombreMenu(options,9)}
                  <Link to="/DistribucionMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="10" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu(options,12)}
                  <Link to="/DistribuciónTipologia">
                  </Link>
                </Menu.Item>      

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu(options,16)}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>                  

                <Menu.Item
                danger="true"
                onClick={ handleLogout}
                key="888" icon={<LogoutOutlined/>}>
                  Cerrar Sesión
                </Menu.Item>
              </Menu>

              {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
              {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

            </div>
          )

      }else if (idModulo == 3)
      {

        return (
            <div>
              <Menu
                defaultSelectedKeys={[llaveItemMenu]}
                defaultOpenKeys={ !open && [llaveSubMenu1,llaveSubMenu2]}
                mode="inline"
                inlineCollapsed={open}
                style={{'background-color': '#122860', 'color': 'white'}}
              >

                <Link to="/perfilBogota">
                  <div/>
                </Link>

                <Menu.Item key="999" onClick={ refreshNumber} icon={<IdcardOutlined/>}>
                  {props.usuarioFirebase}
                </Menu.Item>

                <br></br>


                <Menu.Item key="0" onClick={ refreshNumber} icon={<BookOutlined />}>
                { retornarNombreMenu( options, 15 ) }
                <Link to="/introduccion">
                </Link>
                </Menu.Item>

                <Menu.Item key="1" onClick={ refreshNumber} icon={<PieChartOutlined />}>
                { retornarNombreMenu( options,1 )}
                <Link to="/perfilBogota">
                </Link>
                </Menu.Item>

                <Menu.Item key="2" onClick={ refreshNumber} icon={<BarChartOutlined />}>
                  { retornarNombreMenu( options,2 )}
                  <Link to="/perfilComercios">
                  </Link>
                </Menu.Item>

                <SubMenu key="sub1" onTitleClick={ refreshSubMenu1} icon={<SlidersOutlined />} title={ retornarNombreMenu( options, 3)}>

                  <Menu.Item key="6" onClick={ refreshNumber} icon={<FundProjectionScreenOutlined />}>
                    { retornarNombreMenu( options,8 )}
                    <Link to="/cambiosPorcentualesCovid19">
                    </Link>
                  </Menu.Item>

                </SubMenu>
                <Menu.Item key="7" onClick={ refreshNumber} icon={<ShopOutlined />}>
                  { retornarNombreMenu( options,9 )}
                  <Link to="/DistribucionMercado">
                  </Link>
                </Menu.Item>

                <Menu.Item key="10" onClick={ refreshNumber} icon={<DeploymentUnitOutlined />}>
                  { retornarNombreMenu( options,12 )}
                  <Link to="/DistribuciónTipologia">
                  </Link>
                </Menu.Item>

                <Menu.Item key="11" onClick={ refreshNumber} icon={<NodeIndexOutlined />}>
                  { retornarNombreMenu( options,13 )}
                  <Link to="/OportunidadesEstrategicas">
                  </Link>
                </Menu.Item>          

               <Menu.Item key="889" onClick={ refreshNumber} icon={<FileSearchOutlined />}>
                  { retornarNombreMenu( options,16 )}
                  <Link to="/glosario">
                  </Link>
                </Menu.Item>                  

                <Menu.Item
                danger="true"
                onClick={ handleLogout}
                key="888" icon={<LogoutOutlined/>}>
                  Cerrar Sesión 
                </Menu.Item>
              </Menu>

              {isLoggingOut && <p>Cerrando sesión, por favor espere....</p>}
              {logoutError && <p>Error al cerrar sesión, por favor, vuelva a intentarlo</p>}

            </div>
          )

      }

    } else { return (null) }
  }   


 /*return (
			<Ul open={props.open}>
			{
				props.options.map( optionElement => (optionElement.idOpcion != 3) ? <li key={optionElement.idOpcion} onClick={refreshNumber}> {optionElement.nombreOpcion} </li> : <ul> > <li></li></ul> )
			}
				
			</Ul>
	) */


/// mapToStatePorps logginOut
/// export default


export default SidePan
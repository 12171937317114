import React, { Component } from "react";

import BarChart from "../charts/b_09_BarChart-ComportamientoCiudadano";
import PieChart from "../charts/b_09_PieChart-ComportamientoCiudadano";
import TableChart1 from "../charts/b_09_TableChart-ComportamientoCiudadano";
import TableChart2 from "../charts/b_09_TableChart1-ComportamientoCiudadano";

import '../../resources/styles/MainStyles.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
//import '../styles/BoardStyleComportamientoCiudadano.css';

import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import { arrayToString } from '../../resources/helpers/stringFormatter';
import { dateParams } from '../../resources/helpers/globalDates'

import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";

const urlDev = require("../../properties/properties");

//Clase que permite la ejecución del componente.
export default class ComportamientoCiudadano extends Component {

  constructor( props ){
    super( props );
    this.state = { 
    keyTablero: 'ComportamientoCiudadano', 
    apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    metrica: 'Ventas',
    usuarioIniciado: '',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    };
  }

  asignarCategorias = ()=>{
    var content = "";
    // eslint-disable-next-line
    if(this.state.categoria == []){
      content = arrayToString(arrayCategoriasDos);
    } else {
      content = this.state.categoria;
    }
    return content;
  }

  updateQuery = (nuevoEstado) => {
    // se hace use del operador spread ..., para evitar mutabilidad
    this.setState( prevstate => (
      { ...prevstate, 
      localidad : nuevoEstado.localidad,
      upz: nuevoEstado.upz,
      barrio: nuevoEstado.barrio,
      estrato: nuevoEstado.estrato,
      categoria: nuevoEstado.categoriasNoBasico,
      metrica : nuevoEstado.metrica,
      apiResponse: nuevoEstado.contadorDeCambios,
    }))
  }

    updateDates = (nuevasFechas) => {
      // se hace use del operador spread, para evitar mutabilidad
      this.setState( prevState => ( 
        {
          ...prevState ,  
          filtro_fecha_inicio : nuevasFechas.startDate,
            filtro_fecha_fin: nuevasFechas.endDate, 
            apiResponse: nuevasFechas.apiResponse
          } 
        ) );
    }

updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado} )
    }
  }


   shouldComponentUpdate(nextProps,nextState){
     if(this.state.apiResponse === nextState.apiResponse){
      if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true
        }
        return false
     }else {
        return true
     }
  }

  //Método para el renderizado del componente.
  render() {

    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (

          <body >
    
      <div className="encabezado" >

        <div style={{'display':'contents'}}>

          <div className="logo1" >
            <img className="LogoImage" src={imgn} alt="imgn" /> 
            <img className="LogoImage2" src={imgn2} alt="imgn" /> 
          </div>
          <div className="titulo1" >
            Comportamiento del ciudadano por día, por hora, por categoría.
          </div>
          <div className="calendario1" style={{'z-index':'80'}}>
            <DatePickerComponent 
              tablero = {this.state.keyTablero}
              datesChanged = {this.updateDates}
            />
          </div>
        </div>
      </div>

      <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <div className="opciones" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>
            Comprenda el comportamiento
        de las visitas del ciudadano a los comercios, entendiendo los días y horas de mayor afluencia,
        así como el tiempo y la frecuencia de las visitas </h5> 
            </div>
          
              <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
                    onFilterChange={this.updateQuery}
                />            
          </div>
        </div>
      </div>


      <div className="paneltableros">


           <div className="Purpuere">
            <div className = "graphs-pag3">
                <h5> Días de Mayor Afluencia de Visitantes. <br/></h5>
                  <PieChart
                    filtro_localidad={this.state.localidad}
                    filtro_upz={this.state.upz}
                    filtro_barrio={this.state.barrio}
                    filtro_estrato= {this.state.estrato}
                    filtro_categoria= {this.asignarCategorias()}
                    filtro_metrica={this.state.metrica}
                    url={urlDev.urlApiDev+"ConsultaComportamientoCiudadano/AnilloUno"}
                    startDate={this.state.filtro_fecha_inicio}
                    endDate= {this.state.filtro_fecha_fin}
                  />
                </div>
                </div>

                <div>

                <h5> Horas de Mayor Afluencia de Visitantes. </h5>
                
                  <BarChart
                    filtro_localidad={this.state.localidad}
                    filtro_upz={this.state.upz}
                    filtro_barrio={this.state.barrio}
                    filtro_estrato= {this.state.estrato}
                    filtro_categoria= {this.asignarCategorias()}
                    filtro_metrica={this.state.metrica}
                    url={urlDev.urlApiDev+"ConsultaComportamientoCiudadano/BarrasVerticalUno"}
                    startDate={this.state.filtro_fecha_inicio}
                    endDate= {this.state.filtro_fecha_fin}
                  />
            </div>

            <div className = "map-Pag3">

                <h5> Tiempo Promedio de Permanencia.</h5>
                  <TableChart1
                    filtro_localidad={this.state.localidad}
                    filtro_upz={this.state.upz}
                    filtro_barrio={this.state.barrio}
                    filtro_estrato= {this.state.estrato}
                    filtro_categoria= {this.asignarCategorias()}
                    filtro_metrica={this.state.metrica}
                    url={urlDev.urlApiDev+"ConsultaComportamientoCiudadano/TablaUno"}
                    startDate={this.state.filtro_fecha_inicio}
                    endDate= {this.state.filtro_fecha_fin}
                  />
                </div>

                <div>

                <h5> Frecuencia de Visitantes.</h5>
                 <TableChart2
                    filtro_localidad={this.state.localidad}
                    filtro_upz={this.state.upz}
                    filtro_barrio={this.state.barrio}
                    filtro_estrato= {this.state.estrato}
                    filtro_categoria= {this.asignarCategorias()}
                    filtro_metrica={this.state.metrica}
                    url={urlDev.urlApiDev+"ConsultaComportamientoCiudadano/TablaDos"}
                    startDate={this.state.filtro_fecha_inicio}
                    endDate= {this.state.filtro_fecha_fin}
                  />
                
           </div>
          </div>
        <div className="footer2">Footer
      </div>
    </body>
    );
  }
}

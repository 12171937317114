import React, { Component } from "react";
import LineChartPag2 from "../charts/b_03_LineChart";
import NegativeLineChartPag2 from "../charts/b_03_NegativeLineChart";
import '../../resources/styles/MainStyles.css';
import SideMenu from "../menu/ResponsiveMenu";
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
// this is a generic style not exclusive for board 07 but can be reutilized
import '../../resources/styles/bs_07_DistribucionMercado.css';
import TemplateComponent from "../maps/TemplateComponent";
import { titleaño } from '../../resources/helpers/DinamicTitle';
import { dateParams } from '../../resources/helpers/globalDates';
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";


const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<768) width= width / 1.2
else if ( width < 1024 ) width = width *0.86
else if ( width >= 1024 ) width = width *0.91

//Clase que permite la ejecución del componente.
export default class RecuperacionEconcomicaComponent extends Component {

  constructor( props ){
    super( props );
    this.state = { keyTablero: '03RecuperacionEconcomica', 
    apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    usuarioIniciado: '',
    metrica: 'Ventas',
    metrica_lag: 'Suma_ventas_Lag',
    metrica_cp:'cpventas',
    año_pas:'Año anterior',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    alto_mapa: 600,
    ancho_mapa: width
    };
  }

  updateQuery = (nuevoEstado) => {
      this.setState( {
        localidad : nuevoEstado.localidad,
        upz: nuevoEstado.upz,
        barrio: nuevoEstado.barrio,
        estrato: nuevoEstado.estrato,
        categoria: nuevoEstado.categoriasBasico,
        metrica : nuevoEstado.metrica,
        metrica_lag : nuevoEstado.metrica_lag,
        metrica_cp : nuevoEstado.metrica_cp,
        año_pas:nuevoEstado.año_pas,
        apiResponse: nuevoEstado.contadorDeCambios,
      }
    )
  }

    updateDates = (nuevasFechas) => {
      // se hace use del operador spread, para evitar mutabilidad
      this.setState( prevState => ( 
        {
          ...prevState ,  
          filtro_fecha_inicio : nuevasFechas.startDate,
            filtro_fecha_fin: nuevasFechas.endDate, 
            apiResponse: nuevasFechas.apiResponse
          } 
        ) );
    }

  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

   shouldComponentUpdate(nextProps,nextState){
     if(this.state.apiResponse === nextState.apiResponse){
      if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
        }
        return false
     }else {
        return true
     }
  }

  //Método para el renderizado del componente.
  render() {

    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (

      <body >
    
      <div className="encabezado" >

        <div style={{'display':'contents'}}>

          <div className="logo1" >
            <img className="LogoImage" src={imgn} alt="imgn" /> 
            <img className="LogoImage2" src={imgn2} alt="imgn" /> 
          </div>
          <div className="titulo1" >
            Variación general
          </div>
          <div className="calendario1" style={{'z-index':'80'}}>
            <DatePickerComponent 
              tablero = {this.state.keyTablero}
              datesChanged = {this.updateDates}
            />
          </div>
        </div>
      </div>

      <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <div className="opciones" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>Evalúe como han cambiado las ventas y transacciones de los negocios a lo largo del tiempo,
          así como también el movimiento de las personas en la ciudad.</h5>
            </div>
          
              <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
                    onFilterChange={this.updateQuery}
                />            
          </div>
        </div>
      </div>


      <div className="paneltableros">

          
          <div className="graph1">
                <h5> {titleaño(this.state.año_pas)} </h5>
                 <NegativeLineChartPag2
                  filtro_localidad={this.state.localidad}
                  filtro_upz = {this.state.upz}
                  filtro_barrio = {this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  filtro_metrica_lag={this.state.metrica_lag}
                  filtro_metrica_cp={this.state.metrica_cp}
                  filtro_año_pas={this.state.año_pas}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  url={urlDev.urlApiDev+"ConsultaRecuperacionEconomica/LineasDos"}
                />
              </div>

              <div className="graph2">
              <h5> Cambio Porcentual respecto a la Semana Anterior </h5>
              
                <LineChartPag2
                  filtro_localidad={this.state.localidad}
                  filtro_upz = {this.state.upz}
                  filtro_barrio = {this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  filtro_metrica_lag={this.state.metrica_lag}
                  filtro_metrica_cp={this.state.metrica_cp}
                  url={urlDev.urlApiDev+"ConsultaRecuperacionEconomica/LineasUno"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
          </div>

          <div className="mapa1r2c">

              <div className="title-component"><h5>Mapa de Bogotá</h5></div>
               <TemplateComponent
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  id_mapa_tablero = {this.state.keyTablero}
                  url = {urlDev.urlApiDev+"ConsultaRecuperacionEconomica/Mapa"}
                  alto_mapa = {this.state.alto_mapa}
                  ancho_mapa = {this.state.ancho_mapa}
                />

          </div>
        </div>
      
      <div className="footer2">Footer
      </div>
    </body>
    );
  }
}

import React,{ PureComponent}  from 'react';
import {
  ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Cell, Legend, ResponsiveContainer
} from 'recharts';

const LOCALIDADES = ['Usaquén', 'Chapinero', 'Santa Fe', 'San Cristóbal', 'Usme' , 'Tunjuelito', 'Bosa', 'Kennedy', 'Fontibón', 'Engativá', 'Suba',
'Barrios Unidos', 'Teusaquillo',  'Los Mártires',  'Antonio Nariño',  'Puente Aranda', 'Candelaria', 'Rafael Uribe Uribe', 'Ciudad Bolívar',
];

const COLORS = [ '#1a1a8a', '#28a870',  '#00aaff', '#00ffff', '#aaff00', '#cde81c', '#ffff00',  'orange',   '#ff4848', '#ff00ff',  '#0000ff',
 '#d49942',  '#634e42', 'brown', '#e3366d',   '#8c11ff',  'purple', '#878c8c',
'#ff0000', '#000000'];

let jsonResponse = []; //variable auxiliar para recordar estados al actualizar consultas optimizando consumo de API

export function parseTwoDecimals(x) {
  return Intl.NumberFormat('es-CO').format(Number.parseFloat(x).toFixed(2))
}

const CustomTooltip = ({ active, payload, label }) => {

  if (active) {
    return (
      <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
        <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].NOMBRE_BAR} `}<br/></text>
        <text className="label" style={{'font-size':'10.1px'}}> {`${payload[0]["payload"].NOMBRE_LOC} `}<br/></text>
        <text className="label" style={{'font-size':'11.1px'}}>{`${payload[0].name} :
        ${Number.parseFloat(payload[0].value).toFixed(2).replace(".",",")}
        ${payload[0].unit}`}<br/></text>
        <text className="label" style={{'font-size':'11.1px'}}>{`${payload[1].name} :
        ${Number.parseFloat(payload[1].value).toFixed(2).replace(".",",")}
        ${payload[1].unit}`}<br/></text>
      </div>
    );
  }
  return null;
};
//`

export default class MultipleScatterChartComponent extends PureComponent {

  constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz : props.filtro_upz,
      filtro_barrio : props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metricaY : props.filtro_metricaY,
      filtro_metricaX : props.filtro_metricaX,
      variacion : props.variacion,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate
      };
  }

  //initial callAPi
  readQuery() {
    fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
      .then ( res => jsonResponse = this.state.apiResponse )  ;
  }

  componentDidMount(){
      this.readQuery();
  }

  // Metodo lee los estados de filtros y actualiza
  async componentWillReceiveProps(nextProps) {
    let params_string = "";
    //LOGICA:
    // para cada parametro de filtro
    // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
    if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
      if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
    }
    else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

    if ( nextProps.filtro_upz !== this.state.filtro_upz ){
      if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
    }
    else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

    if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
      if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
    }
    else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

    if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
      if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
    }
    else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

    if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
      if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
    }
    else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

    if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate ))
      params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
    else if (this.state.startDate !== null && this.state.endDate !== null)
      params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");


    if (params_string.length >= 2 ) params_string = "?" + params_string.substring(2);

    if (nextProps.filtro_metricaY !== this.state.filtro_metricaY || nextProps.filtro_metricaX !== this.state.filtro_metricaX){
      // Si solo cambiaron metricas, no es necesario volver a consultar, solo actualizar grafico
      //continue
    }else {
       try {
      //call API
      /// manda valores de filtros al servicio y obtiene respuesta para renderizar
      const response = await fetch(nextProps.url + params_string);
      if (!response.ok){
        throw Error(response.statusText);
      }
      jsonResponse = await response.text();
      jsonResponse = JSON.parse(jsonResponse);
    }catch (error) {
      console.log(error);
    }
    }

    this.setState( { apiResponse: jsonResponse, filtro_localidad: nextProps.filtro_localidad, variacion: nextProps.variacion,
      filtro_metricaY: nextProps.filtro_metricaY,
      filtro_metricaX: nextProps.filtro_metricaX,
      startDate: nextProps.startDate, endDate: nextProps.endDate,
      url: nextProps.url } );
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metricaY !== nextState.filtro_metricaY || this.state.filtro_metricaX !== nextState.filtro_metricaX ){
        return true
      }
      return false
    } else {
      return true
    }
  }

  render() {
    // eslint-disable-next-line
    var datatada = [];
    var metricaY = this.state.filtro_metricaY;
    var metricaX = this.state.filtro_metricaX;
    var nameY = this.state.filtro_metricaY;
    var nameX = this.state.filtro_metricaX;

    if (metricaX === "Ticket Prom") {
      metricaX = "Tickets";
      nameX = "Ticket Prom";
    } if (metricaX === "Búsquedas") {
      metricaX = "Busquedas"
      nameX = "Búsquedas";
    } if (metricaY === "Ticket Prom") {
      metricaY = "Tickets";
      nameY = "Ticket Prom";
    } if (metricaY === "Búsquedas") {
      metricaY = "Busquedas"
      nameY = "Búsquedas";
    }
    return (
    <ResponsiveContainer width="100%" height={400} padding={15}>

      <ScatterChart
        margin={{
          top: 11, right: 20, bottom: 30, left: 11,
        }}
      >
        <CartesianGrid />
        <XAxis type="number" dataKey={metricaX} name={nameX} unit="%" stroke="#281859"
        label={{ value: metricaX, position:"insideBottom", offset: -10, fontSize: 13}}  tick={{fontSize: 12}}
         domain={[-100, 100]} allowDataOverflow={true} />
        <YAxis yAxisId="left" type="number" dataKey={metricaY} name={nameY} unit="%" stroke="#281859"
        label={{ value: metricaY, position:"insideLeft",  angle: 270, offset: 10, fontSize: 13}}  tick={{fontSize: 12}}
        domain={[-100, 100]} allowDataOverflow={true} />
        <ZAxis range={[29,29]} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }}  content = { <CustomTooltip /> }/>
        <Scatter yAxisId="left" data={this.state.apiResponse} shape="circle" >
        {
          // eslint-disable-next-line
           datatada = this.state.apiResponse.map( (entry, index) => <Cell key={`cell-${index}`}  fill={COLORS[entry.ID_LOCALID-1 % COLORS.length]} />)
        }
        </Scatter>

        <Legend
        height={100}
        align="right" layout="vertical" verticalAlign="top"
        wrapperStyle={{ marginTop : "-1px", marginLeft : "0px", marginRight: "-21px", fontSize :"10.7px" }}
          payload={
                    LOCALIDADES.map(
                      (entry, index) => ({
                        id: {index},
                        color: COLORS[index] ,
                        type: "square",
                        value: `${entry}` ,
                      })
                    )
                  }
        >
        </Legend>

      </ScatterChart>

</ResponsiveContainer >
    );
  }
}

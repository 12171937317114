import React from 'react';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload) {
      return (
        <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
          <text className="label" style={{'font-size':'13.2px'}}> {`Semana: ${payload[0].payload.Semana.value} `}<br/></text>

          <text className="label" style={{'font-size':'13px',  'color':'#43CDB5'}}>{`${payload[0].name} :
          ${Number.parseFloat(payload[0].payload.value1.toFixed(2)).toLocaleString('es-CO')}`}<br/></text>

          <text className="label" style={{'font-size':'13px', 'color':'#ff7300'}}>{`${payload[1].name} :
          ${Number.parseFloat(payload[1].payload.value2.toFixed(2)).toLocaleString('es-CO')} ${payload[1].unit}`}<br/></text>

        </div>
      );
    }
  }
  return null;
};

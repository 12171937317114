import React, { Component } from 'react';
import SideMenu from '../menu/ResponsiveMenu';
import Form from "../charts/b_configuracion_form";
import { arrayToString } from '../../resources/helpers/stringFormatter';
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import '../../resources/styles/MainStyles.css';
import '../../resources/styles/bs_configuracion.css';

//const urlDev = require("../../properties/properties");
var categoriasString = arrayToString(arrayCategoriasDos);


//Clase que permite la ejecución del componente.
export default class VariacionAgrupadaComponent extends Component {

    constructor( props ){
        super( props );
        this.state = { keyTablero: 'intro', apiResponse: 0,
        categoria: categoriasString,
        usuarioIniciado: '',};
    }
    
      updateUserCats = (usuarioIniciado) => {
        if ( usuarioIniciado!== this.state.usuarioIniciado){
          this.setState( {usuarioIniciado: usuarioIniciado })
        }
      }
    
      updateQuery = ( ) => {}
    
    render(){
        return(
        <body>
            <div className="encabezado" >
                <div style={{'display':'contents'}}>
                    <div className="titulo1" >
                    Registro de usuarios NO administradores.
                    </div>
                </div>
            </div>

            <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

            <div className="paneltableros_intro">
                <div className="Contenedor_configuracion">
                    <h3>Formulario de registro de usuarios.</h3>
                    <Form/> 
                </div>
            </div>
            <div className="footer2">Footer</div>
        </body>

        )
    }
}
import React, { Component } from "react";
import TableChart from "../charts/b_02_table";
import BarChart from "../charts/b_02_barchart";
import '../../resources/styles/MainStyles.css';
import '../../resources/styles/bs_02_Perfilcomercios.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import { title } from '../../resources/helpers/DinamicTitle'
import { dateParams } from '../../resources/helpers/globalDates'
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";



const urlDev = require("../../properties/properties");
var width = window.innerWidth /5;
var height = window.innerHeight /5;    


export default class TableroPerfilBogota extends Component {
    constructor( props ){
        super( props );
        this.state = { 
            keyTablero: '02perfilComercios', 
            apiResponse: 0,
            //ModuloAvanzadoTab2
            //impactoCambiosPorcentuales
            localidad: '',
            upz: '',
            barrio: '',
            estrato: '',
            categoria: '',
            metrica: 'Ventas',
            usuarioIniciado: '',
            metrica_Belleza_Bienestar: 'ventas_Belleza_Bienestar',
            metrica_Comercio: 'ventas_Comercio',
            metrica_Compras: 'ventas_Compras',
            metrica_Entretenimiento: 'ventas_Entretenimiento',
            metrica_Restaurantes: 'ventas_Restaurantes',
            metrica_Salud: 'ventas_Salud',
            metrica_Servicios: 'ventas_Servicios',
            metrica_Turismo: 'ventas_Turismo',
            metrica_Tráfico_Transporte: 'ventas_Trafico_Transporte',
            metrica_Distrito: 'ventas_Distrito',
            filtro_fecha_inicio: dateParams.startDate,
		    filtro_fecha_fin: dateParams.endDate, 
            alto_mapa:  height,
            ancho_mapa: width
        };
    }


    componentDidMount()
    {
        /*window.addEventListener('resize', ()=>{
            headerHeight = document.querySelector('.encabezado').offsetHeight
            optionsHeight = document.querySelector('.opciones').offsetHeight
        } );*/
    }

    updateQuery = (nuevoEstado) => {
        this.setState( 
        {
            localidad : nuevoEstado.localidad,
            upz: nuevoEstado.upz,
            barrio: nuevoEstado.barrio,
            estrato: nuevoEstado.estrato,
            categoria: nuevoEstado.categoriasBasico,
            metrica : nuevoEstado.metrica,
            metrica_Belleza_Bienestar: nuevoEstado.metrica_Belleza_Bienestar,
            metrica_Comercio: nuevoEstado.metrica_Comercio,
            metrica_Compras: nuevoEstado.metrica_Compras,
            metrica_Entretenimiento: nuevoEstado.metrica_Entretenimiento,
            metrica_Restaurantes: nuevoEstado.metrica_Restaurantes,
            metrica_Salud: nuevoEstado.metrica_Salud,
            metrica_Servicios: nuevoEstado.metrica_Servicios,
            metrica_Turismo: nuevoEstado.metrica_Turismo,
            metrica_Tráfico_Transporte: nuevoEstado.metrica_Tráfico_Transporte,
            metrica_Distrito: nuevoEstado.metrica_Distrito,
            apiResponse: nuevoEstado.contadorDeCambios,
        })
    }


    updateUserCats = (usuarioIniciado) => {
        if ( usuarioIniciado!== this.state.usuarioIniciado){
            this.setState( { usuarioIniciado: usuarioIniciado })
        }
    }

    updateDates = (nuevasFechas) => {
        // se hace use del operador spread, para evitar mutabilidad
        this.setState( prevState => ( 
            {
                ...prevState ,  
                filtro_fecha_inicio : nuevasFechas.startDate,
                filtro_fecha_fin: nuevasFechas.endDate, 
                apiResponse: nuevasFechas.apiResponse
            } 
        ) );
    }

    // se evita "reconciliacion" de react
    shouldComponentUpdate ( nextProps, nextState ){
        if(this.state.apiResponse === nextState.apiResponse){
            if (this.usuarioIniciado !== nextState.usuarioIniciado){
                return true 
            }
        } 
        return true
    } 

    componentDidUpdate(){
        //alert(document.querySelector('.header2').offsetHeight)
    }

    render(){
    return(

    <body >
        <div className="encabezado" >
            <div style={{'display':'contents'}}>
                <div className="logo1" >
                        <img className="LogoImage" src={imgn} alt="imgn" /> 
                        <img className="LogoImage2" src={imgn2} alt="imgn" /> 
                    </div>
                <div className="titulo1" >
                Composición económica Por localidades.!!
                </div>
                <div className="calendario1">
                    <DatePickerComponent 
                        tablero = {this.state.keyTablero}
                        datesChanged = {this.updateDates}
                    />
                </div>
            </div>
        </div>

        <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

			<div className="opciones" >
				<div style={{'display':'contents'}}>
					<div className="subtitulo">
						<h5 align="left" style={{'color':'#4F4F4F'}}>
						Conozca cual es la composición porcentual de cada categoría de los comercios en las localidades de Bogotá.</h5>
				    </div>
			    
			        <div className="filtros1">
						<MultipleFiltersComponent 
							tablero={this.state.keyTablero}
							metrica={this.state.metrica}
				           	onFilterChange={this.updateQuery}
				        />            
					</div>
				</div>
			</div>

			<div className="b02_paneltableros">
                <div className="contenedor1_Perfil_Comercios">
                    <h5>Distribución de {title(this.state.metrica)} </h5>
                    <TableChart
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.state.categoria}
                        filtro_metrica={this.state.metrica}
                        url={urlDev.urlApiDev+"ConsultaPerfilComercios/TablaUno"}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                    />   
                </div>

				<div className="contenedor2_Perfil_Comercios" style={{'z-index':'0'}}> 
					<h5>Distribución de {title(this.state.metrica)} y categoria</h5>
                    <BarChart
                        filtro_localidad={this.state.localidad}
                        filtro_upz={this.state.upz}
                        filtro_barrio={this.state.barrio}
                        filtro_estrato= {this.state.estrato}
                        filtro_categoria= {this.state.categoria}
                        filtro_metrica={this.state.metrica}
                        filtro_metrica_Belleza_Bienestar ={this.state.metrica_Belleza_Bienestar}
                        filtro_metrica_Comercio = {this.state.metrica_Comercio}
                        filtro_metrica_Compras = {this.state.metrica_Compras}
                        filtro_metrica_Entretenimiento = {this.state.metrica_Entretenimiento}
                        filtro_metrica_Restaurantes = {this.state.metrica_Restaurantes}
                        filtro_metrica_Salud = {this.state.metrica_Salud}
                        filtro_metrica_Servicios = {this.state.metrica_Servicios}
                        filtro_metrica_Turismo = {this.state.metrica_Turismo}
                        filtro_metrica_Tráfico_Transporte = {this.state.metrica_Tráfico_Transporte}
                        filtro_metrica_Distrito = {this.state.metrica_Distrito}
                        url = {urlDev.urlApiDev+"ConsultaPerfilComercios/Barras"}
                        startDate={this.state.filtro_fecha_inicio}
                        endDate= {this.state.filtro_fecha_fin}
                    />
				</div>
			</div>

			<div className="footer2">Footer
			</div>
		</body>

		)
	}
}
	
import React, { Component } from "react";
import MixBarChart from "../charts/b_06_MixBarChartPag4";
import NegativeBarChart from "../charts/b_06_NegativeBarChart";
import TemplateComponent from "../maps/TemplateComponent";
import '../../resources/styles/MainStyles.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import '../../resources/styles/bs_06_CambiosPorcentuales.css';
import { dateParams } from '../../resources/helpers/globalDates';
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";

const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<768) width= width / 1.2
else if ( width < 1024 ) width = width *0.86
else if ( width >= 1024 ) width = width *0.91

//Clase que permite la ejecución del componente.
export default class CambiosPorcentualesCovid extends Component {

  constructor( props ){
    super( props );
    this.state = { keyTablero: '06impactoCambiosPorcentuales', 
    apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: 'Salud',
    metrica: 'Ventas',
    Grupo1 : 'Grupo1Ventas',
    Grupo2 : 'Grupo2Ventas',
    Grupo3 : 'Grupo3Ventas',
    Grupo4 : 'Grupo4Ventas',
    Grupo5 : 'Grupo5Ventas',
    usuarioIniciado: '',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    //mapaAnuales: "VariacionesSegregadasAnual",
    alto_mapa: 700,
    ancho_mapa: width

    };
  }


// if basico categorias 
// else 
  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }


  updateQuery = (nuevoEstado) => {
    // se hace use del operador spread, para evitar mutabilidad
    this.setState( prevState => ({
      ...prevState,
      localidad : nuevoEstado.localidad,
      upz: nuevoEstado.upz,
      barrio: nuevoEstado.barrio,
      estrato: nuevoEstado.estrato,
      categoria: nuevoEstado.categoriasBasico,
      metrica : nuevoEstado.metrica,
      Grupo1 : nuevoEstado.Grupo1,
      Grupo2 : nuevoEstado.Grupo2,
      Grupo3 : nuevoEstado.Grupo3,
      Grupo4 : nuevoEstado.Grupo4,
      Grupo5 : nuevoEstado.Grupo5,
      apiResponse: nuevoEstado.contadorDeCambios,
    })) 
  }

    updateDates = (nuevasFechas) => {
        // se hace use del operador spread, para evitar mutabilidad
        this.setState( prevState => ( 
            {
                ...prevState ,  
                filtro_fecha_inicio : nuevasFechas.startDate,
                filtro_fecha_fin: nuevasFechas.endDate, 
                apiResponse: nuevasFechas.apiResponse
            } 
        ) );
    }


   shouldComponentUpdate(nextProps,nextState){
     if(this.state.apiResponse === nextState.apiResponse){
        if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true
        }
        return false
     }else {
        return true
     }
  }


  componentDidUpdate(){
  }

  //Método para el renderizado del componente.
  render() {
    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (
      <body >
        <div className="encabezado" >
            <div style={{'display':'contents'}}>
                <div className="logo1" >
                        <img className="LogoImage" src={imgn} alt="imgn" /> 
                        <img className="LogoImage2" src={imgn2} alt="imgn" /> 
                    </div>
                <div className="titulo1" >
                Impacto por localidad
                </div>
                <div className="calendario1">
                    <DatePickerComponent 
                        tablero = {this.state.keyTablero}
                        datesChanged = {this.updateDates}
                    />
                </div>
            </div>
        </div>


     <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <div className="opciones" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>Observe el impacto promedio derivado
           del COVID-19 en la ciudad por categoría y barrio.
           Ubique los negocios que han sufrido un impacto desde muy alto hasta muy bajo.</h5>
            </div>
          
              <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
                    onFilterChange={this.updateQuery}
                />            
          </div>
        </div>
      </div>

      <div className="paneltableros">


          <div className = "graph1">
                <h5> Impacto porcentual por localidad. <br/></h5>
                <NegativeBarChart
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaCambiosPorcentuales/BarrasVertical"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
              </div>
            <div className="graph2"> 
              <h5> Distribución por tipo de impacto. </h5>
                <MixBarChart
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  Filtro_Grupo1={this.state.Grupo1}
                  Filtro_Grupo2={this.state.Grupo2}
                  Filtro_Grupo3={this.state.Grupo3}
                  Filtro_Grupo4={this.state.Grupo4}
                  Filtro_Grupo5={this.state.Grupo5}
                  url={urlDev.urlApiDev+"ConsultaCambiosPorcentuales/BarrasHorizontal"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
          </div>

          <div className = "mapa1r2c">
            
              <div className="title-component-Cambios-porcentuales">
                <h5>Mapa de cambios porcentuales en Bogotá.</h5>
              </div>
            
                <TemplateComponent
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  url = {urlDev.urlApiDev+"ConsultaCambiosPorcentuales/Mapa"}
                  id_mapa_tablero = {this.state.keyTablero}
                  alto_mapa = {this.state.alto_mapa}
                  ancho_mapa = {this.state.ancho_mapa}
                />
          </div>
        </div>
        <div className="footer2">Footer
      </div>
      </body>
    );
  }
}

import React, { useState } from 'react';
import SidePan from './SidePan';
import { StyledBurger } from './StylesBurguerMenu';




const TriBurger = ( props ) => {

	const [ open, setOpen ] = useState(true);
	
	return (

		<>
		<StyledBurger id="menuRES" open={ open } onClick={ () => setOpen(!open) }>
			<div /> 
			<div /> 
			<div /> 
		</StyledBurger>
		<SidePan open={open} options={props.options} nombreRol={props.nombreRol} idModulo={props.idModulo} usuarioFirebase={props.usuarioFirebase}/>
		</>
	)
}




export default TriBurger
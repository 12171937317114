import React from 'react';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload) {
      if (payload[0].dataKey === "Ventas" || payload[0].dataKey === "Tickets"){
      return (
        <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
          <text className="label" style={{'font-size':'13px',  'color':'#000000'}}>{`${payload[0].payload.CategoriaPadre} :
          ${Number.parseFloat(payload[0].value).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}`}<br/></text>
        </div>
      );
    } else {
      return (
        <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
          <text className="label" style={{'font-size':'13px',  'color':'#000000'}}>{`${payload[0].payload.CategoriaPadre} :
          ${Number.parseFloat(payload[0].value).toLocaleString('es-CO')}`}<br/></text>
        </div>
      );
    }
    }


  }
  return null;
};

import styled from 'styled-components';

export const Menu = styled.nav`
	width: 100%;
	height: 5px; 
	border-bottom: 2px solid #f1f1f1;
	padding: 0 25px;
	display: flex;
	justify-content: space-between;


	.logo{
		padding: 15px 0;
	}


`;


export const StyledBurger = styled.div`
	width: 1.9rem;
	height: 1.9rem; 
	line-height: 6.4px;
	position: fixed; 
	left: 9.8px; 
	z-index: 40;
	display: none;

	@media (min-width: 768px){
		display: flex; 
		justify-content: space-around;
		flex-flow: column nowrap; 
		top: 18px; 

	}

	@media (max-width: 768px){
		display: flex; 
		justify-content: space-around;
		flex-flow: column nowrap; 
		top:18px;
	}


	@media (max-width: 600px){
		display: flex; 
		justify-content: space-around;
		flex-flow: column nowrap; 
		top:18px;

	}

	
	div {
		width: 2rem; 
		height: 0.25rem; 
		background-color: ${ ({ open }) => !open ? '#FFF' : '#FFF'  };
		border-radius: 10px;
		transform-origin: 1px; 
		transition: all 0.3s linear;


	}


`;


export const Ul =  styled.ul`
	list-style: none; 
	display: flex; 
	left: 0;
	flex-flow: row nowrap; 
	

	li{
		padding: 20px 20px;
		color: #fff;
	}
	li.pointer {
	      cursor: pointer;
	    }
	    li:hover {
	    	background-color: #ccc;
	    }

	@media (min-width: 768px) {
		flex-flow: column nowrap;
		background-color: #0D2538;
		position: fixed; 
		transform: ${ ( { open } ) => open ? 'translateX(0)' : 'translateX(-100%)' };
		top: 155px;
		left: -15px;
		height: 100%; // viewport relative percentage
		width: 300px;
		padding-top: 3.5rem; 
		transition: transform 0.3s ease-in-out;

}

	@media (max-width: 768px) {
		flex-flow: column nowrap;
		background-color: #0D2538;
		position: fixed; 
		transform: ${ ( { open } ) => open ? 'translateX(0%)' : 'translateX(-200%)' };
		top: 210px;
		left: -15px;
		height: 100vh; // viewport relative percentaje
		width: 300px;
		padding-top: 3.5rem; 
		transition: transform 0.3s ease-in-out;

}

`;

export const Li =  styled.li` 
		li {
	       margin-bottom: 15px;
	    }
	    
`;
import React, { PureComponent } from 'react';
import {
  ComposedChart, Bar,Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { formatTableRecup } from '../../resources/helpers/numberFormatter'
import { CustomTooltip } from '../../resources/Tooltips/CustomTooltipPag3-1.js'

let jsonResponse = [];
// read var from filter on change

export default class NegativeBarChart extends PureComponent {

  constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz : props.filtro_upz,
      filtro_barrio : props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica,
      filtro_metrica_lag: props.filtro_metrica_lag,
      filtro_metrica_cp : props.filtro_metrica_cp,
      filtro_año_pas : props.filtro_año_pas,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  //callAPi
  readQuery() {
    fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
      .then ( res => jsonResponse = this.state.apiResponse );
  }

  componentDidMount(){
    this.readQuery();
  }

  async componentWillReceiveProps(nextProps) {
    let params_string = "";
    //LOGICA:
    // para cada parametro de filtro
    // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
    if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
      if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
    }
    else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

    if ( nextProps.filtro_upz !== this.state.filtro_upz ){
      if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
    }
    else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

    if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
      if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
    }
    else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

    if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
      if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
    }
    else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

    if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
      if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
    }
    else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

    if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate )) params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
    else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");

    if (params_string.length >= 2 ) params_string = params_string.substring(2);

      if(nextProps.filtro_metrica !== this.state.filtro_metrica) {

    } else {

      try {
        //call API
        /// mandar valores de filtros al servicio y determinar que responde para renderizar
        const response = await fetch(this.state.url + "?" + params_string);
        if (!response.ok){
          throw Error(response.statusText);
        }
        jsonResponse = await response.text();
        jsonResponse = JSON.parse(jsonResponse);
      }catch (error) {
        console.log(error);
      }
    }
      this.setState( prevstate => ({
        ...prevstate, 
        apiResponse: jsonResponse,
        filtro_localidad: nextProps.filtro_localidad,
        filtro_metrica: nextProps.filtro_metrica,
        filtro_metrica_lag: nextProps.filtro_metrica_lag,
        filtro_metrica_cp: nextProps.filtro_metrica_cp,
        filtro_año_pas: nextProps.filtro_año_pas,
        startDate: nextProps.startDate,
        endDate: nextProps.endDate
      } ) );
}

shouldComponentUpdate(nextProps,nextState){
   if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica){
        return true
      }
      return false
   } else {
      return true
   }
}

render() {
    var metrica = this.state.filtro_metrica;

    if (this.state.filtro_metrica === "Ticket Prom") {
        metrica = "Tickets";
    } else if (this.state.filtro_metrica === "Búsquedas") {
        metrica = "Busquedas"
    }

    if (metrica === "Ventas") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Suma_ventas_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpventas";
    } if (metrica === "Flujos") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Prom_flujos_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpflujos";
    } if (metrica === "Busquedas") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Suma_Busquedas_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpbusquedas";
    } if (metrica === "Tickets") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Prom_Tiket_Promedio_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpTiket_Promedio";
    } if (metrica === "Transacciones") {
      // eslint-disable-next-line
      this.state.filtro_metrica_lag = "Suma_transacciones_Lag";
      // eslint-disable-next-line
      this.state.filtro_metrica_cp =  "cpTransacciones";
    }

    var unidad = "";

    if (metrica === "Ventas") {
      unidad = " M";
    }

    const leg = () => {
      var año = [];
      // eslint-disable-next-line
      this.state.apiResponse.map(index => {
        año.push(index.anoNumber);
      })
      return año;
    }

    var año = "";
    var añofinal = 0;
    var añopas = 0;

    const distinct = (value,index, self) => {
      return self.indexOf(value) === index;
    }

    const array = leg().filter(distinct);

    if(array.length === 1){
      año = leg()[0];
      añofinal = parseInt(año);
      añopas = añofinal - 1;
    } else {
      añofinal = " Año actual";
      añopas = " Año anterior";
    }

    return (
      <ComposedChart
        width={500}
        height={300}
        data={formatTableRecup(this.state.apiResponse)}
        margin={{
          top: 20, right: 0, bottom: 20, left: 0,
        }}
      >
        <CartesianGrid stroke="#fffff" strokeDasharray="3 3"/>
        <XAxis dataKey="mes" tick={{fontSize: 10}} />
        <YAxis yAxisId="left" tick={{fontSize: 10}} unit= {unidad}/>
        <YAxis yAxisId="right" orientation="right" tick={{fontSize: 10}} domain={[-100, 100]} unit=" %"/>
        <Tooltip content = { <CustomTooltip />}/>
        <Legend />
        <Bar yAxisId="left" dataKey={this.state.filtro_metrica_lag} name={añopas} fill="#43CDB5" />
        <Bar yAxisId="left" dataKey={metrica} name={añofinal} fill="#3798CD" />
        <Line yAxisId="right" type="monotone" dataKey={this.state.filtro_metrica_cp} unit=" %" name="Cambio porcentual" stroke="#ff7300" />

      </ComposedChart>
    );
  }
}

import React from 'react';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if(payload) {
      if (payload[0].dataKey === "ventas_Belleza_Bienestar" || payload[1].dataKey === "ventas_Comercio"||
          payload[2].dataKey === "ventas_Compras"|| payload[3].dataKey === "ventas_Entretenimiento" ||
          payload[4].dataKey === "ventas_Restaurantes" ||payload[5].dataKey === "ventas_Salud" ||
          payload[6].dataKey === "ventas_Servicios" ||payload[7].dataKey === "ventas_Turismo" ||
          payload[8].dataKey === "ventas_Trafico_Transporte" ||payload[9].dataKey === "ventas_Distrito"){
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Nombre_Loc} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ED254E'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].payload.sum_ventas_Belleza_Bienestar).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[0].value)}
            ${payload[0].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FC6F59'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].payload.sum_ventas_Comercio).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[1].value)}
            ${payload[1].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FEB238'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].payload.sum_ventas_Compras).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[2].value)}
            ${payload[2].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FABC3B'}}>{`${payload[3].name} :
            ${Number.parseFloat(payload[3].payload.sum_ventas_Entretenimiento).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[3].value)}
            ${payload[3].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#59CD8F'}}>{`${payload[4].name} :
            ${Number.parseFloat(payload[4].payload.sum_ventas_Restaurantes).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[4].value)}
            ${payload[4].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#43CDB5'}}>{`${payload[5].name} :
            ${Number.parseFloat(payload[5].payload.sum_ventas_Salud).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[5].value)}
            ${payload[5].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[6].name} :
            ${Number.parseFloat(payload[6].payload.sum_ventas_Servicios).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[6].value)}
            ${payload[6].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3042A1'}}>{`${payload[7].name} :
            ${Number.parseFloat(payload[7].payload.sum_ventas_Turismo).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[7].value)}
            ${payload[7].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#7030A0'}}>{`${payload[8].name} :
            ${Number.parseFloat(payload[8].payload.sum_ventas_Trafico_Transporte).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[8].value)}
            ${payload[8].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#523173'}}>{`${payload[9].name} :
            ${Number.parseFloat(payload[9].payload.sum_ventas_Distrito).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
            (${Number.parseFloat(payload[9].value)}
            ${payload[9].unit})`}<br/></text>
          </div>
        );
      } if (payload[0].dataKey === "Busquedas_Belleza_Bienestar" || payload[1].dataKey === "Busquedas_Comercio"||
          payload[2].dataKey === "Busquedas_Compras"|| payload[3].dataKey === "Busquedas_Entretenimiento" ||
          payload[4].dataKey === "Busquedas_Restaurantes" ||payload[5].dataKey === "Busquedas_Salud" ||
          payload[6].dataKey === "Busquedas_Servicios" ||payload[7].dataKey === "Busquedas_Turismo" ||
          payload[8].dataKey === "Busquedas_Trafico_Transporte" ||payload[9].dataKey === "Busquedas_Distrito"){
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Nombre_Loc} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ED254E'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].payload.sum_Busquedas_Belleza_Bienestar).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[0].value)}
            ${payload[0].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FC6F59'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].payload.sum_Busquedas_Comercio).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[1].value)}
            ${payload[1].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FEB238'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].payload.sum_Busquedas_Compras).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[2].value)}
            ${payload[2].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FABC3B'}}>{`${payload[3].name} :
            ${Number.parseFloat(payload[3].payload.sum_Busquedas_Entretenimiento).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[3].value)}
            ${payload[3].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#59CD8F'}}>{`${payload[4].name} :
            ${Number.parseFloat(payload[4].payload.sum_Busquedas_Restaurantes).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[4].value)}
            ${payload[4].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#43CDB5'}}>{`${payload[5].name} :
            ${Number.parseFloat(payload[5].payload.sum_Busquedas_Salud).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[5].value)}
            ${payload[5].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[6].name} :
            ${Number.parseFloat(payload[6].payload.sum_Busquedas_Servicios).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[6].value)}
            ${payload[6].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3042A1'}}>{`${payload[7].name} :
            ${Number.parseFloat(payload[7].payload.sum_Busquedas_Turismo).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[7].value)}
            ${payload[7].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#7030A0'}}>{`${payload[8].name} :
            ${Number.parseFloat(payload[8].payload.sum_Busquedas_Trafico_Transporte).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[8].value)}
            ${payload[8].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#523173'}}>{`${payload[9].name} :
            ${Number.parseFloat(payload[9].payload.sum_Busquedas_Distrito).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[9].value)}
            ${payload[9].unit})`}<br/></text>
          </div>
        );
    }  if (payload[0].dataKey === "flujos_Belleza_Bienestar" || payload[1].dataKey === "flujos_Comercio"||
          payload[2].dataKey === "flujos_Compras"|| payload[3].dataKey === "flujos_Entretenimiento" ||
          payload[4].dataKey === "flujos_Restaurantes" ||payload[5].dataKey === "flujos_Salud" ||
          payload[6].dataKey === "flujos_Servicios" ||payload[7].dataKey === "flujos_Turismo" ||
          payload[8].dataKey === "flujos_Trafico_Transporte" ||payload[9].dataKey === "flujos_Distrito"){
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Nombre_Loc} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ED254E'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].payload.sum_flujos_Belleza_Bienestar).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[0].value)}
            ${payload[0].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FC6F59'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].payload.sum_flujos_Comercio).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[1].value)}
            ${payload[1].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FEB238'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].payload.sum_flujos_Compras).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[2].value)}
            ${payload[2].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FABC3B'}}>{`${payload[3].name} :
            ${Number.parseFloat(payload[3].payload.sum_flujos_Entretenimiento).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[3].value)}
            ${payload[3].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#59CD8F'}}>{`${payload[4].name} :
            ${Number.parseFloat(payload[4].payload.sum_flujos_Restaurantes).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[4].value)}
            ${payload[4].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#43CDB5'}}>{`${payload[5].name} :
            ${Number.parseFloat(payload[5].payload.sum_flujos_Salud).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[5].value)}
            ${payload[5].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[6].name} :
            ${Number.parseFloat(payload[6].payload.sum_flujos_Servicios).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[6].value)}
            ${payload[6].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3042A1'}}>{`${payload[7].name} :
            ${Number.parseFloat(payload[7].payload.sum_flujos_Turismo).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[7].value)}
            ${payload[7].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#7030A0'}}>{`${payload[8].name} :
            ${Number.parseFloat(payload[8].payload.sum_flujos_Trafico_Transporte).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[8].value)}
            ${payload[8].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#523173'}}>{`${payload[9].name} :
            ${Number.parseFloat(payload[9].payload.sum_flujos_Distrito).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[9].value)}
            ${payload[9].unit})`}<br/></text>
          </div>
        );
      }  if (payload[0].dataKey === "Transacciones_Belleza_Bienestar" || payload[1].dataKey === "Transacciones_Comercio"||
          payload[2].dataKey === "Transacciones_Compras"|| payload[3].dataKey === "Transacciones_Entretenimiento" ||
          payload[4].dataKey === "Transacciones_Restaurantes" ||payload[5].dataKey === "Transacciones_Salud" ||
          payload[6].dataKey === "Transacciones_Servicios" ||payload[7].dataKey === "Transacciones_Turismo" ||
          payload[8].dataKey === "Transacciones_Trafico_Transporte" ||payload[9].dataKey === "Transacciones_Distrito"){
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Nombre_Loc} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ED254E'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].payload.sum_Transacciones_Belleza_Bienestar).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[0].value)}
            ${payload[0].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FC6F59'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].payload.sum_Transacciones_Comercio).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[1].value)}
            ${payload[1].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FEB238'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].payload.sum_Transacciones_Compras).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[2].value)}
            ${payload[2].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FABC3B'}}>{`${payload[3].name} :
            ${Number.parseFloat(payload[3].payload.sum_Transacciones_Entretenimiento).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[3].value)}
            ${payload[3].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#59CD8F'}}>{`${payload[4].name} :
            ${Number.parseFloat(payload[4].payload.sum_Transacciones_Restaurantes).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[4].value)}
            ${payload[4].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#43CDB5'}}>{`${payload[5].name} :
            ${Number.parseFloat(payload[5].payload.sum_Transacciones_Salud).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[5].value)}
            ${payload[5].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[6].name} :
            ${Number.parseFloat(payload[6].payload.sum_Transacciones_Servicios).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[6].value)}
            ${payload[6].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3042A1'}}>{`${payload[7].name} :
            ${Number.parseFloat(payload[7].payload.sum_Transacciones_Turismo).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[7].value)}
            ${payload[7].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#7030A0'}}>{`${payload[8].name} :
            ${Number.parseFloat(payload[8].payload.sum_Transacciones_Trafico_Transporte).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[8].value)}
            ${payload[8].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#523173'}}>{`${payload[9].name} :
            ${Number.parseFloat(payload[9].payload.sum_Transacciones_Distrito).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[9].value)}
            ${payload[9].unit})`}<br/></text>
          </div>
        );
      } else {
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Nombre_Loc} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ED254E'}}>{`${payload[0].name} : $
            ${Number.parseFloat(payload[0].payload.sum_Tiket_Promedio_Belleza_Bienestar).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[0].value)}
            ${payload[0].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FC6F59'}}>{`${payload[1].name} : $
            ${Number.parseFloat(payload[1].payload.sum_Tiket_Promedio_Comercio).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[1].value)}
            ${payload[1].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FEB238'}}>{`${payload[2].name} : $
            ${Number.parseFloat(payload[2].payload.sum_Tiket_Promedio_Compras).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[2].value)}
            ${payload[2].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#FABC3B'}}>{`${payload[3].name} : $
            ${Number.parseFloat(payload[3].payload.sum_Tiket_Promedio_Entretenimiento).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[3].value)}
            ${payload[3].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#59CD8F'}}>{`${payload[4].name} : $
            ${Number.parseFloat(payload[4].payload.sum_Tiket_Promedio_Restaurantes).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[4].value)}
            ${payload[4].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#43CDB5'}}>{`${payload[5].name} : $
            ${Number.parseFloat(payload[5].payload.sum_Tiket_Promedio_Salud).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[5].value)}
            ${payload[5].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[6].name} : $
            ${Number.parseFloat(payload[6].payload.sum_Tiket_Promedio_Servicios).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[6].value)}
            ${payload[6].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3042A1'}}>{`${payload[7].name} : $
            ${Number.parseFloat(payload[7].payload.sum_Tiket_Promedio_Turismo).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[7].value)}
            ${payload[7].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#7030A0'}}>{`${payload[8].name} : $
            ${Number.parseFloat(payload[8].payload.sum_Tiket_Promedio_Trafico_Transporte).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[8].value)}
            ${payload[8].unit})`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#523173'}}>{`${payload[9].name} : $
            ${Number.parseFloat(payload[9].payload.sum_Tiket_Promedio_Distrito).toLocaleString('es-CO')}
            (${Number.parseFloat(payload[9].value)}
            ${payload[9].unit})`}<br/></text>
          </div>
        );
      }
    }
  }
  return null;
};

//Imports de librerias y componentes.
import React from "react";
import keplerGlReducer from "kepler.gl/reducers";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { taskMiddleware } from "react-palm/tasks";
import { Provider, useDispatch } from "react-redux";
import KeplerGl from "kepler.gl";
import { addDataToMap } from "kepler.gl/actions";
import Processors from 'kepler.gl/processors';
import '../../resources/styles/MapVariacionesStyles.css'

import ConfigStyleKeplerMapaDosAvanzado from '../../resources/KeplerJsonConfig/b_08_ConfigStyleKeplerMapaDosAvanzado.json';

import { id_mapa_tablero, datosProps, alto_mapa_conf, ancho_mapa_conf } from './TemplateComponentOportEstrategicas2';

//Reducer perzonalidado del mapa en donde se indica que parámetros cargan por defecto.
const customizedKeplerGlReducer =
keplerGlReducer.initialState({
  uiState: {readOnly: true},
  }
);

//Lamado al reducer personalizado.

const reducers = combineReducers({
  keplerGl: customizedKeplerGlReducer,
});

//Creación del store de kepler y llamado del reducer.

const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));

//Función que permite la ejecución del mapa.

export default function KeplerMapComponent() {

  //Método de retorno el cual renderiza los componentes codificados en estilo JSX.

  return (
    <div>
    <Provider store={store}>
      <Map />
    </Provider>
    </div>
  );
}

//Función para pasar los parámetros de datos que se cargaran al mapa.

function Map() {

  //Inicialización del dispatch requerido en kepler.

  const dispatch = useDispatch();

  //Almacenamiento de los datos enviados por el filtro en una variable del componente.
  let data = datosProps;
  let DataFin = Processors.processRowObject(data);
  let jsonConfigurator;
    //Método de carga de datos al mapa y visualización de estos.
  React.useEffect(() => {
    switch (id_mapa_tablero) {
      case "11OportunidadesEstrategicasMapaAnuales":
      // eslint-disable-next-line
        jsonConfigurator = ConfigStyleKeplerMapaDosAvanzado;
      break;

      default:
        console.log("unappropiate user selection");
      break;

}

  //Método de carga de datos al mapa y visualización de estos.

      dispatch(
          addDataToMap({
        // datasets
        datasets: {
          info: {
            id: 'my_data'
          },
          data: DataFin,
        },
        option: {},
        // config
        config: jsonConfigurator
        }
      )
      );

  }, [dispatch, DataFin]);

  let id = Math.random();
  id = id.toString();

  let altura = Number(alto_mapa_conf);
  let ancho = Number(ancho_mapa_conf);

  //Asignación de propiedades al estilo del mapa y token de mapbox.
  return (
      <KeplerGl
        id={id}
        // eslint-disable-next-line
        style={'mapbox://styles/mapbox/streets-v11'}
        theme="base"
        mapboxApiAccessToken={"pk.eyJ1IjoiY2FtaWxvbW9udG95YTAwMSIsImEiOiJja2V5aDZ4bHAwZHptMnNtYmU1cTQybmI5In0.XWyu0GoCdnuoZQpb_sd1IQ"}
        width={ancho}
        height={altura}
      />
  );
}

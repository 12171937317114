import React, { Component } from 'react';
import { Button, Form } from 'semantic-ui-react'
import '../../resources/styles/bs_formstyle.css';
import { db, auth } from "../../firebase/firebase";
//import { ExCodigoComercioUsuario } from "../menu/SidePan";

export default class Register extends Component {

  state = { correo: "", nombre: "", password: "", rol: "", idRol: "" };

  handleCorreoChange = ({ target }) => {
    this.setState({ correo: target.value });
  };

  handleNombreChange = ({ target }) => {
    this.setState({ nombre: target.value });
  };

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleRolChange = ({ target }) => {
    this.setState({ rol: target.value });
    if(target.value === "value4"){
      this.setState({ idRol: "1" });
    } else if(target.value === "value3"){
      this.setState({ idRol: "2" });
    }
  };

  registrarUsuario(correo, clave, idClienteComercio, idRol, identificadorUnico, NombreUsuario) {
    auth.createUserWithEmailAndPassword(correo, clave)
    .then(() => {
      alert("Usuario Agregado con éxito.");
      db.collection('usuarios').doc(auth.currentUser.uid)
        .set({
          IdClienteComercio: idClienteComercio,
          IdRol: idRol,
          IdentificadorUnico: identificadorUnico,
          "correo usuario": correo,
          nombreUsuario: NombreUsuario,
        })
        .catch(error => {
          var errorFb = error;
          alert('Error al crear el usuario :'  + errorFb);
        })
      })
      .catch(error => {
        var errorAuth = error.message;
        alert('Error al crear el usuario : ' + errorAuth);
      }
    )
  }

  handleSubmit = () => {
    // eslint-disable-next-line
    //Aquí va ExCodigoComercioUsuario
    const { correo, nombre, password, idRol } = this.state;
    this.registrarUsuario(correo, password, idRol, 999, nombre);
  };

  render() {

    return (
      <Form className="formregister">
        <Form.Field id="inputs-form">
          <label>Correo electrónico.</label>
          <input placeholder='Correo electrónico.'
                 onChange={this.handleCorreoChange}
                 name = 'correo'
          />
        </Form.Field>
        <Form.Field id="inputs-form">
          <label>Nombre de usuario.</label>
          <input placeholder='Nombre de usuario.'
                 onChange={this.handleNombreChange}
                 name = 'nombre'
          />
        </Form.Field>
        <Form.Field id="inputs-form">
          <label>Contraseña.</label>
          <input placeholder='Contraseña.'
                 type='password'
                 onChange={this.handlePasswordChange}
                 name = 'password'
          />
        </Form.Field>
        <Form.Field id="inputs-form">
          <label>Rol del usuario.</label>
          <select name="rol"
          onChange={this.handleRolChange}
          >
            <option value="option">--Seleccione un rol--</option>
            <option value="value3">Analista</option>
            <option value="value4">Visualizador</option>
          </select>
        </Form.Field>
        <Button
            type='submit'
            id="boton-form"
            onClick={this.handleSubmit}
            >
            Crear Usuario
            </Button>
         <Button
            type='reset'
            id="boton-form"
            >
            Limpiar
            </Button>
      </Form>
    );
  }
}

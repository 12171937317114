import React from 'react';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload) {
      if (payload[1].dataKey === "Tickets"){
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Semana.value } `}<br/></text>

            <text className="label" style={{'font-size':'13px',  'color':'#8e44c6'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].value.toFixed(2)).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].value.toFixed(2)).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} `}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ff7300'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].value)}
            ${payload[2].unit}`}<br/></text>
          </div>
        );
      }
      if (payload[1].dataKey === "Ventas"){
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Semana.value } `}<br/></text>

            <text className="label" style={{'font-size':'13px',  'color':'#8e44c6'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].value.toFixed(2)).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} M`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].value.toFixed(2)).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })} M`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ff7300'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].value)}
            ${payload[2].unit}`}<br/></text>
          </div>
        );
      } else {
        return (
          <div style={{'background-color': 'white', 'padding':'8px',  'border':'1px solid gray', 'offset':'10'}}>
            <text className="label" style={{'font-size':'13.2px'}}> {`${payload[0]["payload"].Semana.value} `}<br/></text>

            <text className="label" style={{'font-size':'13px',  'color':'#43CDB5'}}>{`${payload[0].name} :
            ${Number.parseFloat(payload[0].value.toFixed(2)).toLocaleString('es-CO')}`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#3798CD'}}>{`${payload[1].name} :
            ${Number.parseFloat(payload[1].value.toFixed(2)).toLocaleString('es-CO')}`}<br/></text>

            <text className="label" style={{'font-size':'13px', 'color':'#ff7300'}}>{`${payload[2].name} :
            ${Number.parseFloat(payload[2].value)}
            ${payload[2].unit}`}<br/></text>
          </div>
        );
      }
    }
  }
  return null;
};

import React, { Component } from "react";
import InfoCardsComponent from "../charts/b_01_InfoCards";
import MixBarChart from "../charts/b_01_BarChart";
import PieChart1 from "../charts/b_01_PieChart1";
import PieChart2 from "../charts/b_01_PieChart2";
import '../../resources/styles/MainStyles.css';
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import TemplateComponent from "../maps/TemplateComponent";
import { title } from '../../resources/helpers/DinamicTitle';
import { dateParams } from '../../resources/helpers/globalDates'
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";


const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<1024) width=width / 1.2
else if ( width >= 1024 ) width = width / 2.3    


export default class TableroPerfilBogota extends Component {
	
	constructor( props )
	{
		super( props );
		this.state = 
		{ 
			keyTablero: '01perfilBogota', 
			apiResponse: 0,
			//ModuloAvanzadoTab2
			//impactoCambiosPorcentuales
    		localidad: '',
		    upz: '',
		    barrio: '',
		    estrato: '',
		    categoria: '',
		    metrica: 'Ventas',
		    usuarioIniciado: '',
		    filtro_fecha_inicio: dateParams.startDate,
		    filtro_fecha_fin: dateParams.endDate, 
		    alto_mapa:  570,
		    ancho_mapa: width
		};
	}
 

 	componentDidMount()
 	{
	    /*window.addEventListener('resize', ()=>{
	        headerHeight = document.querySelector('.encabezado').offsetHeight
	        optionsHeight = document.querySelector('.opciones').offsetHeight
	    } );*/
	}

 	updateQuery = (nuevoEstado) => {
        this.setState( 
        {
	        localidad : nuevoEstado.localidad,
	        upz: nuevoEstado.upz,
	        barrio: nuevoEstado.barrio,
	        estrato: nuevoEstado.estrato,
	        categoria: nuevoEstado.categoriasBasico,
	        metrica : nuevoEstado.metrica,
	        apiResponse: nuevoEstado.contadorDeCambios
	    })
  	}


 	updateUserCats = (usuarioIniciado) => {
 		if ( usuarioIniciado!== this.state.usuarioIniciado){
      		this.setState( { usuarioIniciado: usuarioIniciado })
    	}
  	}

  	updateDates = (nuevasFechas) => {
  		// se hace use del operador spread, para evitar mutabilidad
    	this.setState( prevState => ( 
    		{
    			...prevState ,  
	    		filtro_fecha_inicio : nuevasFechas.startDate,
	      		filtro_fecha_fin: nuevasFechas.endDate, 
	      		apiResponse: nuevasFechas.apiResponse
      		} 
      	) );
  	}

  	// se evita "reconciliacion" de react
  	shouldComponentUpdate ( nextProps, nextState ){
  		if(this.state.apiResponse === nextState.apiResponse){
	      if (this.usuarioIniciado !== nextState.usuarioIniciado){
	          return true 
	      }
	      return false
	    } 
	    return true
  	} 

	componentDidUpdate(){
	  	//alert(document.querySelector('.header2').offsetHeight)
	}

	//Método que retorna el renderizado de los componentes codificados en estilo JSX.
	render(){

		return(
	
		<body >
		
			<div className="encabezado" >

				<div style={{'display':'contents'}}>

					<div className="logo1" >
						<img className="LogoImage" src={imgn} alt="imgn" /> 
						<img className="LogoImage2" src={imgn2} alt="imgn" /> 
					</div>

					<div className="titulo1" >
						Resumen del perfil de Bogotá y sus comercios
					</div>
					<div className="calendario1" style={{'z-index':'80'}}>
						<DatePickerComponent 
							tablero = {this.state.keyTablero}
							datesChanged = {this.updateDates}
						/>
					</div>
				</div>
			</div>

			<SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

			<div className="opciones" >
				<div style={{'display':'contents'}}>
					<div className="subtitulo">
						<h5 align="left" style={{'color':'#4F4F4F'}}>
						Consulte el estado económico de la ciudad y las categorías de sus comercios.
			       		Consulte cómo se distribuye el mercado por localidades, barrios y estratos
          				socioeconómicos. </h5> 
				    </div>
			    
			        <div className="filtros1">
						<MultipleFiltersComponent 
							tablero={this.state.keyTablero}
							metrica={this.state.metrica}
				           	onFilterChange={this.updateQuery}
				        />            
					</div>
				</div>
			</div>


			<div className="paneltableros">

	
	<div className="bs_01_infocards">
          <InfoCardsComponent
            filtro_localidad={this.state.localidad}
            filtro_upz={this.state.upz}
            filtro_barrio={this.state.barrio}
            filtro_estrato= {this.state.estrato}
            filtro_categoria= {this.state.categoria}
            filtro_metrica={this.state.metrica}
            url={urlDev.urlApiDev+"ConsultaPerfilBogota/TablaUno"}
            startDate={this.state.filtro_fecha_inicio}
            endDate= {this.state.filtro_fecha_fin}
          />
        </div>


		<div className="contenedor1-perfil-bogota">
			<h5>{title(this.state.metrica, " por localidad")}</h5>
               <MixBarChart
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                    filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaPerfilBogota/BarrasHorizontal"}
                  startDate={this.state.filtro_fecha_inicio}
            		endDate= {this.state.filtro_fecha_fin}
                />
              
            </div>

 <div className="map-perfil-bogota">
              <div className="contenedor3-perfil-bogota">
                <h5>Mapa de Bogotá</h5>
                  <TemplateComponent
                    filtro_localidad={this.state.localidad}
                    filtro_upz={this.state.upz}
                    filtro_barrio={this.state.barrio}
                    filtro_estrato= {this.state.estrato}
                    filtro_categoria= {this.state.categoria}
                    filtro_metrica={this.state.metrica}
                    startDate={this.state.filtro_fecha_inicio}
                    endDate= {this.state.filtro_fecha_fin}
                    url = {urlDev.urlApiDev+"ConsultaPerfilBogota/Mapa"}
                    id_mapa_tablero = {this.state.keyTablero}
                    alto_mapa = {this.state.alto_mapa}
                    ancho_mapa = {this.state.ancho_mapa}
                  />
              
              </div>
              </div>


            <div className="contenedor2-perfil-bogota">
              <h5>Clasificación Comercios por Categoría</h5>             
                <PieChart1
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  url={urlDev.urlApiDev+"ConsultaPerfilBogota/TortaUno"}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
            </div>

				  <div className="contenedor4-perfil-bogota">
                <h5>Clasificación Comercios por Estrato</h5>
                  <PieChart2
                    filtro_localidad={this.state.localidad}
                    filtro_upz={this.state.upz}
                    filtro_barrio={this.state.barrio}
                    filtro_estrato= {this.state.estrato}
                    filtro_categoria= {this.state.categoria}
                    filtro_metrica={this.state.metrica}
                    url={urlDev.urlApiDev+"ConsultaPerfilBogota/TortaDos"}
                    startDate={this.state.filtro_fecha_inicio}
                    endDate= {this.state.filtro_fecha_fin}
                  />
              </div>

              </div>
			<div className="footer2">Footer
			</div>
		</body>

		)
	}
}
	
import React, { Component } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
let jsonResponse = [];
export default class MixBarChart extends Component {

  constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz: props.filtro_upz,
      filtro_barrio: props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica,
      Filtro_Grupo1 : props.Filtro_Grupo1,
      Filtro_Grupo2 : props.Filtro_Grupo2,
      Filtro_Grupo3 : props.Filtro_Grupo3,
      Filtro_Grupo4 : props.Filtro_Grupo4,
      Filtro_Grupo5 : props.Filtro_Grupo5,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  //callAPi
  readQuery() {
    fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
      .then ( res => jsonResponse = this.state.apiResponse );
  }


  componentDidMount(){
    this.readQuery();
  }

  async componentWillReceiveProps(nextProps) {
      let params_string = "";
      // para cada parametro de filtro
      // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
      if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
        if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
      }
      else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

      if ( nextProps.filtro_upz !== this.state.filtro_upz ){
        if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
      }
      else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

      if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
        if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
      }
      else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

      if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
        if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
      }
      else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

      if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
        if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
      }
      else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

      if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate )) params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
      else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");


      if (params_string.length >= 2 ) params_string = params_string.substring(2);
      if(nextProps.filtro_metrica !== this.state.filtro_metrica) {

      } else {
        try {
          //call API
          /// mandar valores de filtros al servicio y determinar que responde para renderizar
          const response = await fetch(this.state.url + "?" + params_string);
          if (!response.ok){
            throw Error(response.statusText);
          }
          jsonResponse = await response.text();
          jsonResponse = JSON.parse(jsonResponse);
        }catch (error) {
          console.log(error);
        }
      }
      this.setState( prevstate => ({
        ...prevstate,
        apiResponse: jsonResponse,
        filtro_localidad: nextProps.filtro_localidad,
        filtro_upz: nextProps.filtro_upz,
        filtro_barrio: nextProps.filtro_barrio,
        filtro_metrica: nextProps.filtro_metrica,
        Filtro_Grupo1: nextProps.Filtro_Grupo1,
        Filtro_Grupo2: nextProps.Filtro_Grupo2,
        Filtro_Grupo3: nextProps.Filtro_Grupo3,
        Filtro_Grupo4: nextProps.Filtro_Grupo4,
        Filtro_Grupo5: nextProps.Filtro_Grupo5,
        startDate: nextProps.startDate,
        endDate: nextProps.endDate
      } )
    );
}

 shouldComponentUpdate(nextProps,nextState){
   if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica){
        return true
      }
      return false
   } else {
      return true
   }
}

render() {
    var metrica = this.state.filtro_metrica;

    if (this.state.filtro_metrica === "Ticket Prom") {
        metrica = "Tickets";
    } else if (this.state.filtro_metrica === "Búsquedas") {
        metrica = "Busquedas"
    }

    if (metrica === "Ventas") {
      // eslint-disable-next-line
      this.state.Grupo1 = "Grupo1Ventas";
      // eslint-disable-next-line
      this.state.Grupo2 = "Grupo2Ventas";
      // eslint-disable-next-line
      this.state.Grupo3 = "Grupo3Ventas";
      // eslint-disable-next-line
      this.state.Grupo4 = "Grupo4Ventas";
      // eslint-disable-next-line
      this.state.Grupo5 = "Grupo5Ventas";
    } if (metrica === "Busquedas") {
      // eslint-disable-next-line
      this.state.Grupo1 = "Grupo1Busquedas";
      // eslint-disable-next-line
      this.state.Grupo2 = "Grupo2Busquedas";
      // eslint-disable-next-line
      this.state.Grupo3 = "Grupo3Busquedas";
      // eslint-disable-next-line
      this.state.Grupo4 = "Grupo4Busquedas";
      // eslint-disable-next-line
      this.state.Grupo5 = "Grupo5Busquedas";
    } if (metrica === "Flujos"){
      // eslint-disable-next-line
      this.state.Grupo1 = "Grupo1Flujos";
      // eslint-disable-next-line
      this.state.Grupo2 = "Grupo2Flujos";
      // eslint-disable-next-line
      this.state.Grupo3 = "Grupo3Flujos";
      // eslint-disable-next-line
      this.state.Grupo4 = "Grupo4Flujos";
      // eslint-disable-next-line
      this.state.Grupo5 = "Grupo5Flujos";
    } if (metrica === "Tickets") {
      // eslint-disable-next-line
      this.state.Grupo1 = "Grupo1Tickets";
      // eslint-disable-next-line
      this.state.Grupo2 = "Grupo2Tickets";
      // eslint-disable-next-line
      this.state.Grupo3 = "Grupo3Tickets";
      // eslint-disable-next-line
      this.state.Grupo4 = "Grupo4Tickets";
      // eslint-disable-next-line
      this.state.Grupo5 = "Grupo5Tickets";
    } if (metrica === "Transacciones") {
      // eslint-disable-next-line
      this.state.Grupo1 = "Grupo1Transacciones";
      // eslint-disable-next-line
      this.state.Grupo2 = "Grupo2Transacciones";
      // eslint-disable-next-line
      this.state.Grupo3 = "Grupo3Transacciones";
      // eslint-disable-next-line
      this.state.Grupo4 = "Grupo4Transacciones";
      // eslint-disable-next-line
      this.state.Grupo5 = "Grupo5Transacciones";
    }

    return (
      <ComposedChart
        layout="vertical"
        width={490}
        height={410}
        data={this.state.apiResponse}
        margin={{
          top: 20, right: 10, bottom: 20, left: 25,
        }}
      >
        <XAxis type="number" domain={[0, 50]}/>
        <YAxis dataKey="LOCALIDAD" type="category" tick={{fontSize: 8, width : 200, height : 1}} interval = {0}/>
        <Tooltip />
        <Legend/>
        <Bar dataKey={this.state.Grupo1} name="Muy Alto" stackId="a" fill="#b30500">
        </Bar>
        <Bar dataKey={this.state.Grupo2} name="Alto" stackId="a" fill="#F8C0AA">
        </Bar>
        <Bar dataKey={this.state.Grupo3} name="Medio" stackId= "a" fill="#ffeda7">
        </Bar>
        <Bar dataKey={this.state.Grupo4} name="Bajo"  stackId= "a" fill="#5DBABF">
#fff6d4        </Bar>
        <Bar dataKey={this.state.Grupo5} name="Muy Bajo " stackId= "a" fill="#1d5cac">
        </Bar>
      </ComposedChart>
    );
  }
}

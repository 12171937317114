export const formatMetrica = (metrica) => {
  var arraycadena = metrica.split("");
  var cadena = "";
  var response = false;

  for (var i = 0; i < arraycadena.length; i++) {
    cadena = cadena + arraycadena[i];
    if (cadena === "Ventas") {
      response = true;
      return response;
    }
  }
  return response
}

export const arrayToString = (arreglo) => {
  var content = "";
  if (arreglo){
    for(var i = 0; i<arreglo.length; i++){
      content += arreglo[i]+",";
    }  
  }
  return content.substring(0,(content.length-1));
}

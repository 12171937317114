import React, { Component } from "react";
import ScatterChart from "../charts/b_04_MultipleScatterChart"
import SideMenu from '../menu/ResponsiveMenu';
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import '../../resources/styles/MainStyles.css';
import '../../resources/styles/bs_04_VariacionSemanal.css';
import { dateParams } from '../../resources/helpers/globalDates'
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";

const urlDev = require("../../properties/properties");

//Clase que permite la ejecución del componente.
export default class VariacionSemanalComponent extends Component {

  constructor( props ){
    super( props );
    this.state = { keyTablero: 'DispersionVariaciones', 
    apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    usuarioIniciado: '',
    metricaY: 'Ventas',
    metricaX: 'Flujos',
    variacion: 'Semanal',
    urlDispersion: urlDev.urlApiDev+"ConsultaProcesoRecuperacion/DispersionSemanal",
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    };
  }

  updateQuery = (nuevoEstado) => {
    this.setState( prevstate => (
      { ...prevstate, 
      localidad:nuevoEstado.localidad,
      upz: nuevoEstado.upz,
      barrio: nuevoEstado.barrio,
      estrato: nuevoEstado.estrato,
      categoria: nuevoEstado.categoriasBasico,
      apiResponse: nuevoEstado.contadorDeCambios,
      variacion: nuevoEstado.variacion,
      metricaY : nuevoEstado.metricaY,
      metricaX : nuevoEstado.metricaX,
      urlDispersion: urlDev.urlApiDev+"ConsultaProcesoRecuperacion/Dispersion" + nuevoEstado.variacion
    }) )
  }

  updateDates = (nuevasFechas) => {
      // se hace use del operador spread, para evitar mutabilidad
      this.setState( prevState => ( 
        {
          ...prevState ,  
          filtro_fecha_inicio : nuevasFechas.startDate,
            filtro_fecha_fin: nuevasFechas.endDate, 
            apiResponse: nuevasFechas.apiResponse
          } 
        ) );
    }

  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.state.apiResponse === nextState.apiResponse){
      if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
      }
      return false
    } else {
      return true
    }
  }

  //Método para el renderizado del componente.
  render() {

    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    // Los componentes reciben las propiedades y estados del tablero como argumento
    return (
      <body >
    
      <div className="encabezado" >

        <div style={{'display':'contents'}}>

          <div className="logo1" >
            <img className="LogoImage" src={imgn} alt="imgn" /> 
            <img className="LogoImage2" src={imgn2} alt="imgn" /> 
          </div>
          <div className="titulo1" >
            Variación comparada
          </div>
          <div className="calendario1" style={{'z-index':'80'}}>
            <DatePickerComponent 
              tablero = {this.state.keyTablero}
              datesChanged = {this.updateDates}
            />
          </div>
        </div>
      </div>

      <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

      <div className="opciones_03" >
        <div style={{'display':'contents'}}>
          <div className="subtitulo">
            <h5 align="left" style={{'color':'#4F4F4F'}}>
            Compare la evolución porcentual de los negocios con dos métricas en su variación semanal y anual. </h5> 
            </div>
          
              <div className="filtros1">
            <MultipleFiltersComponent 
              tablero={this.state.keyTablero}
              metrica={this.state.metrica}
                    onFilterChange={this.updateQuery}
                />            
          </div>
        </div>
      </div>


      <div className="paneltableros">
          <div className="graphScatterChart">
              <h5><div className="title-graph-variacion-Semanal"> Dispersión temporal de métricas </div></h5>
                <ScatterChart
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metricaY={this.state.metricaY}
                  filtro_metricaX={this.state.metricaX}
                  variacion= {this.state.variacion}
                  url={this.state.urlDispersion}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                />
          </div>
        </div>
      <div className="footer2">Footer
      </div>
    </body>
    );
  }
}

import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../resources/styles/Datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import esLocale from 'moment/locale/es';
import { dateParams } from '../../resources/helpers/globalDates'

//variablees auxiliares
//notifica que si cambia y hace update al tablero
var actualizar = false
//almacenan estados inicial final y maximo
var _startDate = null
var _endDate = null

export default class DatePickerComponent extends Component {

  constructor(props){
    super(props);
    // se asignan en desarrollo distintos rangos de fecha inicial segun tableros de cada modulo
    let initial_startDate = dateParams.startDate
    let initial_endDate = dateParams.endDate
    let initial_maxDate = dateParams.maxDate
    this.state = {
      startDate: initial_startDate,
      endDate: initial_endDate,
      maxDate: initial_maxDate,
      apiResponse : 0,
      focusedInput : null
    }

  }

  componentDidUpdate(){
    var auxStart =  this.state.startDate.clone()    
    //idFecha en cubos de inicio de semana es lunes
    //con moment() domingo es  0, sabado: 6
    let dayOfAux = auxStart.day()
    if ( dayOfAux === 0 ) auxStart.add(-6, 'days')
    else auxStart.add(1-auxStart.day(), 'days')
    //console.log(auxStart)
    //console.log(this.state.startDate)

    let state_hook = {
      startDate: auxStart,
      endDate: this.state.endDate,
      apiResponse: this.state.apiResponse
    }

    this.props.datesChanged(state_hook)
  }

  async handleDates( startDate, endDate ){
    actualizar = false

    var auxMax = startDate.clone()
    var auxStart = startDate.clone()
    var auxEnd = startDate.clone()
    // maximo 3 meses o 12 semanas
    auxMax.add( 91, 'days' )
    if (startDate && ! endDate ){
      //para cualquier fecha inicial sin fecha final asigna 1 semana
      _endDate = auxStart.add(6, 'days')
      _startDate = startDate
      actualizar = true
      await this.setState( prevstate=> ({ prevstate, startDate : _startDate, endDate: _endDate, maxDate:auxMax, apiResponse:this.state.apiResponse-1 } ) )

    }else if( startDate && endDate){
      auxStart = startDate.clone()
      // si se selecciona fecha futura
      if( (auxMax < endDate) || (startDate >= endDate) ){
        // si fecha final no cumple
        //asigna solo 1 semana
        auxEnd.add( 6, 'days' )
        _endDate = auxEnd.clone()
      }else{
        // si fecha final esta entre 1 y 90 dias la asigna
        if( auxStart.add(91, 'days') >= endDate ) _endDate = endDate
      }
      _startDate = startDate
      actualizar = true
      this.state.startDate = _startDate
      this.state.endDate = _endDate
      this.state.maxDate = auxMax
      this.state.apiResponse = this.state.apiResponse-1
      //await this.setState( { startDate : , endDate: _endDate, maxDate:auxMax, apiResponse:this.state.apiResponse-1 } )
    }
  }



  shouldComponentUpdate(nextProps,nextState){
    if (actualizar || (this.state.apiResponse !== nextState.apiResponse)) {
      actualizar = false 
      dateParams.startDate = this.state.startDate
      dateParams.endDate = this.state.endDate
      dateParams.maxDate = this.state.maxDate
      return true
    }
    return false
  }


  render(){
    moment.locale('es', esLocale);
    return (
      <div >
        <DateRangePicker
          small="false"
          startDatePlaceholderText = "Fecha Inicio"
          endDatePlaceholderText = "Fecha Fin"
          showDefaultInputIcon = "true"
          startDate= {this.state.startDate}
          startDateId="your_unique_start_date_id"
          endDate={this.state.endDate}
          endDateId="your_unique_end_date_id"
          onDatesChange = { ( { startDate, endDate } ) => {
            this.handleDates( startDate, endDate )
          }}
          dateformat = 'YYYY-MM-dd'
          focusedInput={this.state.focusedInput}
          isOutsideRange={date => date.isBefore( "2020-01-01" ) || date.isAfter(this.state.maxDate) }
          onFocusChange={focusedInput => { actualizar=true; if(focusedInput==="startDate"){this.setState({maxDate:moment().add( 1, 'days' )})}; this.setState({ focusedInput }) } }
          numberOfMonths={2}
          id = "Date-Style"
        />
      </div>
  );
  }
}


import React from 'react';
import Routes from './Routes.js';

function App() {

  return (

    <div>
      <Routes />
    </div>


  );
}

export default App;

export const title = (metrica) => {
  var title = " por localidad";
  var aux;
  aux = metrica + title;
  return aux;
}


export const titletipologia = (metrica) => {
  var title = "Distribución de ";
  var titlefinal = " por preferencia de pago crédito"
  var aux;
  aux = title + metrica + titlefinal;
  return aux;
}

export const titlevisitas = (metrica) => {
  var title = "Mapa de ";
  var titlefinal = " por UPZ"
  var aux;
  aux = title + metrica + titlefinal;
  return aux;
}

export const titleaño = (metrica) => {
  var title = "Cambio Porcentual respecto al año anterior";
  var aux;
  aux = title;
  return aux;
}

import React, { Component } from 'react';
import SideMenu from '../menu/ResponsiveMenu';
import { Table } from 'semantic-ui-react'
import { arrayToString } from '../../resources/helpers/stringFormatter';
import { arrayCategoriasDos } from '../filters/MultipleFiltersComponent';
import '../../resources/styles/MainStyles.css';
import '../../resources/styles/bs_glosario.css';
import { Link } from "react-router-dom";

//const urlDev = require("../../properties/properties");
var categoriasString = arrayToString(arrayCategoriasDos);


//Clase que permite la ejecución del componente.
export default class VariacionAgrupadaComponent extends Component {

    constructor( props ){
        super( props );
        this.state = { keyTablero: 'intro', apiResponse: 0,
        categoria: categoriasString,
        usuarioIniciado: '',};
    }
    
      updateUserCats = (usuarioIniciado) => {
        if ( usuarioIniciado!== this.state.usuarioIniciado){
          this.setState( {usuarioIniciado: usuarioIniciado })
        }
      }
    
      updateQuery = ( ) => {}
    
    render(){
        return(
        <body>
            <div className="encabezado" >
                <div style={{'display':'contents'}}>
                    <div className="titulo1" >
                    Diccionario Cívico Insights.
                    </div>
                </div>
            </div>

            <SideMenu OnMenuUserLoaded={this.updateUserCats}/> 

            <div className="paneltableros_intro">
                <div className="Contenedor_glosario">
                    <Table striped >
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Término/Concepto</Table.HeaderCell>
                            <Table.HeaderCell>Descripción</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>

                        <Table.Row>
                            <Table.Cell key="1" style={{fontSize: '12px'}}>Localidad</Table.Cell>
                            <Table.Cell key="1" style={{fontSize: '12px'}}>Las localidades son unidades administrativas 
                            en las que puede ser dividida el área del Bogotá (DC). En total, Bogotá esta conformada 
                            por 20 localidades, de las cuales 19 tienen zona urbana, y una (Sumapaz), es completamente 
                            rural.</Table.Cell>
                        </Table.Row>

                        <Table.Row>  
                            <Table.Cell key="2" style={{fontSize: '12px'}}>UPZ</Table.Cell>
                            <Table.Cell key="2" style={{fontSize: '12px'}}>Las UPZ (Unidades de Planeamiento Zonal), 
                            subdividen las localidades en áreas mas pequeñas, agrupando conjuntos de barrios. 
                            No son muy comúnmente conocidas, y se utilizan por el Estado para: 1) definir pareas 
                            con una norma urbana similar (normas de construcción); y 2) realizar estadísticas oficiales 
                            a una escala menor a la "localidad". </Table.Cell> 
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="3" style={{fontSize: '12px'}}>Barrio</Table.Cell>
                            <Table.Cell key="3" style={{fontSize: '12px'}}>Los barrios son conjuntos de manzanas, 
                            y son la unidad en la que pueden ser divididas las UPZ's en el caso de Bogotá. 
                            Definiciones de barrios hay muchas para el caso de Bogotá, y diversas empresas e 
                            incluso entidades dividen y nombran los barrios de manera distinta. En nuestro caso, 
                            la capa de barrios utilizada corresponde a los "barrios catastrales" definidos oficialmente 
                            por la UADECD - (Unidad Administrativa Especial de Catastro Distrital)</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="4" style={{fontSize: '12px'}}>Categoría</Table.Cell>
                            <Table.Cell key="4" style={{fontSize: '12px'}}>En CÍVICO, agrupamos a los negocios con 
                            un sistema de categorización de tres niveles (una categorización jerárquica). En el 
                            primer nivel tenemos 10 categorías generales (ejm: Comercio, Servicios, etc.). Estas 
                            primeras "macro-categorias" (llamadas también "categorías padre"), son las que en este 
                            producto llamamos "Categorías".  Los otros dos niveles, corresponden a una especificación 
                            ás detallada del tipo de comercio.</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="5" style={{fontSize: '12px'}}>Sub categoría</Table.Cell>
                            <Table.Cell key="5" style={{fontSize: '12px'}}>Las subcategorías, son el segundo nivel de 
                            agrupación que utilizamos en Cívico para identificar las actividades de los negocios. 
                            Estas subcategorías también reciben el nombre de "categorías hijo". </Table.Cell>   
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="6" style={{fontSize: '12px'}}>Transacciones</Table.Cell>
                            <Table.Cell key="6" style={{fontSize: '12px'}}>Las "Transacciones" corresponden a la 
                            cantidad operaciones (o valga la redundancia, transacciones), que se efectúan desde 
                            la red de datafonos de Credibacno (también llamada "red de bajo valor"), en un momento y 
                            lugar determinado.</Table.Cell>  
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="7" style={{fontSize: '12px'}}>Ventas</Table.Cell>
                            <Table.Cell key="7" style={{fontSize: '12px'}}>Las "Ventas", corresponde a la suma 
                            de dinero que se mueve en cada "Transacción" dentro de la red de datafonos de Credibanco,
                            en un momento y lugar determinado.</Table.Cell> 
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="8" style={{fontSize: '12px'}}>Ticket Promedio</Table.Cell>
                            <Table.Cell key="8" style={{fontSize: '12px'}}>El "Ticket-promedio", hace referencia 
                            a la cantidad de dinero que EN PROMEDIO se mueve en un conjunto de transacciones, 
                            en un momento y lugar determinado.</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="9" style={{fontSize: '12px'}}>Flujos</Table.Cell>
                            <Table.Cell key="9" style={{fontSize: '12px'}}>Los flujos hacen referencia a la cantidad 
                            de personas que pasan por determinado lugar: es decir al tránsito peatonal y vehicular 
                            que hay en un tiempo y lugar determinado. Estos flujos se calculan del traqueo a usuarios 
                            de Cívico en la ciudad, que aceptan este seguimiento al momento de la 
                            instalación. </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="10" style={{fontSize: '12px'}}>Búsquedas</Table.Cell>
                            <Table.Cell key="10" style={{fontSize: '12px'}}>La cantidad de búsquedas virtuales que 
                            se hacen sobre un negocio. Estas búsquedas virtuales están medidas sobre las 
                            búsquedas que se hacen dentro de la plataforma virtual de Cívico.</Table.Cell> 
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="11" style={{fontSize: '12px'}}>Desplazamientos: Demanda - Oferta</Table.Cell>
                            <Table.Cell key="11" style={{fontSize: '12px'}}>Los "desplazamientos demanda-oferta", 
                            hace referencia a la distancia promedio (km) que los habitantes de un lugar recorren 
                            para acceder a una determinada categoría (o subcategoría) de negocios. Si en un lugar de 
                            la ciudad, este indicador tiene un valor relativamente alto, indica que las personas de 
                            ese lugar se desplazan grandes distancias para acceder a ese tipo de negocios. Esto quiere 
                            decir, que es probable que sean lugares en donde hay déficit de ese tipo de negocios, y 
                            por ende oportunidades comerciales. Analiza personas.</Table.Cell> 
                        </Table.Row>  

                        <Table.Row> 
                            <Table.Cell key="12" style={{fontSize: '12px'}}>Desplazamientos: Oferta - Demanda</Table.Cell>
                            <Table.Cell key="12" style={{fontSize: '12px'}}>Los "desplazamientos oferta-demanda", 
                            hacen referencia a la distancia promedio (km) que los habitantes de toda la ciudad 
                            recorren para acceder a los negocios de una categoría (o sub categoría) ubicados en un 
                            lugar de la ciudad. Si en un lugar de la ciudad, este indicador tiene un valor 
                            relativamente alto, idica que la gente se desplaza grandes distancias para acceder a los 
                            comercios ubicados en ese lugar. En otras palabras, los negocios en ese lugar de la ciudad, 
                            atraen personas que viven muy lejos: por lo que son zonas donde intependientemente de la 
                            competencia, hay gran atracción de personas. Analiza negocios.</Table.Cell> 
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="13" style={{fontSize: '12px'}}>Visitas</Table.Cell>
                            <Table.Cell key="13" style={{fontSize: '12px'}}>Es la cantidad de  visitas presenciales 
                            mayores a 1 minuto de duración que hacen los usuarios de la app de Cívico a un negocio. 
                            Es importante recordar, que solo la muestra de las visitas esta condicionada no al número 
                            de usuarios de la app de Cívico, sino al número de usuarios de la app que otorgaron permisos 
                            de localización a la aplicación al momento de su instalación.</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="14" style={{fontSize: '12px'}}>Duraciones</Table.Cell>
                            <Table.Cell key="14" style={{fontSize: '12px'}}>El tiempo total que han gastado las 
                            personas en un negocio determinado, sumando todas las visitas superiores a un minuto. 
                            La muestra de esta medición está condicionada a la cantidad de usuarios de la app de 
                            Cívico que otorgaron permisos de localización a la aplicación al 
                            momento de su instalación.</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell key="15" style={{fontSize: '12px'}}>Frecuencias</Table.Cell>
                            <Table.Cell key="15" style={{fontSize: '12px'}}>La frecuencia hace referencia a la 
                            cantidad de visitas que se hacen a un comercio en un tiempo determinado. A diferencia 
                            de otras métricas como las "Visitas" o las "Duraciones", las frecuencias se miden 
                            en torno a los usuarios y no a los comercios. Con esta variable respondemos a 
                            ¿Cada cuanto un usuario promedio visita este negocio?, que es diferente a la pregunta 
                            ¿Cada cuanto hay una visita nueva a este negocio?</Table.Cell>                                                                                                 
                        </Table.Row>
                    </Table.Body>
                    </Table>     
                </div>
                <div className="link_glosario">
                    <Link to="/">
                        <h4 style={{'color':'#1890ff'}}> Regrese a la introducción.</h4>
                    </Link>   
                </div>
            </div>
            <div className="footer2">Footer</div>
        </body>

        )
    }
}
import React, { PureComponent } from 'react';
import { Menu } from './StylesBurguerMenu';
import Burger from './Burger';
import { db, auth } from "../../firebase/firebase";

import "firebase/auth";
import "firebase/firestore";


export let contadorDeInits = 0;
export let ExCodigoComercioUsuario = "";
export let ExMatchCategorias = "";


var bandera = true;

class MenuComponent extends PureComponent
{
	state = {
	    menuOpc: [],
	    users: [],
	    roles: [],
	    modulos: [],
	    codigoComercioUsuario: "",
	    clienteComercio: [],
	    categoriasComercio: [],
	    categoriasGenerales: [],
	    categoriasComercioTransformadas: "",
	    categoriasGeneralesTransformadas: "",
	    categoriasMatch: "",
  }

  ObtenerUsuarioFirebase(){
    let usuario = auth.currentUser;
    let usuario2 ="";
    if (usuario) {
      bandera = true;
      usuario2 = usuario.email;
    }
    return usuario2;
  }

  QueryMenu(){
    db.collection("menu")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState( prevState => ( { ...prevState, 
          menuOpc: data
           } )
        );
      })
  }


  QueryObtenerUsuario(){
    db.collection("usuarios")
    .where("correo usuario", "==", this.ObtenerUsuarioFirebase())
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        // eslint-disable-next-line
        if(bandera == true){
          this.setState( prevState => ( { ...prevState,
            users: data[0], 
            codigoComercioUsuario: data[0].IdClienteComercio } ) );  
        }
      });
  }


// REVISAR CLAUSULAS 
  QueryObtenerCodigoComercio(){
    db.collection("ClienteComercio")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState( prevState => ({ ...prevState, clienteComercio: data }) );
      });
  }

// REVISAR CLAUSULAS 
  QueryObtenerRolUsuario(){
    db.collection("roles")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState(prevState => ({ ...prevState, roles: data }) );
      });
  }


/*  MatchDeCategoriasComercioGenerales(){
    var arreglo = [];
    var arreglo2 = [];
    var arreglo3 = [];
    var arreglo4 = [];
    var arreglo5 = [];
    var arreglo6 = [];

    for(var i = 0; i<this.state.categoriasGenerales.length ; i++){
      arreglo.push(this.state.categoriasGenerales[i].idCategoriaHijo );
      arreglo2.push(arreglo[i].trim());
    }
    // eslint-disable-next-line
    //this.state.categoriasGeneralesTransformadas = arreglo2;

    alert(this.state.categoriasComercio)
    for(var k = 0; k<this.state.categoriasComercio.length ; k++){
      arreglo3.push(this.state.categoriasComercio[k]);
      // eslint-disable-next-line
      arreglo4.push(arreglo3[k].trim());
    }
    // eslint-disable-next-line
    this.state.categoriasComercioTransformadas = arreglo4;

    for(var j = 0 ; j<this.state.categoriasGeneralesTransformadas.length ; j++ ){
      // eslint-disable-next-line
      if(this.state.categoriasComercioTransformadas[j] && bandera == true){
        arreglo5.push(this.state.categoriasGenerales[j].nombreCategoriaHijo);
        arreglo6.push(arreglo5[j].trim());
      }
    }
    // eslint-disable-next-line
    this.state.categoriasMatch = arreglo6;
    ExMatchCategorias = this.state.categoriasMatch;
    }
*/

  retornarNombreRol() {
    var nombreRol = "";
    for(var i = 0 ; i<this.state.roles.length ; i++){
      // eslint-disable-next-line
      if(this.state.users.IdRol == this.state.roles[i].idRol && bandera == true){
        nombreRol = this.state.roles[i].nombreRol;
      }
    }
    return nombreRol;
  }

  retornarIdModulo() {
    var idModulo = "";
    for(var i = 0 ; i<this.state.clienteComercio.length ; i++){
      // eslint-disable-next-line
      if(this.state.users.IdClienteComercio == this.state.clienteComercio[i].IdentificadorUnico 
        // eslint-disable-next-line
        && bandera == true){
        idModulo = this.state.clienteComercio[i].idModulo
        if (this.state.clienteComercio[i].categorias_id_list)
        // eslint-disable-next-line
        this.state.categoriasComercio = this.state.clienteComercio[i].categorias_id_list
        
      }
    }
    return idModulo;
  }

  componentWillMount(){
    contadorDeInits = 0
    this.QueryMenu();
    this.QueryObtenerUsuario();
    this.QueryObtenerCodigoComercio();
    this.QueryObtenerRolUsuario();
    
  }

  componentDidUpdate(){
    // con este metodo se estable que Tan Pronto termine de cargar el usuarioActivo
    // exporte la info a todos los demas componentes para que los filtros 
    // puedan determinar las categorias asociadas de cualquier usuario en cualquier modulo
    if (this.state.codigoComercioUsuario.length !==0 && contadorDeInits === 0){
      contadorDeInits +=1 
      this.props.OnMenuUserLoaded( this.state.codigoComercioUsuario )
    }

  }


	render () {

    	ExCodigoComercioUsuario = this.state.codigoComercioUsuario;
		  let nombreRol = this.retornarNombreRol();
      let idModulo = this.retornarIdModulo();
      let usuarioFirebase = this.ObtenerUsuarioFirebase();
			
      //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
	    //if rol id mod	
      if (this.state.menuOpc){

			return (
				<Menu>
					<div className="logo">
					<Burger  options={ this.state.menuOpc } nombreRol = {nombreRol} idModulo = {idModulo} usuarioFirebase={usuarioFirebase}/>
					</div>								
				</Menu>
			
			)	
		}
		return (
			<Menu>
			</Menu>
			)
		
	}

}

export default MenuComponent
import React, { Component } from "react";
import TemplateComponent1 from "../maps/TemplateComponentVariacionAgrupada1";
import TemplateComponent2 from "../maps/TemplateComponentVariacionAgrupada2";
import DatePickerComponent from "../filters/DatePickerComponent";
import MultipleFiltersComponent from "../filters/MultipleFiltersComponent";
import SideMenu from '../menu/ResponsiveMenu';
import '../../resources/styles/bs_05_VariacionAgrupada.css';
import '../../resources/styles/MainStyles.css';
import imgn from "../../resources/img/logo.svg";
import imgn2 from "../../resources/img/logo-blanco.png";
import { dateParams } from '../../resources/helpers/globalDates';


const urlDev = require("../../properties/properties");
var width = window.innerWidth ;
if (width<1024) width=width / 1.2
else width = width/2.3

//Clase que permite la ejecución del componente.
export default class VariacionAgrupadaComponent extends Component {

    constructor( props ){
    super( props );
    this.state = { 
    	keyTablero: '05VariacionesAgrupadas', 
    	apiResponse: 0,
    localidad: '',
    upz: '',
    barrio: '',
    estrato: '',
    categoria: '',
    usuarioIniciado: '',
    metrica: 'Ventas',
    filtro_fecha_inicio : dateParams.startDate,
    filtro_fecha_fin : dateParams.endDate,
    mapaSemanales : "05VariacionesAgrupadasMapaSemanales",
    mapaAnuales: "05VariacionesAgrupadasMapaAnuales",
    alto_mapa: 600,
    ancho_mapa: width
    };
  }

  updateQuery = (nuevoEstado) => {
      this.setState( prevstate => ({
      	...prevstate, 
        localidad : nuevoEstado.localidad,
        upz: nuevoEstado.upz,
        barrio: nuevoEstado.barrio,
        estrato: nuevoEstado.estrato,
        categoria: nuevoEstado.categoriasBasico,
        metrica : nuevoEstado.metrica,
        apiResponse: nuevoEstado.contadorDeCambios,
      })
      );
  }

  updateDates = (nuevasFechas) => {
    this.setState( prevstate => ({
    	...prevstate, 
    	filtro_fecha_inicio : nuevasFechas.startDate, filtro_fecha_fin: nuevasFechas.endDate, 
      apiResponse: nuevasFechas.apiResponse
  		} 
      ));
  }

  updateUserCats = (usuarioIniciado) => {
    if ( usuarioIniciado!== this.state.usuarioIniciado){
      this.setState( {usuarioIniciado: usuarioIniciado })
    }
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.state.apiResponse === nextState.apiResponse){
      if (this.usuarioIniciado !== nextState.usuarioIniciado){
          return true 
        }
      return false
    } 
      return true
  }

  //Método para el renderizado del componente.
  render() {

    //Método de retorno el cual renderiza los componentes codificados en estilo JSX.
    return (
      <body>
        <div className="encabezado">
        
        	<div style={{'display':'contents'}}>

					<div className="logo1" >
						<img src={imgn} alt="imgn" className="LogoImage"/> 
            <img className="LogoImage2" src={imgn2} alt="imgn" /> 
					</div>
					<div className="titulo1" >
						Variación geográfica.
					</div>
					<div className="calendario1" >
						<DatePickerComponent 
							tablero = {this.state.keyTablero}
							datesChanged = {this.updateDates}
						/>
					</div>
				</div>
			</div>


		<SideMenu OnMenuUserLoaded={this.updateUserCats}/> 
<div className="opciones" >
				<div style={{'display':'contents'}}>
					<div className="subtitulo">
						<h5 align="left" style={{'color':'#4F4F4F'}}>Conozca cómo han evolucionado porcentualmente 
        las métricas semanal y anualmente en los barrios de Bogotá.</h5>
				    </div>
			    
			        <div className="filtros1">
						<MultipleFiltersComponent 
							tablero={this.state.keyTablero}
							metrica={this.state.metrica}
				           	onFilterChange={this.updateQuery}
				        />            
					</div>
				</div>
			</div>


			<div className="paneltableros">

          <div className="map1-variacion-agrupada" >
            <h5><div className="title-map1-variacion-agrupada">  Mapa de Bogotá - Variaciones semanales </div></h5>
                <TemplateComponent1
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  url = {urlDev.urlApiDev+"ConsultaVariacionesAgrupadas/MapaUno"}
                  id_mapa_tablero = {this.state.mapaSemanales}
                  alto_mapa = {this.state.alto_mapa}
                  ancho_mapa = {this.state.ancho_mapa}
                />
          </div>

          <div className="map2-variacion-agrupada" >
            <h5 align="center"> <div className="title-map2-variacion-agrupada"> Mapa de Bogotá - Variaciones anuales </div></h5>          
                <TemplateComponent2
                  filtro_localidad={this.state.localidad}
                  filtro_upz={this.state.upz}
                  filtro_barrio={this.state.barrio}
                  filtro_estrato= {this.state.estrato}
                  filtro_categoria= {this.state.categoria}
                  filtro_metrica={this.state.metrica}
                  startDate={this.state.filtro_fecha_inicio}
                  endDate= {this.state.filtro_fecha_fin}
                  url = {urlDev.urlApiDev+"ConsultaVariacionesAgrupadas/MapaDos"}
                  id_mapa_tablero = {this.state.mapaAnuales}
                  alto_mapa = {this.state.alto_mapa}
                  ancho_mapa = {this.state.ancho_mapa}
                />
          </div>
        </div>
        <div className="footer2">Footer
		</div>
      </body>
    );
  }
}

import React, { PureComponent } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatTable } from '../../resources/helpers/numberFormatter'
import { CustomTooltip } from '../../resources/Tooltips/CustomTooltipPag2'
let jsonResponse = [];

export default class MixBarChart extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/shjsn5su/';

  constructor( props ){
    super( props );
    this.state = { apiResponse: [],
      filtro_localidad : props.filtro_localidad,
      filtro_upz: props.filtro_upz,
      filtro_barrio: props.filtro_barrio,
      filtro_estrato : props.filtro_estrato,
      filtro_categoria : props.filtro_categoria,
      filtro_metrica : props.filtro_metrica,
      filtro_metrica_Belleza_Bienestar : props.filtro_metrica_Belleza_Bienestar,
      filtro_metrica_Comercio : props.filtro_metrica_Comercio,
      filtro_metrica_Compras : props.filtro_metrica_Compras,
      filtro_metrica_Entretenimiento :props.filtro_metrica_Entretenimiento,
      filtro_metrica_Restaurantes : props.filtro_metrica_Restaurantes,
      filtro_metrica_Salud : props.filtro_metrica_Salud,
      filtro_metrica_Servicios : props.filtro_metrica_Servicios,
      filtro_metrica_Turismo : props.filtro_metrica_Turismo,
      filtro_metrica_Tráfico_Transporte :props.filtro_metrica_Tráfico_Transporte,
      filtro_metrica_Distrito :props.filtro_metrica_Distrito,
      url : props.url,
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  //callAPi
  readQuery() {
    fetch(this.state.url+"?fechainicial="+ this.state.startDate.format("YYYY-MM-DD")
      + "&&fechafinal=" + this.state.endDate.format("YYYY-MM-DD"))
      .then ( res => res.text() )
      .then ( res => this.setState( {apiResponse : JSON.parse( res )} ) )
      .then ( res => jsonResponse = this.state.apiResponse );
  }

  componentDidMount(){
    this.readQuery();
  }

  async componentWillReceiveProps(nextProps) {
    let params_string = "";
      // para cada parametro de filtro
      // revisa: si cambio y no es vacio o si no cambio pero tampoco es vacio (ya habia sido definido)
      if ( nextProps.filtro_localidad !== this.state.filtro_localidad  ){
        if ( nextProps.filtro_localidad !== "" ) params_string += "&&nombrelocalidad=" + nextProps.filtro_localidad
      }
      else if ( this.state.filtro_localidad !== '' ) params_string+="&&nombrelocalidad=" + this.state.filtro_localidad

      if ( nextProps.filtro_upz !== this.state.filtro_upz ){
        if ( nextProps.filtro_upz !== "" ) params_string+="&&nombreupz="+nextProps.filtro_upz;
      }
      else if ( this.state.filtro_upz !== '' ) params_string+="&&nombreupz="+this.state.filtro_upz;

      if (nextProps.filtro_barrio !== this.state.filtro_barrio) {
        if ( nextProps.filtro_barrio !== "" ) params_string+="&&nombrebarrio="+nextProps.filtro_barrio;
      }
      else if (this.state.filtro_barrio !== '') params_string+="&&nombrebarrio="+this.state.filtro_barrio;

      if (nextProps.filtro_estrato !== this.state.filtro_estrato) {
        if ( nextProps.filtro_estrato !== "" ) params_string+="&&nombreestrato="+nextProps.filtro_estrato;
      }
      else if (this.state.filtro_estrato !== '') params_string+="&&nombreestrato= "+this.state.filtro_estrato;

      if (nextProps.filtro_categoria !== this.state.filtro_categoria) {
        if ( nextProps.filtro_categoria !== "" ) params_string+="&&nombrecategoria="+nextProps.filtro_categoria;
      }
      else if (this.state.filtro_categoria !== '') params_string+="&&nombrecategoria="+this.state.filtro_categoria;

      if ( (nextProps.startDate !== this.state.startDate ) || (nextProps.endDate !== this.state.endDate )) params_string+="&&fechainicial="+nextProps.startDate.format("YYYY-MM-DD") + "&&fechafinal="+nextProps.endDate.format("YYYY-MM-DD");
      else if (this.state.startDate !== null && this.state.endDate !== null) params_string+="&&fechainicial="+ this.state.startDate.format("YYYY-MM-DD") + "&&fechafinal="+this.state.endDate.format("YYYY-MM-DD");

    if (params_string.length >= 2 ) params_string = params_string.substring(2);
    if(nextProps.filtro_metrica !== this.state.filtro_metrica) {

      } else {

      try {
        //call API
        /// mandar valores de filtros al servicio y determinar que responde para renderizar
        const response = await fetch(this.state.url + "?" + params_string);
        if (!response.ok){
          throw Error(response.statusText);
        }
        jsonResponse = await response.text();
        jsonResponse = JSON.parse(jsonResponse);
      }catch (error) {
        console.log(error);
      }
    }
    this.setState( {
      apiResponse: jsonResponse,
      filtro_localidad: nextProps.filtro_localidad,
      filtro_upz: nextProps.filtro_upz,
      filtro_barrio: nextProps.filtro_barrio,
      filtro_metrica: nextProps.filtro_metrica,
      filtro_metrica_Belleza_Bienestar : nextProps.filtro_metrica_Belleza_Bienestar,
      filtro_metrica_Comercio : nextProps.filtro_metrica_Comercio,
      filtro_metrica_Compras : nextProps.filtro_metrica_Compras,
      filtro_metrica_Entretenimiento :nextProps.filtro_metrica_Entretenimiento,
      filtro_metrica_Restaurantes : nextProps.filtro_metrica_Restaurantes,
      filtro_metrica_Salud : nextProps.filtro_metrica_Salud,
      filtro_metrica_Servicios : nextProps.filtro_metrica_Servicios,
      filtro_metrica_Turismo : nextProps.filtro_metrica_Turismo,
      filtro_metrica_Tráfico_Transporte :nextProps.filtro_metrica_Tráfico_Transporte,
      filtro_metrica_Distrito :nextProps.filtro_metrica_Distrito,
      startDate: nextProps.startDate,
      endDate: nextProps.endDate
    }
  );
}

 shouldComponentUpdate(nextProps,nextState){
   if(this.state.apiResponse === nextState.apiResponse){
      if (this.state.filtro_metrica !== nextState.filtro_metrica){
        return true
      }
      return false
   } else {
      return true
   }
}

render() {

    if (this.state.filtro_metrica === "Ventas") {
      // eslint-disable-next-line
      this.state.metrica_Belleza_Bienestar = "ventas_Belleza_Bienestar"; this.state.metrica_Comercio =  "ventas_Comercio"; this.state.metrica_Compras = "ventas_Compras"; this.state.metrica_Entretenimiento = "ventas_Entretenimiento";
      // eslint-disable-next-line
      this.state.metrica_Restaurantes = "ventas_Restaurantes"; this.state.metrica_Salud = "ventas_Salud"; this.state.metrica_Servicios ="ventas_Servicios"; this.state.metrica_Turismo = "ventas_Turismo"; this.state.metrica_Tráfico_Transporte = "ventas_Trafico_Transporte";
      // eslint-disable-next-line
      this.state.metrica_Distrito = "ventas_Distrito";
    } if (this.state.filtro_metrica === "Búsquedas") {
      // eslint-disable-next-line
      this.state.metrica_Belleza_Bienestar = "Busquedas_Belleza_Bienestar"; this.state.metrica_Comercio =  "Busquedas_Comercio"; this.state.metrica_Compras = "Busquedas_Compras"; this.state.metrica_Entretenimiento = "Busquedas_Entretenimiento";
      // eslint-disable-next-line
      this.state.metrica_Restaurantes = "Busquedas_Restaurantes"; this.state.metrica_Salud = "Busquedas_Salud"; this.state.metrica_Servicios ="Busquedas_Servicios"; this.state.metrica_Turismo = "Busquedas_Turismo"; this.state.metrica_Tráfico_Transporte = "Busquedas_Trafico_Transporte";
      // eslint-disable-next-line
      this.state.metrica_Distrito = "Busquedas_Distrito";
    } if (this.state.filtro_metrica === "Flujos"){
      // eslint-disable-next-line
      this.state.metrica_Belleza_Bienestar = "flujos_Belleza_Bienestar"; this.state.metrica_Comercio =  "flujos_Comercio"; this.state.metrica_Compras = "flujos_Compras"; this.state.metrica_Entretenimiento = "flujos_Entretenimiento";
      // eslint-disable-next-line
      this.state.metrica_Restaurantes = "flujos_Restaurantes"; this.state.metrica_Salud = "flujos_Salud"; this.state.metrica_Servicios ="flujos_Servicios"; this.state.metrica_Turismo = "flujos_Turismo"; this.state.metrica_Tráfico_Transporte = "flujos_Trafico_Transporte";
      // eslint-disable-next-line
      this.state.metrica_Distrito = "flujos_Distrito";
    } if (this.state.filtro_metrica === "Ticket Prom") {
      // eslint-disable-next-line
      this.state.metrica_Belleza_Bienestar = "Tiket_Promedio_Belleza_Bienestar"; this.state.metrica_Comercio =  "Tiket_Promedio_Comercio"; this.state.metrica_Compras = "Tiket_Promedio_Compras"; this.state.metrica_Entretenimiento = "Tiket_Promedio_Entretenimiento";
      // eslint-disable-next-line
      this.state.metrica_Restaurantes = "Tiket_Promedio_Restaurantes"; this.state.metrica_Salud = "Tiket_Promedio_Salud"; this.state.metrica_Servicios ="Tiket_Promedio_Servicios"; this.state.metrica_Turismo = "Tiket_Promedio_Turismo"; this.state.metrica_Tráfico_Transporte = "Tiket_Promedio_Trafico_Transporte";
      // eslint-disable-next-line
      this.state.metrica_Distrito = "Tiket_Promedio_Distrito";
    } if (this.state.filtro_metrica === "Transacciones") {
      // eslint-disable-next-line
      this.state.metrica_Belleza_Bienestar = "Transacciones_Belleza_Bienestar"; this.state.metrica_Comercio =  "Transacciones_Comercio"; this.state.metrica_Compras = "Transacciones_Compras"; this.state.metrica_Entretenimiento = "Transacciones_Entretenimiento";
      // eslint-disable-next-line
      this.state.metrica_Restaurantes = "Transacciones_Restaurantes"; this.state.metrica_Salud = "Transacciones_Salud"; this.state.metrica_Servicios ="Transacciones_Servicios"; this.state.metrica_Turismo = "Transacciones_Turismo"; this.state.metrica_Tráfico_Transporte = "Transacciones_Trafico_Transporte";
      // eslint-disable-next-line 
      this.state.metrica_Distrito = "Transacciones_Distrito";
    }

    return (
      <ResponsiveContainer width='100%' height={700}>
          <ComposedChart
            layout="vertical"
            data={formatTable(this.state.apiResponse)}
            margin={{
              top: 20, right: 15, bottom: 20, left: 20,
            }}
          >
            <XAxis type="number" domain={[0, 100]} allowDataOverflow={true} unit=" %"/>
            <YAxis dataKey="Nombre_Loc" type="category" tick={{fontSize: 9, width : 250}}/>
            <Tooltip content = { <CustomTooltip />}/>
            <Legend verticalAlign="top" height={60}/>
            <Bar dataKey={this.state.metrica_Belleza_Bienestar} unit=" %" name="Belleza y Bienestar" stackId="a" fill="#FF1F70">
            </Bar>
            <Bar dataKey={this.state.metrica_Comercio} unit= " %" name="Comercio" stackId="a" fill="#FF6D2F">
            </Bar>
            <Bar dataKey={this.state.metrica_Compras} unit=" %" name="Compras" stackId= "a" fill="#FFA945">
            </Bar>
            <Bar dataKey={this.state.metrica_Entretenimiento} unit=" %" name="Entretenimiento"  stackId= "a" fill="#FFDC61">
            </Bar>
            <Bar dataKey={this.state.metrica_Restaurantes} unit=" %" name="Restaurantes" stackId= "a" fill="#73EA56">
            </Bar>
            <Bar dataKey={this.state.metrica_Salud} unit=" %" name="Salud" stackId= "a" fill="#1BDFC8">
            </Bar>
            <Bar dataKey={this.state.metrica_Servicios} unit=" %" name="Servicios" stackId= "a" fill="#36B0F4">
            </Bar>
            <Bar dataKey={this.state.metrica_Turismo} unit=" %" name="Turismo" stackId= "a" fill="#1B4DAD">
            </Bar>
            <Bar dataKey={this.state.metrica_Tráfico_Transporte} unit=" %" name="Tráfico y Transporte"  stackId= "a" fill="#8E44C6">
            </Bar>
            <Bar dataKey={this.state.metrica_Distrito} unit=" %" name="Distrito" stackId= "a" fill="#602A97">
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
    );
  }
}

import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./components/authentication/ProtectedRoute";


import Login from './components/authentication/Login';
import b_01_PerfilBogota from "./components/boards/b_01_PerfilBogota";
import b_02_PerfilComercios from "./components/boards/b_02_PerfilComercios";
import b_03_evolucionGeneral from "./components/boards/b_03_evolucionGeneral";
import b_04_variacionComparada from "./components/boards/b_04_variacionComparada";
import b_05_variacionAgrupada from "./components/boards/b_05_variacionAgrupadaGeo";
import b_06_impactoPorcentual from "./components/boards/b_06_impactoPorcentualCovid19";
import b_07_distribucionMercado from "./components/boards/b_07_distribucionMercado";
import b_08_tendenciaMercado from "./components/boards/b_08_tendenciasMercado";
import b_09_ComportamientoCiudadano from "./components/boards/b_09_comportamientoCiudadano";
import b_10_DistribuciónTipologia from "./components/boards/b_10_tipologiasDePago";
import b_11_OportunidadesEstrategicas from "./components/boards/b_11_oportunidadesEstrategicas";
import b_12_ComportamientoConsumidor from "./components/boards/b_12_comportamientoConsumidor";
import b_introduccion from "./components/boards/b_introduccion";
import b_configuracion from "./components/boards/b_configuracion";
import b_glosario from "./components/boards/b_glosario";


function Routes( props ){

	const { isAuthenticated, isVerifying } = props;

	return (
		<Switch>
 			<ProtectedRoute
		        exact
		        path="/"
		        component={b_introduccion}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>
	      	<ProtectedRoute
		        exact
		        path="/perfilBogota"
		        component={b_01_PerfilBogota}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>
	      	<ProtectedRoute
		        exact
		        path="/perfilComercios"
		        component={b_02_PerfilComercios}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>
	      	<ProtectedRoute
		        exact
		        path="/recuperacionEconomica"
		        component={b_03_evolucionGeneral}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>
	      	<ProtectedRoute
		        exact
		        path="/variacionSemanal"
		        component={b_04_variacionComparada}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>
	      	<ProtectedRoute
		        exact
		        path="/variacionAgrupada"
		        component={b_05_variacionAgrupada}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>

	      	<ProtectedRoute
		        exact
		        path="/cambiosPorcentualesCovid19"
		        component={b_06_impactoPorcentual}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>


	      	<ProtectedRoute
		        exact
		        path="/DistribucionMercado"
		        component={b_07_distribucionMercado}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>

	      	<ProtectedRoute
		        exact
		        path="/TendenciaMercado"
		        component={b_08_tendenciaMercado}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>

	      	<ProtectedRoute
		        exact
		        path="/ComportamientoCiudadano"
		        component={b_09_ComportamientoCiudadano}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
		      />
		      <ProtectedRoute
		        exact
		        path="/DistribuciónTipologia"
		        component={b_10_DistribuciónTipologia}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
		      />

			<ProtectedRoute
		        exact
		        path="/OportunidadesEstrategicas"
		        component={b_11_OportunidadesEstrategicas}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>
			
			<ProtectedRoute
		        exact
		        path="/ComportamientoConsumidor"
		        component={b_12_ComportamientoConsumidor}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>

			<ProtectedRoute
		        exact
		        path="/ConfiguracionUsuario"
		        component={b_configuracion}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>

			<ProtectedRoute
		        exact
		        path="/glosario"
		        component={b_glosario}
		        isAuthenticated={isAuthenticated}
		        isVerifying={isVerifying}
	      	/>

			<Route
				path="/"
				component={Login}
			/>
		</Switch>
		)

}

//Función que permite la validación de rutas y/o urls.

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  };
}

//Exportación de la clase/función.

export default connect(mapStateToProps)(Routes);
